import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';

import { completeStepDS, fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import { orgGetStartedUrl } from 'accounts/urls';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const GetStartedStepButtons = ({ stepName }) => {
  const history = useHistory();
  const activeOrg = useSelector(selectActiveOrg);
  const dispatch = useDispatch();

  if (!history.location?.state?.fromGetStarted) {
    return null;
  }

  return (
    <div className="d-flex justify-content-end align-items-center mt-5">
      <TDButton label="Cancel" onClick={() => history.push(orgGetStartedUrl(activeOrg.alias))} />
      <TDButton
        label="Save & continue"
        onClick={async () => {
          const { steps: updatedSteps } = await dispatch(fetchSetupStepsDS());
          const step = (updatedSteps.userCardSetupSteps[activeOrg.alias] || []).find(
            thisStep => thisStep.name === stepName,
          );
          if (step?.completed) {
            await completeStepDS(dispatch, activeOrg.alias, stepName, { history });
          } else {
            toastr.error('Oh snap!', 'You have not completed this step');
          }
        }}
        variant={BS_STYLE.PRIMARY}
      />
    </div>
  );
};

GetStartedStepButtons.propTypes = {
  stepName: PropTypes.string.isRequired,
};

export default GetStartedStepButtons;
