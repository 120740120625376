import { difference, isEmpty, isEqual, uniq } from 'lodash';
import { Money } from 'td-finance-ts';

import { DEFAULT_BALANCE_CURRENCY } from 'finance/assets/js/constants';


/**
 * This class is used to calculate the AOR charges applied to an organization.
 * While the AORFeeScheme is the record of the service settings,
 * the AORFeeAnalysis is the actual calculated charges.
 */
class AORFeeAnalysis {
  static getEmptyAnalysis() {
    return new AORFeeAnalysis();
  }

  /**
   * It is used for de-serializing a ApiFeeAnalysis serialized object
   *
   * @param {Object} serialized
   *
   * @returns {AORFeeAnalysis}
   */
  constructor(...serialized) {
    if (isEmpty(serialized) || serialized.every(s => isEmpty(s))) {
      return this.init();
    }
    return this.init(...serialized);
  }

  /**
   *
   * @param {object} metrics
   * @param {string} metrics.currency - AOR service currency
   * @param {string} metrics.perAORSeat - Charge per AOR seat
   * @param {number} metrics.numAORSeats - Num of AOR seats to apply charged
   * @param {boolean} metrics.hasBeenEnabled - Check if AOR service was enabled
   * @param {string} metrics.total
   *
   * @returns {AORFeeAnalysis}
   */
  init({
    currency = DEFAULT_BALANCE_CURRENCY,
    perAORSeat = '0.00',
    numAORSeats = 0,
    hasBeenEnabled = false,
    feeBreakdownItems = [],
    total,
  } = {}) {
    this.details = {
      currency,
      perAORSeat,
      numAORSeats,
      hasBeenEnabled,
      feeBreakdownItems,
    };

    Object.assign(this.details, {
      total: total || this.aggregate(),
    });
  }

  serialize() {
    if (this.isEmpty()) {
      return null;
    }
    return {
      ...this.details,
    };
  }

  /**
   * Calculates AOR seats costs
   *
   * @param {string} perAORSeat - cost per AOR seat
   * @param {number} numAORSeats - number of AOR seats
   * @param {string} currency - currency of AOR
   *
   * @see this.getFormattedFeeBreakdownItems this.aggregate
   *
   * @returns {string} total cost
   */
  calcAORSeatsCost({
    perAORSeat,
    numAORSeats,
    currency,
  } = {}) {
    const { hasBeenEnabled } = this.details;

    if (!hasBeenEnabled) {
      return '0.00';
    }
    const fee = new Money(perAORSeat, currency).mul(numAORSeats);

    return fee.toString();
  }


  aggregate() {
    const {
      perAORSeat,
      numAORSeats,
    } = this.details;

    const currency = this.getCurrency();

    const fee = this.calcAORSeatsCost({
      perAORSeat, numAORSeats, currency,
    });

    return fee;
  }

  getCurrency() {
    const { currency } = this.details;
    return currency;
  }

  getTotal() {
    return this.aggregate();
  }

  /**
   * Compares the analysis passed with the current one
   *
   * @param {AORFeeAnalysis} analysisToCompareWith
   * @returns {boolean}
   */
  isEqual(analysisToCompareWith) {
    return isEqual(this, analysisToCompareWith);
  }

  /**
   * Checks if the total calculated as the analysis total is zero
   *
   * @returns {Boolean}
   */
  isZero() {
    return new Money(this.getTotal(), this.getCurrency()).isZero();
  }

  /**
   * Checks if an analysis is empty
   *
   * @returns {Boolean}
   */
  isEmpty() {
    const emptyAnalysis = AORFeeAnalysis.getEmptyAnalysis();
    return this.isEqual(emptyAnalysis);
  }

  /**
   * Returns the cost per AOR seat
   *
   * @returns {String}
   */
  getPerAORSeat() {
    const { perAORSeat } = this.details;
    return perAORSeat;
  }

  /**
   * Returns the number of seats used for the AOR cost calculation
   *
   * @returns {Number}
   */
  getNumSeats() {
    const { numAORSeats } = this.details;
    return numAORSeats;
  }

  /**
   * Returns whether the service has been enabled
   *
   * @returns {Boolean}
   */
  isEnabled() {
    const { hasBeenEnabled } = this.details;
    return hasBeenEnabled;
  }

  /**
   * @returns {String}
   */
  getServiceTitle() {
    return 'AOR service';
  }

  /**
   * @returns {String}
   */
  getServiceMetricsDescriptions() {
    const aorSeats = this.getNumSeats();

    return [{
      description: `AOR seats @ ${this.getPerAORSeat()}`,
      fee: this.getTotal(),
      quantity: aorSeats,
    }];
  }

  /**
   * Returns the fee breakdown items
   *
   * @returns {object[]} the fee breakdown items
   */
  getFeeBreakdownItems() {
    const { feeBreakdownItems } = this.details;

    return feeBreakdownItems;
  }

  /**
   * Returns a metrics structure of the feeBreakdownItems
   *
   * @returns {{
   *   total: string,
   *   count: number
   *   orgId: number,
   * }[]} the metrics of the feeBreakdownItems
   */
  getFormattedFeeBreakdownItems() {
    const feeBreakdownItems = this.getFeeBreakdownItems();

    if (isEmpty(feeBreakdownItems)) {
      return [];
    }

    const currency = this.getCurrency();
    const perAORSeat = this.getPerAORSeat();

    const formattedFeeBreakdownItems = feeBreakdownItems.map((item) => {
      const { orgId } = item;
      const providerUserIds = item.providerUserIds || [];
      const providerCount = providerUserIds.length;

      return {
        orgId,
        count: providerCount,
        total: this.calcAORSeatsCost({
          perAORSeat,
          numAORSeats: providerCount,
          currency,
        }),
      };
    });

    return formattedFeeBreakdownItems;
  }


  /**
   * Returns all needed feeBreakdown metrics
   *
   * @see getFormattedFeeBreakdownItems
   * @returns {{
   *   chargedUserIdsInMultipleOrgs: number[],
   *   chargedUserIdsInOneOrg: number[]
   * }}|{}
   */
  _getFeeBreakdownMetricIds() {
    const feeBreakdownItems = this.getFeeBreakdownItems();

    if (isEmpty(feeBreakdownItems)) {
      return {};
    }

    const chargedUserIds = feeBreakdownItems
      .map(item => item.providerUserIds)
      .flat();

    const uniqChargedUserIds = uniq(chargedUserIds);

    const chargedUserIdsInMultipleOrgs = uniq(uniqChargedUserIds
      .filter(userId => chargedUserIds.filter(_userId => _userId === userId).length > 1));

    const chargedUserIdsInOneOrg = difference(chargedUserIds, chargedUserIdsInMultipleOrgs);

    const metrics = {
      chargedUserIdsInMultipleOrgs,
      chargedUserIdsInOneOrg,
    };

    return metrics;
  }


  /**
   * It combines the formatted breakdown items and adds their metrics
   *
   * @returns {object} the formatted breakdown items along with their metrics
   */
  getFormattedFeeBreakdownItemsWithMetrics() {
    const metrics = this._getFeeBreakdownMetricIds();
    const formattedFeeBreakdownItems = this.getFormattedFeeBreakdownItems();

    return ({
      ...metrics,
      formattedFeeBreakdownItems,
    });
  }
}

export default AORFeeAnalysis;
