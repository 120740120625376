import React from 'react';
import PropTypes from 'prop-types';

import InterviewCardSkeleton from './InterviewCardSkeleton.jsx';

const InterviewListSkeleton = ({ className }) => (
  <div className={['skeleton--interview-list', className].join(' ')}>
    <InterviewCardSkeleton variation="1" />
    <InterviewCardSkeleton variation="2" />
    <InterviewCardSkeleton variation="1" />
    <InterviewCardSkeleton variation="2" />
    <InterviewCardSkeleton variation="1" />
    <InterviewCardSkeleton variation="2" />
  </div>
);

InterviewListSkeleton.propTypes = {
  className: PropTypes.string,
};

InterviewListSkeleton.defaultProps = {
  className: '',
};

export default InterviewListSkeleton;
