import PropTypes from 'prop-types';
import React, { useState } from 'react';

import TDGeoSuggest from 'core/assets/js/components/TDGeoSuggest.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import withField from 'core/assets/js/components/withField.jsx';
import Address from 'core/assets/js/lib/Address';
import { countryOptions } from 'core/assets/js/lib/isoCountries';
import { FieldSelect } from 'core/assets/js/components/ReduxFormFields/SelectField.jsx';
import { extractAddressComponent } from 'core/assets/js/lib/utils';
import { isEmpty } from 'lodash';

const GeoSuggestField = ({
  input,
  label,
  meta: { error, submitError, touched },
  onlyAuto,
  placeholder,
  required,
  sublabel,
}) => {
  const address = new Address(input.value);

  const [showForm, setShowForm] = useState(false);

  const onChangeComponent = (event, type) => {
    const { target: { value } } = event;
    address[type] = value;
    input.onChange(address.toObject());
  };

  const hasError = touched && (error || submitError);
  const groupClassName = ['form-group'];
  if (hasError) {
    groupClassName.push('has-error');
  }

  return (
    <div className={groupClassName.join(' ')}>
      <TDLabel
        label={label}
        name={input.name}
        required={required}
        sublabel={onlyAuto ? sublabel : (
          <>
            {'Search for your address by typing it in the field below, or '}
            <a
              className="imitate-link"
              data-testid="geosuggest-enter-address-manually"
              onClick={() => setShowForm(!showForm)}
            >
              enter it manually
            </a>
          </>
        )}
      />
      <TDGeoSuggest
        disabled={showForm}
        initialValue={input.value ? input.value.label : input.value}
        inputClassName="form-control"
        // Do not delete on change, we need it so that when the user deletes address, it returns an
        // empty string and removes all address details from notAuto form fields
        onChange={obj => input.onChange(obj)}
        onSelect={obj => input.onChange(obj)}
        onSuggestSelect={obj => {
          input.onChange(obj);
          if (!isEmpty(obj) && !extractAddressComponent(obj, 'country')) {
            // Not all google address searches will include a country (e.g. disputed regions)
            // but we require it to be entered
            this.setState({ showForm: true });
          }
        }}
        placeholder={placeholder}
        // https://developers.google.com/maps/documentation/javascript/place-types
        types={['establishment', 'premise', 'street_address']}
      />
      {hasError && <span className="help-block mt-3">{error || submitError}</span>}
      {showForm && (
        <div className="mt-3">
          <div className="form-group">
            <input
              className="form-control"
              onChange={ev => onChangeComponent(ev, 'addressLine1')}
              placeholder="Address line 1"
              value={address.addressLine1}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              onChange={ev => onChangeComponent(ev, 'addressLine2')}
              placeholder="Address line 2"
              value={address.addressLine2}
            />
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pr-md-3 form-group">
              <input
                className="form-control"
                onChange={ev => onChangeComponent(ev, 'region')}
                placeholder="County"
                value={address.region}
              />
            </div>

            <div className="col-12 col-md-6 pl-md-3 form-group">
              <input
                className="form-control"
                onChange={ev => onChangeComponent(ev, 'postcode')}
                placeholder="Postcode"
                value={address.postcode}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pr-md-3 form-group">
              <input
                className="form-control"
                onChange={ev => onChangeComponent(ev, 'city')}
                placeholder="City/Town"
                value={address.city}
              />
            </div>
            <div className="col-12 col-md-6 pl-md-3 form-group">
              <FieldSelect
                defaultOptionText="Country"
                inputClassName="form-control"
                onChange={ev => onChangeComponent(ev, 'countryCode')}
                optionsMapping={countryOptions}
                value={address.countryCode}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

GeoSuggestField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
  }),
  onlyAuto: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
};

GeoSuggestField.defaultProps = {
  label: '',
  meta: {
    touched: false,
    error: '',
    submitError: '',
  },
  onlyAuto: false,
  placeholder: '',
  required: false,
  sublabel: '',
};

export default withField(GeoSuggestField);
