import { kebabCase } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import WidgetWrapper from 'core/assets/js/components/Dashboard/WidgetWrapper.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import {
  DATETIME_FORMAT_HUMAN_FRIENDLY_2, IMG_SIZE, PROFILE_TABS, TASK_TABS,
} from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { formatDate } from 'core/assets/js/lib/utils';
import { documentAssignmentSignUrl } from 'documents/urls';
import { downloadFileApiUrl } from 'files/urls';
import {
  financeExpenseViewUrl,
  financeProFormaInvoiceViewUrl,
  financePurchaseOrderViewUrl,
  financeWorksheetViewUrl,
} from 'finance/urls';
import {
  DASHBOARD_PENDING_ACTIONS_TYPE,
  DASHBOARD_PENDING_ACTIONS_TYPE_ICON,
  DASHBOARD_PENDING_ACTIONS_TYPE_LABEL,
} from 'organizations/assets/js/constants';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { getDashboardPendingActionsApiUrl } from 'organizations/urls';
import { orgUserProfileUrl } from 'people/urls';
import { SERVICE_ORDER_TYPE, SERVICE_ORDER_TYPE_LABEL } from 'projects/assets/js/constants';
import { projectApplicationUrl, projectInvitationsUrl, projectViewTaskUrl } from 'projects/urls';
import { orgSettingsRatesUrl } from 'rates/urls';

const PAGINATION_KEY = 'pending-actions-widget-pagination';

const PendingActionsWidget = () => {
  const params = useParams();
  const history = useHistory();
  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);
  const { items, pagination } = fetchDataHook({
    componentName: PendingActionsWidget.GetComponentName(params),
    duck: 'list',
    paginationKey: PAGINATION_KEY,
    url: getDashboardPendingActionsApiUrl(activeOrg.alias),
  });
  if (items.length === 0) {
    return null;
  }
  const { isProvider } = activeUserCard.userRole;
  return (
    <WidgetWrapper
      bodyExtraClasses="p-0"
      extraFooterContent={<TDPagination paginationKey={PAGINATION_KEY} {...pagination} />}
      isEmpty={items.length === 0}
      title="Pending actions"
    >


      <Table
        cols={[
          {
            className: 'border-0 pb-0',
            dataFormat: (type, { serviceOrderType }) => {
              let typeString = serviceOrderType
                ? SERVICE_ORDER_TYPE_LABEL[serviceOrderType]
                : DASHBOARD_PENDING_ACTIONS_TYPE_LABEL[type];
              if (serviceOrderType) {
                switch (type) {
                  case DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_AMENDED:
                    typeString += ' amendment';
                    break;
                  case DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_CONFIRMED:
                    typeString += ' confirmation';
                    break;
                  case DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_APPROVED:
                    typeString += ' approval';
                    break;
                  case DASHBOARD_PENDING_ACTIONS_TYPE.ONBOARDING_FORM_NEEDS_REVIEW:
                    typeString += ' approval';
                    break;
                  default:
                }
              }
              return (
                <div className="d-flex align-items-center">
                  <div className="icon-container d-flex justify-content-center">
                    <i className={`${DASHBOARD_PENDING_ACTIONS_TYPE_ICON[type]} fa-2x`} />
                  </div>
                  <span className="ml-2 font-weight-bolder">{typeString}</span>
                </div>
              );
            },
            key: 'type',
            label: 'Type',
          },
          {
            className: 'border-0 pb-0',
            dataFormat: (userFullName, { userAvatarFileId, userId, userType }) => {
              if (!userId) {
                return userFullName;
              }
              return (
                <>
                  <ProfilePic
                    alt={userFullName}
                    className="mr-3"
                    size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
                    url={userAvatarFileId && downloadFileApiUrl(userAvatarFileId)}
                  />
                  <Link className="text-dark" to={orgUserProfileUrl(params.orgAlias, userType, userId)}>
                    {userFullName}
                  </Link>
                </>
              );
            },
            key: 'userFullName',
            label: isProvider ? 'Manager' : 'User',
          },
          { className: 'border-0 pb-0', key: 'description', label: 'Description' },
          {
            className: 'border-0 pb-0',
            dataFormat: date => formatDate(date, DATETIME_FORMAT_HUMAN_FRIENDLY_2),
            key: 'date',
            label: 'Date',
            width: '170px',
          },
          {
            className: 'border-0 pb-0',
            dataFormat: (id, { projectId, serviceOrderType, type, userType, userId }) => {
              let url = null;
              switch (type) {
                case DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_AMENDED:
                case DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_CONFIRMED:
                case DASHBOARD_PENDING_ACTIONS_TYPE.SERVICE_ORDER_NEEDS_APPROVED:
                  switch (serviceOrderType) {
                    case SERVICE_ORDER_TYPE.WORKSHEET:
                      url = financeWorksheetViewUrl(params.orgAlias, id);
                      break;
                    case SERVICE_ORDER_TYPE.EXPENSE:
                      url = financeExpenseViewUrl(params.orgAlias, id);
                      break;
                    case SERVICE_ORDER_TYPE.PROFORMA_INVOICE:
                      url = financeProFormaInvoiceViewUrl(params.orgAlias, id);
                      break;
                    default:
                  }
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.PROJECT_INVITATION:
                case DASHBOARD_PENDING_ACTIONS_TYPE.TASK_INVITATION:
                  url = projectInvitationsUrl(params.orgAlias);
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.TASK_RATE_AMENDMENT:
                  if (isProvider) {
                    url = projectInvitationsUrl(params.orgAlias);
                  } else {
                    url = projectViewTaskUrl(
                      params.orgAlias, projectId, id, TASK_TABS.DISCUSSION, null, userId,
                    );
                  }
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.RATE_NEEDS_APPROVED:
                  url = orgUserProfileUrl(params.orgAlias, userType, userId, PROFILE_TABS.RATES);
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.RATE_AMENDMENT:
                  if (isProvider) {
                    url = orgSettingsRatesUrl(params.orgAlias);
                  } else {
                    url = orgUserProfileUrl(params.orgAlias, userType, userId, PROFILE_TABS.RATES);
                  }
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.CONTRACT_TEMPLATE:
                  url = documentAssignmentSignUrl(params.orgAlias, id);
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.ONBOARDING_FORM_NEEDS_REVIEW:
                  url = [
                    orgUserProfileUrl(params.orgAlias, userType, userId, PROFILE_TABS.ONBOARDING),
                    `?reviewOnboardingForm=${id}`,
                  ].join('');
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.BUDGET_REQUEST:
                  url = financePurchaseOrderViewUrl(params.orgAlias, id);
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.TASK_COMPLETION_REQUEST:
                  url = projectViewTaskUrl(
                    params.orgAlias, projectId, id, TASK_TABS.DISCUSSION, null, userId,
                  );
                  break;
                case DASHBOARD_PENDING_ACTIONS_TYPE.PROJECT_APPLICATION:
                  url = projectApplicationUrl(
                    params.orgAlias, projectId, id,
                  );
                  break;
                default:
              }
              if (!url) {
                return null;
              }
              return (
                <TDButton
                  className="float-right"
                  label={isProvider ? 'View' : 'Review'}
                  onClick={() => history.push(url)}
                />
              );
            },
            key: 'id',
            label: '',
            width: '130px',
          },
        ]}
        items={items}
        tableProps={{
          bordered: false,
          headerContainerClass: 'no-border',
        }}
        trClassName={({ serviceOrderType, type }) => {
          let className = type;
          if (serviceOrderType) {
            className += ` ${kebabCase(SERVICE_ORDER_TYPE_LABEL[serviceOrderType])}`;
          }
          return className;
        }}
      />
    </WidgetWrapper>
  );
};

PendingActionsWidget.GetComponentName = () => 'PendingActionsWidget';

export default PendingActionsWidget;
