import React from 'react';
import PropTypes from 'prop-types';

import GetStartedStepButtons from 'accounts/assets/js/components/GetStartedStepButtons.jsx';
import { profileSettingsUrl } from 'accounts/urls';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import ProfileInformationTab from 'accounts/assets/js/components/ProfileInformationTab.jsx';
import ProfileSocialTab from 'accounts/assets/js/components/ProfileSocialTab.jsx';
import CustomFieldsTab from 'accounts/assets/js/components/CustomFieldsTab.jsx';
import ProfileSkillsTab from 'accounts/assets/js/components/ProfileSkillsTab.jsx';
import ProfileLanguageTab from 'accounts/assets/js/components/ProfileLanguageTab.jsx';
import ProfileExperienceTab from 'accounts/assets/js/components/ProfileExperienceTab.jsx';
import ProfileBankAccountTab from 'settings/assets/js/components/ProfileBankAccountTab.jsx';
import ProfilePaymentTermsTab from 'settings/assets/js/components/ProfilePaymentTermsTab.jsx';
import ProfileCompanyInformationTab from 'settings/assets/js/components/ProfileCompanyInformationTab.jsx';
import { SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';

/**
 * Returns different profile settings tab depending which tab is active
 * @param tab The active tab
 * @param history
 * @param profile User profile
 * @returns {null|*}
 */
const ProfileSettingsTab = ({ tab, history, profile }) => {
  switch (tab) {
    case PROFILE_SETTINGS_TABS.BASIC_INFORMATION:
      return (
        <ProfileInformationTab profile={profile} />
      );
    case PROFILE_SETTINGS_TABS.SOCIAL_PROFILES:
      return (
        <ProfileSocialTab profile={profile} />
      );
    case PROFILE_SETTINGS_TABS.CUSTOM_FIELDS:
      return (
        <CustomFieldsTab profile={profile} />
      );
    case PROFILE_SETTINGS_TABS.SKILLS:
      return (
        <>
          <ProfileSkillsTab profile={profile} />
          <GetStartedStepButtons stepName="SkillsStep" />
        </>
      );
    case PROFILE_SETTINGS_TABS.LANGUAGES:
      return (
        <>
          <ProfileLanguageTab profile={profile} />
          <GetStartedStepButtons stepName="LanguagesStep" />
        </>
      );
    case PROFILE_SETTINGS_TABS.EXPERIENCE:
      return (
        <ProfileExperienceTab />
      );
    case SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS:
      return (
        <ProfileBankAccountTab />
      );
    case SETTINGS_PAYMENTS_TABS.PAYMENT_TERMS:
      return (
        <ProfilePaymentTermsTab />
      );
    case SETTINGS_PAYMENTS_TABS.INVOICE_DETAILS:
      return (
        <ProfileCompanyInformationTab />
      );
    default:
      history.push(
        profileSettingsUrl(PROFILE_SETTINGS_TABS.BASIC_INFORMATION),
      );
      return null;
  }
};

ProfileSettingsTab.propTypes = {
  history: routerHistorySpec.isRequired,
  profile: PropTypes.object,
  tab: PropTypes.string.isRequired,
};

ProfileSettingsTab.defaultProps = {
  profile: {},
};

export default ProfileSettingsTab;
