import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import DocumentSelectField from 'core/assets/js/components/FinalFormFields/DocumentSelectField.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import SelectedDocumentsLinks from 'documents/assets/js/components/SelectedDocumentsLinks.jsx';
import { documentOptionsSpec } from 'documents/assets/js/lib/objectSpecs';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import AllowAccessToOrgCreator from 'core/assets/js/components/AllowAccessToOrgCreator.jsx';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import { customFieldsTemplateListApiUrlAll, interviewCreateUrl } from 'interviews/urls';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import { interviewSpec } from 'interviews/assets/js/lib/objectSpecs';
import { selectActiveOrg, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import { INVITATION_EXPIRATION_DAYS_OPTIONS, INVITATION_NEVER_EXPIRE_VALUE } from 'invitations/assets/js/constants';
import InterviewTemplateSelect from 'settings/assets/js/components/InterviewTemplateSelect.jsx';
import {
  PEOPLE_INVITATION_WORDINGS, PEOPLE_TYPE, USER_EMPLOYMENT_TYPE,
} from 'people/assets/js/constants';
import { getDocumentIdsFromSelectedOnboardingForm } from 'people/assets/js/lib/utils';

export const COMPONENT_NAME = 'InvitePeopleDetails';

const InvitePeopleDetails = ({
  countersigningEnabled: countersigningEnabledIn,
  documentOptions,
  formValues,
  headerText,
  interviews,
  invitationsExpire,
  peopleType,
  updateFormField,
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);
  const location = useLocation();

  const { item: customFieldTemplates } = fetchDataHook({
    componentName: InvitePeopleDetails.GetComponentName(),
    url: customFieldsTemplateListApiUrlAll({
      entityType: FIELD_ENTITY_TYPE.USER, orgAlias: activeOrg.alias,
    }),
  });

  const {
    documents_with_countersigning_enabled: documentsWithCountersigningEnabled,
    onboarding_forms_are_mandatory: onboardingFormsAreMandatory,
  } = activeOrg;
  const canManageInstantApproval = (
    !!activeOrg.should_high_manager_manage_instant_approval && activeUserCard.userRole.isAnyManager
  );

  const countersigningEnabled = countersigningEnabledIn && documentsWithCountersigningEnabled;
  const { employment_type: employmentType } = formValues;
  const isEmployee = parseInt(employmentType, 10) === USER_EMPLOYMENT_TYPE.EMPLOYEE;
  const expirationDaysOptions = [...INVITATION_EXPIRATION_DAYS_OPTIONS];
  const addNeverExpiresOption = !invitationsExpire && !expirationDaysOptions.find(({ text }) => text === 'Never expire');
  if (addNeverExpiresOption) {
    expirationDaysOptions.push({ value: INVITATION_NEVER_EXPIRE_VALUE, text: 'Never expire' });
  }

  const onboardingFormEnabled = Array.isArray(formValues.onboardingFormEnabled)
    && formValues.onboardingFormEnabled.length === 1
    && formValues.onboardingFormEnabled[0].value === true;

  const interviewSubLabel = (
    <AllowAccessToOrgCreator>
      Build a
      {' '}
      <Link to={interviewCreateUrl(activeOrg.alias, location.pathname)}>
        new Onboarding form
      </Link>
    </AllowAccessToOrgCreator>
  );

  const instantApprovalOptions = [{ text: 'Instant approval', value: true }];

  const templateSelectComponent = (
    <InterviewTemplateSelect
      onSelect={template => {
        updateFormField('message', template.content);
      }}
    />
  );

  const { [peopleType]: { instantApproval, messageSublabel } } = PEOPLE_INVITATION_WORDINGS;

  const onboardingFormDocumentIds = getDocumentIdsFromSelectedOnboardingForm(
    formValues, interviews, onboardingFormsAreMandatory,
  );

  return (
    <div>
      <h2>{headerText}</h2>

      <TextAreaField
        label="Custom message"
        name="message"
        sublabel={messageSublabel}
        templateSelectComponent={templateSelectComponent}
      />

      {!isEmployee && (
        <>
          {!onboardingFormsAreMandatory && (
            <CheckboxField
              name="onboardingFormEnabled"
              options={[{
                text: `Do you want the ${peopleType} to complete an onboarding form?`, value: true,
              }]}
            />
          )}
          <SelectField
            data-testid="select-onboarding-form"
            defaultOptionText={null}
            disabled={!onboardingFormsAreMandatory && !onboardingFormEnabled}
            label="Onboarding Form"
            name="interview"
            optionsMapping={interviews.map(i => ({ value: i.id, text: i.name }))}
            sublabel={interviewSubLabel}
            type="text"
          />
          <SelectedDocumentsLinks
            className="mb-5"
            documentOptions={documentOptions}
            label="Documents included in onboarding form"
            selectedDocumentIds={onboardingFormDocumentIds}
          />
        </>
      )}

      {peopleType === PEOPLE_TYPE.PROVIDERS && customFieldTemplates.length > 0 && (
        <CustomSelectField
          className="mt-5"
          defaultOptionText="Select user templates"
          isMulti
          label="User templates"
          name="customFieldTemplateIds"
          options={customFieldTemplates.map(t => ({ label: t.name, value: t.id }))}
          sublabel={(
            'Do you require invited providers to fill out user templates before obtaining access to'
            + ' the organization?'
          )}
        />
      )}

      <DocumentSelectField
        allowCountersigners={countersigningEnabled}
        className="mt-2"
        name="documents"
        showSelected
        sublabel={`Do you require invited ${peopleType} to review and agree to any legal
          documents before obtaining access to the organization?`}
      />

      <AreaCollapsible
        data-testid="invite-people-advanced-options-collapsible"
        contentChildren={(
          <>
            {canManageInstantApproval && !isEmployee && (
              <CheckboxField
                label="Instant approval"
                name="approved"
                options={instantApprovalOptions}
                sublabel={instantApproval}
                type="checkbox"
              />
            )}

            <SelectField
              defaultOptionText={null}
              label="Invitation expires in"
              name="expiration_days"
              optionsMapping={expirationDaysOptions}
              sublabel={(
                'The invitation will expire in the designated number of days. You can always resend the '
                + 'invitation to renew it.'
              )}
              type="text"
            />
          </>
        )}
        fieldSet
        headingChildren={<span>Advanced Options</span>}
        keepInDOM
      />
    </div>
  );
};

InvitePeopleDetails.GetComponentName = () => 'InvitePeopleDetails';

InvitePeopleDetails.propTypes = {
  countersigningEnabled: PropTypes.bool,
  documentOptions: PropTypes.arrayOf(documentOptionsSpec).isRequired,
  formValues: PropTypes.object,
  headerText: PropTypes.string,
  interviews: PropTypes.arrayOf(interviewSpec),
  invitationsExpire: PropTypes.bool,
  peopleType: peopleTypeSpec.isRequired,
  updateFormField: PropTypes.func.isRequired,
};

InvitePeopleDetails.defaultProps = {
  countersigningEnabled: true,
  formValues: {},
  headerText: 'Details',
  interviews: [],
  invitationsExpire: false,
};

export default InvitePeopleDetails;
