import { orderBy } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { BS_STYLE } from 'core/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { interviewCreateUrl } from 'interviews/urls';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import ManageTemplatesTabContent from 'settings/assets/js/components/ManageTemplatesTabContent.jsx';
import ManageTemplatesTabs from 'settings/assets/js/components/ManageTemplatesTabs.jsx';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import { orgInvitationTemplateCreateUrl, customFieldTemplateTypeSelectUrl } from 'settings/urls';

const OrgManageTemplatesView = () => {
  const history = useHistory();
  const { tab } = useParams();

  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);

  const ctaButtonItems = [];

  if (
    activeUserCard.userRole.isHigherManager
    || (activeUserCard.userRole.isAnyManager && activeOrg.managers_can_manage_onboarding_forms)
  ) {
    ctaButtonItems.push({
      label: 'Create onboarding form',
      onClick: () => history.push(interviewCreateUrl(activeOrg.alias)),
      order: tab === SETTINGS_TEMPLATE_TABS.ONBOARDING ? 1 : 2,
      variant: BS_STYLE.PRIMARY,
    });
  }

  ctaButtonItems.push(
    {
      label: 'Create org invitation template',
      onClick: () => history.push(orgInvitationTemplateCreateUrl(activeOrg.alias)),
      order: tab === SETTINGS_TEMPLATE_TABS.ORG_INVITATIONS ? 1 : 2,
      variant: BS_STYLE.PRIMARY,
    },
    {
      label: 'Create template',
      onClick: () => history.push(customFieldTemplateTypeSelectUrl(activeOrg.alias)),
      order: tab === SETTINGS_TEMPLATE_TABS.TEMPLATES ? 1 : 2,
      variant: BS_STYLE.PRIMARY,
    },
  );

  return (
    <>
      <ContentHeader
        breadcrumbs={[{ title: 'Templates', url: null }]}
        ctaButtonItems={orderBy(ctaButtonItems, 'order')}
      >
        <ManageTemplatesTabs tab={tab} />
      </ContentHeader>
      <div className="page page--settings">
        <div className="container">
          <ManageTemplatesTabContent tab={tab} />
        </div>
      </div>
    </>
  );
};

OrgManageTemplatesView.GetComponentName = () => 'OrgManageTemplatesView';

export default OrgManageTemplatesView;
