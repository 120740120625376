import { difference, isEmpty, isNil, uniq } from 'lodash';
import Big from 'big.js';
import pluralize from 'pluralize';


Big.RM = 1;
Big.DP = 2;

class SeatFeeAnalysis {
  static getEmptyAnalysis() {
    return new SeatFeeAnalysis();
  }

  /**
   * It is used for de-serializing a SeatFeeAnalysis serialized object
   *
   * @param {Object} serialized
   *
   * @returns {CodatFeeAnalysis}
   */
  constructor(...serialized) {
    if (isEmpty(serialized) || serialized.every(a => isEmpty(a))) {
      return this.init({ fee: '0.00', managersFee: '0.00', providersFee: '0.00' });
    }
    return this.init(...serialized);
  }

  init({
    baseLicenceFee = '0.00',
    extraManagers = 0,
    extraProviders = 0,
    fee,
    managersFee,
    numManagers = 0,
    numProviders = 0,
    feeBreakdownItems = [],
    perManagerSeat = '0.00',
    perProviderSeat = '0.00',
    providersFee,
  } = {}) {
    const details = {
      numManagers: parseInt(numManagers, 10), numProviders: parseInt(numProviders, 10),
      extraManagers: parseInt(extraManagers, 10), extraProviders: parseInt(extraProviders, 10),
      baseLicenceFee, perManagerSeat, perProviderSeat,
      fee, managersFee, providersFee, feeBreakdownItems,
    };

    Object.entries(details).forEach(([key, value]) => {
      if (isNil(value)) {
        throw new Error(`${key} is required`);
      }
    });

    this.details = details;
  }

  _aggregate() {
    const {
      managersFee, providersFee, baseLicenceFee, numManagers, numProviders,
      perManagerSeat, perProviderSeat,
    } = this.details;
    let totalForManagers = managersFee;
    let totalForProviders = providersFee;
    if (!managersFee || !providersFee) {
      totalForManagers = numManagers ? Big(numManagers).times(perManagerSeat).toFixed(2) : '0.00';
      totalForProviders = numProviders ? Big(numProviders).times(perProviderSeat).toFixed(2) : '0.00';
    }
    return Big(baseLicenceFee).plus(totalForManagers).plus(totalForProviders).toFixed(2);
  }

  isEqual(other) {
    if (!this.isConsistent() || !other.isConsistent()) {
      return false;
    }
    return this.getTotal() === other.getTotal();
  }

  isConsistent() {
    const { fee } = this.details;
    if (fee !== this._aggregate()) {
      return false;
    }
    return true;
  }

  isConsistentWithScheme(licenceFeeScheme) {
    const { numManagers, numProviders } = this.details;
    if (!this.isConsistent()) {
      return false;
    }

    const applied = licenceFeeScheme.apply({
      numManagers, numProviders,
    });

    if (!this.isEqual(applied)) {
      return false;
    }

    return true;
  }

  makeConsistentScheme(licenceFeeScheme) {
    const {
      fee, managersFee, providersFee,
      numManagers, numProviders,
      perManagerSeat,
    } = this.details;
    const schemeBaseLicenceFee = licenceFeeScheme.getBaseLicenceFee();
    if (schemeBaseLicenceFee === fee) {
      licenceFeeScheme.setBaseManagerSeats(numManagers);
      licenceFeeScheme.setBaseProviderSeats(numProviders);
      return licenceFeeScheme;
    }

    const remainder = Big(fee).minus(schemeBaseLicenceFee).toFixed(2);
    if (Big(fee).gt(schemeBaseLicenceFee)) {

      if (managersFee !== '0.00' && providersFee === '0.00') {
        const extraManagers = Big(remainder).div(perManagerSeat).toNumber();
        const baseManagerSeats = numManagers - extraManagers;
        licenceFeeScheme.setBaseManagerSeats(baseManagerSeats);
        return licenceFeeScheme;
      }
    }

    throw new Error('cannot make fee scheme consistent');
  }

  serialize() {
    return this.details;
  }

  isZero() {
    return this.getTotal() === '0.00';
  }

  hasFee() {
    return this.getTotal() !== '0.00';
  }

  getNumProviders() {
    const { numProviders } = this.details;
    return numProviders || 0;
  }

  getNumManagers() {
    const { numManagers } = this.details;
    return numManagers || 0;
  }

  getProvidersFee() {
    const { providersFee: fee } = this.details;
    return fee || '0.00';
  }

  getManagersFee() {
    const { managersFee: fee } = this.details;
    return fee || '0.00';
  }

  hasManagersFee() {
    return this.getManagersFee() !== '0.00';
  }

  getPerManagerSeat() {
    const { perManagerSeat } = this.details;
    return perManagerSeat;
  }

  getPerProviderSeat() {
    const { perProviderSeat } = this.details;
    return perProviderSeat;
  }

  /**
   * Returns true if both managers and providers
   * have fees and their respective counts are greater than zero
   * @returns {Boolean}
   */
  hasManagerAndProvidersFeeApplied() {
    return !!(this.getNumManagers()
      && this.getNumProviders()
      && this.getManagersFee() !== '0.00'
      && this.getProvidersFee() !== '0.00');
  }

  getBaseFee() {
    const { baseLicenceFee: fee } = this.details;
    return fee || '0.00';
  }

  getTotal() {
    const { fee } = this.details;
    return fee || '0.00';
  }

  /**
   * @returns {String}
   */
  getServiceTitle() {
    return 'Licence service';
  }

  /**
   * @returns {String}
   */
  getServiceMetricsDescriptions() {
    const {
      numManagers,
      numProviders,
      extraManagers,
      extraProviders,
      perManagerSeat,
      perProviderSeat,
    } = this.details;
    const baseManagerSeats = numManagers - extraManagers;
    const baseProviderSeats = numProviders - extraProviders;

    const baseSeats = [];

    if (baseManagerSeats) {
      baseSeats.push(`${baseManagerSeats} included manager ${pluralize('seat', baseManagerSeats)}`);
    }
    if (baseProviderSeats) {
      baseSeats.push(`${baseProviderSeats} included provider ${pluralize('seat', baseProviderSeats)}`);
    }

    const baseSeatSubtitle = baseManagerSeats || baseProviderSeats ? `(${baseSeats.join(' and ')})` : '';

    return [{
      description: `Base platform fee ${baseSeatSubtitle}`,
      fee: this.getBaseFee(),
      quantity: 1,
    }, {
      description: `Additional manager seats @ ${perManagerSeat}`,
      fee: this.getManagersFee(),
      quantity: extraManagers,
    }, {
      description: `Additional provider seats @ ${perProviderSeat}`,
      fee: this.getProvidersFee(),
      quantity: extraProviders,
    }];
  }

  /**
   * Returns the fee breakdown items
   *
   * @returns {Array}
   */
  getFeeBreakdownItems() {
    const { feeBreakdownItems } = this.details;

    return feeBreakdownItems;
  }

  /**
   * Returns a metrics structure of the feeBreakdownItems
   *
   * @returns {{
   *   total: string,
   *   count: number
   *   orgId: number,
   * }[]} the metrics of the feeBreakdownItems
   */
  getFormattedFeeBreakdownItems() {
    const feeBreakdownItems = this.getFeeBreakdownItems();
    if (isEmpty(feeBreakdownItems)) {
      return [];
    }
    const {
      numManagers,
      numProviders,
      extraManagers,
      extraProviders,
      perManagerSeat,
      perProviderSeat,
    } = this.details;
    let baseManagerSeats = numManagers - extraManagers;
    let baseProviderSeats = numProviders - extraProviders;

    const formattedFeeBreakdownItems = feeBreakdownItems.map((item) => {
      const { orgId } = item;
      const providerUserIds = item.providerUserIds || [];
      const managerUserIds = item.managerUserIds || [];

      // count providers, reducing by baseProviderSeats if existing
      let providerCount = providerUserIds.length;
      if (providerCount <= baseProviderSeats) {
        baseProviderSeats -= providerCount;
        providerCount = 0;
      } else {
        providerCount -= baseProviderSeats;
        baseProviderSeats = 0;
      }

      // count managers, reducing by baseManagerSeats if existing
      let managerCount = managerUserIds.length;
      if (managerCount <= baseManagerSeats) {
        baseManagerSeats -= managerCount;
        managerCount = 0;
      } else {
        managerCount -= baseManagerSeats;
        baseManagerSeats = 0;
      }

      const totalCount = providerCount + managerCount;

      const managerCharges = Big(perProviderSeat).times(providerCount).toFixed(2);
      const providerCharges = Big(perManagerSeat).times(managerCount).toFixed(2);
      const totalCharges = Big(managerCharges).plus(providerCharges).toFixed(2);

      return {
        orgId,
        count: totalCount,
        total: totalCharges,
      };
    });

    return formattedFeeBreakdownItems;
  }


  /**
   * Returns all needed feeBreakdown metrics
   *
   * @see getFormattedFeeBreakdownItems
   * @returns {{
   *   chargedUserIdsInMultipleOrgs: number[],
   *   chargedUserIdsInOneOrg: number[],
   *   chargedProviderUserIds: number[],
   *   chargedManagerUserIds: number[]
   * }}|{}
   */
  _getFeeBreakdownMetricIds() {
    const feeBreakdownItems = this.getFeeBreakdownItems();

    if (isEmpty(feeBreakdownItems)) {
      return {};
    }

    const hasManagerFee = this.getManagersFee() !== '0.00';
    const hasProviderFee = this.getProvidersFee() !== '0.00';

    let providerUserIds = [];
    let managerUserIds = [];

    const chargedUserIds = feeBreakdownItems
      .map((item) => {
        providerUserIds = [...providerUserIds, ...(item.providerUserIds || [])];
        managerUserIds = [...managerUserIds, ...(item.managerUserIds || [])];
        return ([
          ...(hasProviderFee ? item.providerUserIds || [] : []),
          ...(hasManagerFee ? item.managerUserIds || [] : []),
        ]);
      })
      .flat();

    const uniqChargedUserIds = uniq(chargedUserIds);

    const chargedProviderUserIds = providerUserIds
      .filter(userId => chargedUserIds.includes(userId));
    const chargedManagerUserIds = managerUserIds
      .filter(userId => chargedUserIds.includes(userId));

    const chargedUserIdsInMultipleOrgs = uniqChargedUserIds
      .filter(userId => chargedUserIds.filter(_userId => _userId === userId).length > 1);
    const chargedUserIdsInOneOrg = difference(chargedUserIds, chargedUserIdsInMultipleOrgs);

    const metrics = {
      chargedUserIdsInMultipleOrgs,
      chargedUserIdsInOneOrg,
      chargedProviderUserIds,
      chargedManagerUserIds,
    };

    return metrics;
  }

  getFormattedFeeBreakdownItemsWithMetrics() {
    const metrics = this._getFeeBreakdownMetricIds();
    const formattedFeeBreakdownItems = this.getFormattedFeeBreakdownItems();

    return ({
      ...metrics,
      formattedFeeBreakdownItems,
    });
  }
}

export default SeatFeeAnalysis;
