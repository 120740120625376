import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';

import ProfileForm from 'accounts/assets/js/components/ProfileForm.jsx';
import { downloadFile } from 'accounts/assets/js/lib/helpers';
import ProfileSettingsView from 'accounts/assets/js/ProfileSettingsView.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { completeStepDS, fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { postProfileDS } from 'accounts/assets/js/data-services/profiles';
import { refreshAccountDS } from 'accounts/assets/js/data-services/account';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const ProfileInformationTab = ({
  dispatch,
  history,
  profile,
  activeOrg: { alias },
}) => {
  const [loadedFiles, setLoadedFiles] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [homeUrl, setHomeUrl] = useState(null);

  // Check if coming from get started page
  const fromGetStarted = !!history?.location?.state?.fromGetStarted;

  const loadFiles = async ({ attachmentFileIds, avatarFileId }) => {
    const fileIds = attachmentFileIds || [];
    if (avatarFileId) {
      fileIds.push(avatarFileId);
    }
    if (fileIds.length === 0) {
      return;
    }
    try {
      const downloadedFiles = await Promise.all(fileIds.map(async fileId => {
        const file = await downloadFile(fileId);
        return { file, fileId };
      }));
      const newAttachmentFiles = [];
      downloadedFiles.forEach(({ file, fileId }) => {
        if (fileId === avatarFileId) {
          setAvatarFile(file);
        } else {
          newAttachmentFiles.push(file);
        }
      });
      setAttachmentFiles(newAttachmentFiles);
    } catch (e) {
      toastr.error('Oh Snap!', e._error || e.message);
    }
  };

  useEffect(() => {
    loadFiles(profile)
      .then(() => {
        setLoadedFiles(true);
      });
  }, []);

  if (!loadedFiles) {
    return null;
  }

  const profileInitialValues = {
    address: profile.address,
    attachments: attachmentFiles,
    avatar: avatarFile,
    dateOfBirth: profile.dateOfBirth,
    first_name: profile.firstName,
    middle_name: profile.middleName,
    job_title: profile.jobTitle,
    languages: profile.languages,
    last_name: profile.lastName,
    phone: profile.phone,
    resume: profile.resume,
    skills: profile.skills,
  };

  const handleProfileUpdated = async (values) => {
    const componentName = ProfileSettingsView.GetComponentName();
    try {
      const { updated } = await dispatch(postProfileDS(values, componentName));
      await dispatch(fetchSetupStepsDS());

      if (updated.is_complete && !profile.is_complete) {
        await completeStepDS(dispatch, alias, 'ProfileFillStep', { history });
        const { auth } = await dispatch(refreshAccountDS());
        setHomeUrl(auth.userProfile.homeUrl);
        return {};
      }

      toastr.success('Well Done!', 'Your profile has been updated successfully.');
      await loadFiles(updated);
      return null;
    } catch (error) {
      const propertyErrors = omit(error.errors || {}, '_error', '_meta');
      if (Object.keys(propertyErrors).length > 0) {
        return propertyErrors;
      }
      return { [FORM_ERROR]: error._error || error.message };
    }
  };

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <div className="page--settings__content clearfix">
        {!profile.is_complete && (
          <TDSystemMessage
            type={BS_STYLE.WARNING}
            className="mb-4"
            title="You're almost there!"
          >
            <p> Please fill in your profile </p>
          </TDSystemMessage>
        )}
        {homeUrl && (
          <TDSystemMessage
            type={BS_STYLE.SUCCESS}
            className="mb-4"
            title="Your profile has been completed"
          >
            <p>
              You can continue
              {' '}
              <Link to={homeUrl}>here</Link>
            </p>
          </TDSystemMessage>
        )}
        <div className="settings-row">
          <h3 className="heading-block">Personal Information</h3>
          <ProfileForm
            fromGetStarted={fromGetStarted}
            initialValues={profileInitialValues}
            profile={profile}
            onSubmit={handleProfileUpdated}
          />
        </div>
      </div>
    </div>
  );
};

ProfileInformationTab.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProfileInformationTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileInformationTab);

export default withRouter(ProfileInformationTabConnected);
