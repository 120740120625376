import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const OffCanvas = ({
  children,
  containerClassName,
  overlayClassName,
  isOpen,
  width,
  height,
}) => {
  const [body, setBody] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals, no-undef
    setBody(document.body);
  }, []);

  return body && (
    createPortal(
      <div className={overlayClassName}>
        <div
          style={{
            width,
            height,
            transform: `translateX(${isOpen ? '0' : `-${width}`})`,
          }}
          className={containerClassName}
        >
          {children}
        </div>
      </div>,
      body,
    )
  );
};

OffCanvas.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  overlayClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default OffCanvas;
