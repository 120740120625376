import PropTypes from 'prop-types';
import React from 'react';

import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import RequiredDocumentsCollapsible from 'people/assets/js/components/profile/RequiredDocumentsCollapsible.jsx';
import OnboardingForms from 'people/assets/js/components/profile/OnboardingForms.jsx';

const PeopleOnboardingTab = ({ parentComponentName, userCard }) => (
  <>
    <OnboardingForms
      parentComponentName={parentComponentName}
      userCard={userCard}
    />
    <RequiredDocumentsCollapsible parentComponentName={parentComponentName} />
  </>
);


PeopleOnboardingTab.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
  userCard: userCardSpec.isRequired,
};

export default PeopleOnboardingTab;
