import React from 'react';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';
import ModalErrorSubmission from 'core/assets/js/components/ModalErrorSubmission.jsx';
import TWBankAccountDynamicFieldsContainer from 'settings/assets/js/components/tw-form-components/TWBankAccountDynamicFieldsContainer.jsx';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';

const TWWizardBankDetails = ({
  bankAccount,
  descriptor,
  goToPage,
  mutators: formMutators,
  orgCurrencies,
  pristine,
  showErrorSubmissionOption,
  formValues,
}) => {
  const { currency, address: { country } } = formValues;
  const hasSelectedCountry = !!country;
  const hasSelectedCurrency = !!currency;
  if (!hasSelectedCurrency || !hasSelectedCountry) {
    return null;
  }

  return (
    <>
      <TWBankAccountDynamicFieldsContainer
        bankAccount={bankAccount}
        formValues={formValues}
        mayRefetch={!pristine}
        orgCurrencies={orgCurrencies}
        descriptor={descriptor}
        onCountrySelect={() => goToPage(0)}
        formMutators={formMutators}
      />
      { showErrorSubmissionOption && (
        <div className="row">
          <div className="col-12">
            <div className="discreet">
              <span className={`${ICON.WARNING_CIRCLE} mr-2`} />
              <span>
                If your can&apos;t save your bank account details,
              </span>
              {' '}
              <ModalErrorSubmission
                modalId="bank-account-error-report"
                linkClassName="d-inline"
                linkName="click here"
                pageView="TW bank account forms"
              />
              {' '}
              to report to our payment experts the issues you face.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

TWWizardBankDetails.propTypes = {
  bankAccount: bankAccountSpec,
  descriptor: PropTypes.string.isRequired,
  goToPage: PropTypes.func.isRequired,
  mutators: PropTypes.object.isRequired,
  orgCurrencies: PropTypes.array.isRequired,
  pristine: PropTypes.bool.isRequired,
  showErrorSubmissionOption: PropTypes.bool.isRequired,
  formValues: PropTypes.object.isRequired,
};

TWWizardBankDetails.defaultProps = {
  bankAccount: {},
};

export default TWWizardBankDetails;
