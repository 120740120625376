import { FORM_ERROR } from 'final-form';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { USER_TYPE_LABEL } from 'core/assets/js/constants';
import { getViewState } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import InterviewForm from 'interviews/assets/js/components/InterviewForm.jsx';
import { fetchInterviewDS, postInterviewDS } from 'interviews/assets/js/data-services/interviews';
import { parseInterviewBody } from 'interviews/assets/js/lib/utils';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import { orgTemplatesManageUrl } from 'settings/urls';

const InterviewEditView = ({ history, location: { search }, match: { params } }) => {
  const { orgAlias } = params;
  const dispatch = useDispatch();
  const componentName = InterviewEditView.GetComponentName();

  useEffect(() => {
    dispatch(fetchInterviewDS({ componentName, params }));
  }, []);

  const { hasLoaded, item } = useSelector(state => getViewState(state, componentName));

  const initialValues = useMemo(
    () => (!hasLoaded ? {} : {
      documentIds: item.documentIds,
      name: item.name,
      questions: item.questions.map(q => ({
        ...q,
        payload: {
          ...q.payload,
          attachment_types: Array.isArray(q.payload.attachment_types)
            ? q.payload.attachment_types.map(value => ({ value }))
            : [],
          visibleTo: q.payload.visibleTo.map(value => ({ text: USER_TYPE_LABEL[value], value })),
        },
      })),
    }),
    [item?.id, params.interviewId],
  );

  if (!hasLoaded || !item?.id || (params.interviewId !== item.id.toString())) {
    return null;
  }

  const { canEdit } = item.allowedActions;

  const settingsPageUrl = orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.ONBOARDING);

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Onboarding forms', url: settingsPageUrl }, { title: item.name, url: null },
        ]}
      />
      <div className="page page--interview-create">
        <div className="container">
          {canEdit && (
            <InterviewForm
              initialValues={initialValues}
              onSubmit={async values => {
                try {
                  await dispatch(postInterviewDS(
                    params, parseInterviewBody(values), InterviewEditView.GetComponentName()),
                  );
                  const queries = queryString.parse(search);
                  history.push(queries.backUrl || settingsPageUrl);
                  toastr.success(
                    'Onboarding form saved!',
                    'Your Onboarding form has been saved. You can revisit this page and extend it',
                  );
                  return null;
                } catch (err) {
                  const errors = omit(err.response?.data || {}, '_error', '_meta');
                  if (Object.keys(errors).length > 0) {
                    return errors;
                  }
                  return { [FORM_ERROR]: err.response?.data._error || err.message };
                }
              }}
            />
          )}
          {!canEdit && 'You cannot edit this onboarding form'}
        </div>
      </div>
    </>
  );
};

InterviewEditView.GetComponentName = () => 'InterviewEditView';

InterviewEditView.propTypes = {
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(InterviewEditView);
