/* eslint-disable react/no-multi-comp,no-param-reassign,react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import NumberFormat from 'react-number-format';

import TDTable from 'core/assets/js/components/TDTable.jsx';
import { DATE_FORMAT_DEFAULT } from 'core/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import { getDisplayRate } from 'rates/assets/js/lib/utils';

function moneyFormatter(currencySymbol, cell) {
  return (<NumberTpl value={cell} prefix={currencySymbol} />);
}

function fixedDecimalsFormatter(cell, { ...rest }) {
  return (<NumberTpl value={cell} decimals={2} {...rest} />);
}

function amountDiffFormatter(cell, currencySymbol) {
  const direction = (cell > 0 ? '+' : '');
  return (<NumberTpl value={cell} decimals={2} prefix={`${direction} ${currencySymbol}`} />);
}

function numberFormatter(cell) {
  return (<NumberFormat value={Number(cell)} displayType="text" thousandSeparator />);
}

function dateFormatter(cell, format = DATE_FORMAT_DEFAULT) {
  return formatDate(cell, format);
}

function errorFormatter(cell) {
  return (cell ? (
    <span className="text-danger">{cell}</span>) : <span className="text-success">Valid</span>
  );
}

/**
 * Table wrapper component
 * @param {Array} cols
 * @param {Array} containerClass
 * @param {Array} items
 * @param {Object} tableProps Table properties object to apply to <BootstrapTable>
 * @param {Object} tableOpts Table options object to apply to <BootstrapTable options={...}>
 * @param {Object} onRowClick Callback on row click
 * @param {String} keyField  String of items' prop to signify as unique key
 * @param {String|Boolean} emptyText
 * @param {Array} children
 * @param {Array} expandableRow
 * @param {Array} expandComponent
 * @param {Array} expandColumnOptions
 * @param {String} headerAlign
 * @param {Boolean} responsive
 * @param {Boolean} isLarge - Changes the breakpoint of responsive table feture. (transforms table
 *                            into list of cards earlier - 1440px instead of 1200px)
 * @param {Function} trClassName returning table row class name
 * @param {Boolean} useReactBootstrapTable
 */
function Table({
  children,
  cols,
  containerClass,
  emptyText,
  expandableRow,
  expandColumnOptions,
  expandComponent,
  headerAlign,
  isLarge,
  items,
  keyField,
  onRowClick,
  responsive,
  tableOpts,
  tableProps,
  trClassName,
}) {
  const headers = cols.map(col => {
    const {
      key, label, currencySymbol: currSymbol, isMoney, isNumber, isDate, width,
      dateFormat = DATE_FORMAT_DEFAULT, isFixedDecimals, isAmtDiff, rateUnitKey,
      tdStyle, isError,
      ...rest
    } = col;

    const dataFormat = (cell, row) => {
      const currencySymbol = isFunction(currSymbol) ? currSymbol(row) : currSymbol;
      // Data formatters
      if (isMoney && currencySymbol && !rest.dataFormat) {
        return moneyFormatter(currencySymbol, cell, row);
      }
      if (isFixedDecimals) {
        if (rateUnitKey && row[rateUnitKey]) {
          const rate = cell;
          const rateUnit = row[rateUnitKey];
          return getDisplayRate(rate, rateUnit, {
            currency: currencySymbol,
          });
        }
        return fixedDecimalsFormatter(cell, { prefix: currencySymbol || '' });
      }
      if (isAmtDiff) {
        return amountDiffFormatter(cell, currencySymbol);
      }
      if (isNumber) {
        return numberFormatter(cell, row);
      }
      if (isDate) {
        return dateFormatter(cell, dateFormat);
      }
      if (rest.dataFormat && isFunction(rest.dataFormat)) {
        return rest.dataFormat(cell);
      }

      if (isError) {
        return errorFormatter(cell);
      }
      return cell;
    };

    let dataLabel = null;
    if (typeof label !== 'object') {
      dataLabel = label;
    } else if (Array.isArray(label.props?.children)) {
      dataLabel = label.props.children[0];
    }

    return {
      dataField: key,
      dataFormat,
      headerAlign,
      label,
      tdAttr: { // Used by css on mobile view
        /**
         * Used by css on mobile view
         * There are cases where we include a (?) tooltip on table headers. In such cases,
         * we need to extract the wording (first child of the component)
         */
        'data-label': dataLabel,
      },
      tdStyle,
      width: width ? width.toString() : null,
      ...rest,
    };
  });

  const _containerClass = [];

  if (containerClass) {
    _containerClass.push(containerClass);
  }

  if (responsive) {
    _containerClass.push('react-bs-table--responsive');
  }

  if (isLarge) {
    _containerClass.push('react-bs-table--large');
  } else {
    _containerClass.push('react-bs-table--normal');
  }

  // Table options
  const options = {
    noDataText: emptyText,
    withoutNoDataText: emptyText === false,
    expandBodyClass: 'expanded-row',
    expandParentClass: 'expanded-row-parent',
    onRowClick,
    ...tableOpts,
  };

  // Auto-set first column as key if none is provided
  const aiKey = '__key__';
  if (keyField) {
    tableProps.keyField = keyField;
  } else {
    tableProps.keyField = cols[0].key === 'id'
      ? cols[0].key
      : aiKey;
  }
  if (items) {
    items.forEach((item, i) => { item[aiKey] = i; });
  }

  // If onRowClick function found, add show pointer on row hover.
  const rowClass = (row) => {
    const cssClasses = [];
    if (row.className) {
      cssClasses.push(row.className);
    }
    if (onRowClick) {
      cssClasses.push('clickable');
    }

    if (trClassName) {
      cssClasses.push(trClassName(row));
    }

    return cssClasses.join(' ');
  };

  return (
    <TDTable
      columns={headers}
      containerClass={_containerClass.join(' ')}
      data={items}
      expandColumnOptions={expandColumnOptions}
      expandComponent={expandComponent}
      expandableRow={expandableRow}
      options={options}
      trClassName={rowClass}
      {...tableProps}
    >
      {children}
    </TDTable>
  );
}

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  cols: PropTypes.array.isRequired,
  containerClass: PropTypes.string,
  emptyText: PropTypes.string,
  expandColumnOptions: PropTypes.object,
  expandComponent: PropTypes.func,
  expandableRow: PropTypes.func,
  headerAlign: PropTypes.string,
  isLarge: PropTypes.bool,
  items: PropTypes.array,
  keyField: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onRowClick: PropTypes.func,
  responsive: PropTypes.bool,
  tableOpts: PropTypes.object,
  tableProps: PropTypes.object,
  trClassName: PropTypes.func,
  width: PropTypes.number,
};

Table.defaultProps = {
  children: [],
  containerClass: null,
  emptyText: 'No records found',
  expandColumnOptions: {},
  expandComponent: null,
  expandableRow: null,
  headerAlign: 'left',
  isLarge: false,
  items: [],
  keyField: false,
  onRowClick: null,
  responsive: true,
  tableOpts: {},
  tableProps: {
    striped: false,
  },
  trClassName: null,
  width: null,
};

export default Table;
