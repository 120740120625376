import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import RoleForm from 'roles/assets/js/components/RoleForm.jsx';
import { fetchRoleDS, createRoleDS } from 'roles/assets/js/data-services/role';
import { roleEditUrl, rolesListUrl } from 'roles/urls';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import {
  ROLE_TABS,
  SYSTEM_ROLES,
  SYSTEM_ROLES_PERMISSIONS,
  SYSTEM_ROLES_LABEL,
  PERMISSIONS,
} from 'roles/assets/js/constants';

const RoleCreateView = ({
  dispatch,
  history,
  location: { search },
  match: { params: { orgAlias } },
}) => {
  const [sourceRole, setSourceRole] = useState(null);
  const { srcRole: sourceRoleId  } = queryString.parse(search);

  const fetchSourceRole = async () => {
    if (Object.values(SYSTEM_ROLES).includes(sourceRoleId)) {
      setSourceRole({
        name: `copy of ${SYSTEM_ROLES_LABEL[sourceRoleId]}`,
        permissions:
          sourceRoleId === SYSTEM_ROLES.SYSTEM_ADMIN
            ? Object.values(PERMISSIONS)
            : SYSTEM_ROLES_PERMISSIONS[sourceRoleId],
      });
    } else {
      const srcRole = await dispatch(
        fetchRoleDS({
          componentName: RoleCreateView.GetComponentName(),
          orgAlias,
          roleId: sourceRoleId,
        }),
      );

      setSourceRole({
        name: `copy of ${srcRole.name}`,
        permissions: srcRole.permissions.map(p => p.permission),
      });
    }
  };

  useEffect(() => {
    if (sourceRoleId) {
      fetchSourceRole();
    }
  }, [sourceRoleId]);

  const handleSubmit = async (values) => {
    try {
      const role = await dispatch(
        createRoleDS({
          orgAlias,
          values,
        }),
      );

      toastr.success('Well Done!', 'Role created successfully.');
      history.push(roleEditUrl(orgAlias, role?.id, ROLE_TABS.USERS));
      return true;
    } catch (err) {
      const errorData = err.errors;

      if (errorData?._error) {
        toastr.error('Oh Snap!', errorData._error);
      }

      return {
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }
  };

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          {
            title: 'Roles & Permissions',
            url: rolesListUrl(orgAlias),
          },
          { title: 'Create role', url: '' },
        ]}
      />
      <div className="page page--roles" data-testid="roles-list-page">
        <div className="container">
          <RoleForm
            initialValues={{ ...sourceRole }}
            isCreate
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

RoleCreateView.GetComponentName = () => 'RoleCreateView';

RoleCreateView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const RoleCreateViewConnected = connect(
  null,
  mapDispatchToProps,
)(RoleCreateView);


export default withRouter(RoleCreateViewConnected);
