/*
  The aim of this component is to replicate our usage of react-bootstrap-table 4.3.1
  So this can be a drop-in replacement for that now mothballed package
*/
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

import { ICON } from 'core/assets/js/constants';
import { isTableRowExpandElement } from 'core/assets/js/lib/utils';

const ALIGN_VALUES = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  START: 'start',
  END: 'end',
};

const getContentAlign = value => {
  switch (value) {
    case ALIGN_VALUES.START:
      return ALIGN_VALUES.LEFT;
    case ALIGN_VALUES.END:
      return ALIGN_VALUES.RIGHT;
    case ALIGN_VALUES.CENTER:
    case ALIGN_VALUES.LEFT:
    case ALIGN_VALUES.RIGHT:
      return value;
    default:
      return null;
  }
};

const expandableCellStyle = { width: '30px' };

export const TDTable = ({
  bordered,
  children,
  columns: columnsIn,
  containerClass,
  data,
  expandableRow,
  expandComponent,
  striped,
  options: { noDataText, onRowClick, withoutNoDataText } = {},
  trClassName: trClassNameIn,
  headerContainerClass,
}) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const hasData = data.length > 0;

  let trClassNamFn = () => '';
  if (typeof trClassNameIn === 'function') {
    trClassNamFn = trClassNameIn;
  } else if (typeof trClassNameIn === 'string') {
    trClassNamFn = () => trClassNameIn;
  }

  const expandableRowIsFn = typeof expandableRow === 'function';
  const hasAnExpandableRow = expandableRowIsFn && data.some(row => expandableRow(row));

  const columns = columnsIn.filter(column => !column.hidden);

  let columnsLength = columns.length;
  if (hasAnExpandableRow) {
    columnsLength += 1;
  }

  const containerClasses = ['td-table'];
  if (bordered) {
    containerClasses.push('bordered');
  }
  if (striped) {
    containerClasses.push('striped');
  }
  if (containerClass) {
    containerClasses.push(containerClass);
  }
  if (columns.some(column => column.width)) {
    containerClasses.push('layout-fixed');
  }

  return (
    <div className={containerClasses.join(' ')}>
      <table className="w-100">
        <colgroup>
          {hasAnExpandableRow && <col style={expandableCellStyle} />}
          {columns.map((column, columnIndex) => (
            <col key={columnIndex} style={{ width: column.width || null }} />
          ))}
        </colgroup>
        <thead className={headerContainerClass}>
          <tr>
            {hasAnExpandableRow && <th className="expand-cell px-4" style={expandableCellStyle} />}
            {columns.map((column, columnIndex) => {
              const hasLabel = !!column.label;
              const style = {};
              if (column.width) {
                style.width = column.width;
                style.minWidth = column.width;
              }

              const columnClassNames = [column.defaultClassName || 'px-4'];
              if (column.className) {
                columnClassNames.push(column.className);
              }

              return (
                <th className={columnClassNames.join(' ')} key={columnIndex} style={style}>
                  <div className={`header-content${hasLabel ? '' : ' empty'}`}>
                    {hasLabel && column.label}
                    {!hasLabel && 'Actions'}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {hasData && data.map((row, rowIndex) => {
            const isExpandable = expandableRowIsFn && expandableRow(row);
            let trClassName = trClassNamFn(row);
            const isExpanded = expandedRowIndex === rowIndex;
            if (isExpanded) {
              trClassName += ' expanded-row-parent';
            }
            return (
              <Fragment key={row.id || rowIndex}>
                <tr
                  className={trClassName}
                  onClick={event => {
                    if (!onRowClick) {
                      return;
                    }
                    if (isTableRowExpandElement(event)) {
                      // The expand row toggle has been clicked, so don't execute onRowClick
                      return;
                    }
                    onRowClick(row);
                  }}
                >
                  {isExpandable && (
                    <td
                      className="react-bs-table-expand-cell"
                      onClick={() => setExpandedRowIndex(isExpanded ? null : rowIndex)}
                    >
                      <i className={ICON.CHEVRON_RIGHT} />
                    </td>
                  )}
                  {columns.map((column, cellIndex) => {
                    const classNames = [];
                    const contentAlign = getContentAlign(column.dataAlign);
                    if (contentAlign) {
                      classNames.push(`text-${contentAlign}`);
                    }
                    const value = row[column.dataField];
                    if (column.columnClassName) {
                      classNames.push(
                        typeof column.columnClassName === 'string'
                          ? column.columnClassName
                          : column.columnClassName(value, row),
                      );
                    }
                    return (
                      <td
                        className={classNames.join(' ')}
                        key={cellIndex}
                        style={column.tdStyle}
                        {...column.tdAttr}
                      >
                        {column.dataFormat(row[column.dataField], row)}
                      </td>
                    );
                  })}
                </tr>
                {isExpanded && (
                  <tr className="expanded-row">
                    <td className="p-4" colSpan={columnsLength}>
                      {expandComponent(row)}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
          {!hasData && !withoutNoDataText && (
            <tr>
              <td className="p-4 text-center react-bs-table-no-data" colSpan={columnsLength}>
                {noDataText}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {children}
    </div>
  );
};

TDTable.propTypes = {
  bordered: PropTypes.bool,
  striped: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  columns: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    columnClassName: PropTypes.string,
    defaultClassName: PropTypes.string,
    dataAlign: PropTypes.oneOf(Object.values(ALIGN_VALUES)),
    dataField: PropTypes.string.isRequired,
    dataFormat: PropTypes.func,
    headerAlign: PropTypes.oneOf(Object.values(ALIGN_VALUES)),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tdAttr: PropTypes.object,
    tdStyle: PropTypes.object,
    width: PropTypes.string,
  })).isRequired,
  containerClass: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  expandColumnOptions: PropTypes.shape({
    columnWidth: PropTypes.string,
    expandColumnComponent: PropTypes.func,
  }),
  expandComponent: PropTypes.func,
  expandableRow: PropTypes.func,
  headerContainerClass: PropTypes.string,
  keyField: PropTypes.string.isRequired,
  options: PropTypes.shape({
    expandBodyClass: PropTypes.string,
    expandParentClass: PropTypes.string,
    noDataText: PropTypes.string,
    onRowClick: PropTypes.func,
    withoutNoDataText: PropTypes.bool,
  }),
  trClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

TDTable.defaultProps = {
  bordered: true,
  striped: false,
  children: null,
  containerClass: '',
  expandableRow: null,
  expandColumnOptions: {
    columnWidth: null,
    expandColumnComponent: () => null,
  },
  expandComponent: null,
  options: {
    expandBodyClass: null,
    expandParentClass: '',
    noDataText: 'No records found',
    onRowClick: null,
    withoutNoDataText: false,
  },
  headerContainerClass: '',
  trClassName: '',
};

export default TDTable;
