import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';

import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import { ICON } from 'core/assets/js/constants';
import { interviewPendingInvitationSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import {
  interviewAllowedActionsSpec, interviewQuestionSpec,
} from 'interviews/assets/js/lib/objectSpecs';

const InterviewCardItem = ({
  allowedActions,
  editUrl,
  history,
  inviteesUrl,
  name,
  onDelete,
  onDuplicate,
  onManageUrl,
  pendingInvitationCount,
  publicUrl,
  questions,
  url,
}) => (
  <div className="row interview-card-item rounded shadow-sm p-4 bg-white mb-3 mx-0">
    <div className="col-12 col-sm-4 d-flex align-items-center mb-5 mb-sm-0">
      <i className={`${ICON.CLIPBOARD_LIST_CHECK_DUOTONE} fa-3x`} />
      <div className="d-flex flex-column ml-5">
        <Link to={url}><h3 className="m-0">{name}</h3></Link>
        <div className="interview-list-item__meta">
          {pluralize('question', questions.length, true)}
        </div>
      </div>
    </div>
    <div
      className={(
        'col-6 col-sm-4 d-flex align-items-center justify-content-start justify-content-sm-center'
      )}
    >
      {pendingInvitationCount > 0 && (
        <StatusTag
          label={pluralize('pending invitations', pendingInvitationCount, true)}
          statusClass="pending"
        />
      )}
    </div>
    <div className="col-6 col-sm-4 d-flex align-items-center justify-content-end">
      <TDDropChevronButton data-testid="interview-card-item-actions" useDropButton>
        {allowedActions.canView && (
          <Dropdown.Item eventKey="1" onClick={() => history.push(url)}>Preview</Dropdown.Item>
        )}
        {allowedActions.canEdit && (
          <Dropdown.Item eventKey="2" onClick={() => history.push(editUrl)}>Edit</Dropdown.Item>
        )}
        {allowedActions.canCreate && (
          <Dropdown.Item eventKey="3" onClick={onDuplicate}>Duplicate</Dropdown.Item>
        )}
        {(
          (publicUrl && allowedActions.canRevokeInviteLink)
          || (!publicUrl && allowedActions.canGenerateInviteLink)
        ) && (
          <Dropdown.Item
            data-testid="interview-card-dropdown-invite-link"
            eventKey="4"
            onClick={onManageUrl}
          >
            Invite Link
          </Dropdown.Item>
        )}
        {pendingInvitationCount > 0 && (
          <Dropdown.Item eventKey="5" onClick={() => history.push(inviteesUrl)}>
            View invitees
          </Dropdown.Item>
        )}
        {allowedActions.canDelete && (
          <Dropdown.Item eventKey="6" onClick={onDelete}>Delete</Dropdown.Item>
        )}
      </TDDropChevronButton>
    </div>
  </div>
);
InterviewCardItem.propTypes = {
  allowedActions: interviewAllowedActionsSpec.isRequired,
  name: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(interviewQuestionSpec).isRequired,
  pendingInvitations: PropTypes.arrayOf(interviewPendingInvitationSpec).isRequired,
  url: PropTypes.string.isRequired,
  editUrl: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  publicUrl: PropTypes.string,
  inviteesUrl: PropTypes.string.isRequired,
  onManageUrl: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  pendingInvitationCount: PropTypes.number.isRequired,
};

InterviewCardItem.defaultProps = {
  publicUrl: null,
};

export default withRouter(InterviewCardItem);
