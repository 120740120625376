import React from 'react';
import PropTypes from 'prop-types';

const TDSwitch = ({ className, disabled, onClick, selected, 'data-testid': dataTestId }) => {
  const classNames = ['custom-switch'];

  if (selected) {
    classNames.push('custom-switch--selected');
  }

  if (disabled) {
    classNames.push('custom-switch--disabled');
  }

  if (className) {
    classNames.push(className);
  }

  return (
    <span
      className={classNames.join(' ')}
      onClick={(...args) => !disabled && onClick(...args)}
      data-testid={dataTestId}
    />
  );
};

TDSwitch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  'data-testid': PropTypes.string,
};

TDSwitch.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => {},
  selected: false,
  'data-testid': '',
};

export default TDSwitch;
