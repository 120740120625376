import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, USER_TYPE, USER_TYPE_LABEL } from 'core/assets/js/constants';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import SubmissionQuestion from 'interviews/assets/js/components/SubmissionQuestion.jsx';
import { submissionSpec } from 'interviews/assets/js/lib/objectSpecs';
import { mapValuesToPostFields } from 'interviews/assets/js/lib/utils';
import { submissionApiUrl } from 'interviews/urls';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const SubmissionForm = ({
  containerClassName, fromGetStarted, displayName, onCancel, onSubmit, submission,
}) => {
  const activeOrg = useSelector(selectActiveOrg);
  return (
    <Form
      initialValues={{ ...submission.answers, draft: false }}
      onSubmit={async values => {
        try {
          const { data: { submission: updated } } = await axios.put(
            submissionApiUrl(activeOrg.alias, submission.id), mapValuesToPostFields(values),
          );
          await onSubmit(updated);
          return null;
        } catch (error) {
          return parseAxiosErrorForFinalForm(error);
        }
      }}
      render={({ form: { change, getState }, handleSubmit }) => {
        const { submitError, submitting } = getState();
        return (
          <form onSubmit={handleSubmit}>
            <div className={containerClassName}>
              {displayName && <h3>{submission.name}</h3>}
              <div className="clearfix mb-4">
                {submission.questions.map(q => {
                  const canSubmit = q?.allowedActions?.canSubmit;
                  const answeredByUserType = q.answeredByUserType
                    || q.answered_by_user_type
                    || USER_TYPE.PROVIDER;

                  if (fromGetStarted && !canSubmit) {
                    return null;
                  }

                  return (
                    <div
                      data-testid="submission-form-reviewer-comment"
                      className={`submission-question-wrap${!canSubmit ? ' disabled' : ''}`}
                      key={q.path}
                    >
                      <SubmissionQuestion
                        additionalError={submission.errors && submission.errors[q.path]}
                        disabled={!canSubmit}
                        interviewId={submission.interviewId}
                        key={q.path}
                        orgId={activeOrg.id}
                        question={q}
                        submissionId={submission.id}
                      />
                      {!canSubmit && (
                        <p className="warning">
                          The above question will be filled in by the
                          {` ${USER_TYPE_LABEL[answeredByUserType].toLowerCase()}.`}
                          <br />
                          The answer will be visible to users of type:
                          {` ${(q.payload.visibleTo || []).map(t => USER_TYPE_LABEL[t]).join(', ')}.`}
                        </p>
                      )}
                    </div>
                  );
                })}
                {submitError && <p className="text-danger">{submitError}</p>}
              </div>
            </div>
            <div className="mt-5 button-container text-right">
              {onCancel && (
                <TDButton
                  data-testid="button-cancel"
                  className="float-left"
                  disabled={submitting}
                  label="Cancel"
                  onClick={onCancel}
                  variant={BS_STYLE.DEFAULT}
                />
              )}
              {submission.allowedActions.canSaveDraft && (
                <TDButton
                  data-testid="button-save-draft"
                  disabled={submitting}
                  label="Save draft"
                  onClick={() => {
                    change('draft', true);
                  }}
                  type="submit"
                  variant={BS_STYLE.DEFAULT}
                />
              )}

              <TDButton
                data-testid="submission-form-button-save-submit"
                disabled={submitting}
                label={fromGetStarted ? 'Save & continue' : 'Submit'}
                onClick={() => {
                  change('draft', false);
                }}
                type="submit"
                variant={BS_STYLE.PRIMARY}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

SubmissionForm.propTypes = {
  containerClassName: PropTypes.string,
  displayName: PropTypes.bool,
  fromGetStarted: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  orgAlias: PropTypes.string.isRequired,
  submission: submissionSpec.isRequired,
  userCard: userCardSpec.isRequired,
};

SubmissionForm.defaultProps = {
  containerClassName: 'container rounded shadow-sm p-4 bg-white',
  displayName: true,
  fromGetStarted: false,
  onCancel: null,
  onSubmit: () => null,
};

export default SubmissionForm;
