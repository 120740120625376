import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import { getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  fetchProjectTaskDS,
  fetchProjectTaskAssignmentsDS,
  fetchProjectTaskInfoDS,
} from 'projects/assets/js/data-services/tasks';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { getListStateExtras } from 'core/assets/js/ducks/list';
import {
  projectSpec,
  projectTaskSpec,
  taskAssignmentSpec,
  taskInfoSpec,
  taskParticipantSpec,
} from 'projects/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { projectListUrl, projectViewUrl, projectViewTaskUrl } from 'projects/urls';
import { BS_STYLE, PROJECT_TABS, TASK_TABS } from 'core/assets/js/constants';
import { PROJECT_MEMBER_STATUS, TASK_ACTION } from 'projects/assets/js/constants';
import { getTaskPendingActions } from 'projects/assets/js/lib/helpers';
import { fetchProjectManagersDS } from 'projects/assets/js/data-services/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProjectTaskTabs from 'projects/assets/js/components/ProjectTaskTabs.jsx';
import ProjectCta from 'projects/assets/js/components/ProjectCta.jsx';
import TaskOwnerModal, { MODAL_ID as TASK_OWNER_MODAL_ID } from 'projects/assets/js/components/TaskOwnerModal.jsx';
import withPendingInvitationCheck from 'core/assets/js/components/withPendingInvitationCheck.jsx';
import TaskAddManagersPanel, { MODAL_ID as ADD_MANAGERS_TO_TASK_MODAL_ID } from 'projects/assets/js/components/TaskAddManagersPanel.jsx';

export const COMPONENT_NAME = 'ProjectTaskView';

const OWNERSHIP_WORKING_ACTIONS = [
  TASK_ACTION.ACCEPT_ASSIGNMENT_INVITATION,
  TASK_ACTION.REJECT_ASSIGNMENT_INVITATION,
  TASK_ACTION.MANAGE_ASSIGNMENT_INVITATION,
  TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION,
  TASK_ACTION.ACCEPT_RATE_INVITATION,
  TASK_ACTION.REJECT_RATE_INVITATION,
  TASK_ACTION.CANCEL_RATE_INVITATION,
  TASK_ACTION.ACCEPT_COMPLETION_INVITATION,
  TASK_ACTION.REJECT_COMPLETION_INVITATION,
  TASK_ACTION.CANCEL_COMPLETION_INVITATION,
];

class ProjectTaskView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { orgAlias, id: projectId, taskId } = params;

    return Promise.all([
      dispatch(
        fetchProjectTaskDS({ orgAlias, projectId, taskId, authedAxios, url, componentName }),
      ),
      dispatch(fetchProjectTaskAssignmentsDS({
        authedAxios, componentName, orgAlias, projectId, taskId, url,
      })),
      dispatch(
        fetchProjectTaskInfoDS({ orgAlias, projectId, taskId, authedAxios, url, componentName }),
      ),
      dispatch(
        fetchProjectManagersDS({ orgAlias, id: projectId, authedAxios, url }),
      ),
    ]);
  }

  static GetComponentName() {
    return COMPONENT_NAME;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentParticipant: null,
    };

    this.handleTaskUpdated = this.handleTaskUpdated.bind(this);
    this.handleOpenDiscussionBoard = this.handleOpenDiscussionBoard.bind(this);
    this.handleDiscussionSelected = this.handleDiscussionSelected.bind(this);
    this.refreshTaskStateAndAssignments = this.refreshTaskStateAndAssignments.bind(this);
    this.refreshTaskInfo = this.refreshTaskInfo.bind(this);
    this.getActiveTabFromPathname = this.getActiveTabFromPathname.bind(this);
  }

  /**
   * The reasoning behind this function is that we can't provide the tab name as a router param
   * because when switching from the group discussion to the user-specific discussion, it would
   * trigger requests to refresh the task. While this isn't so terrible, it's a waste of resources
   *
   * @returns {String}
   */
  getActiveTabFromPathname() {
    const { location: { pathname } } = this.props;
    const matches = pathname.match(
      new RegExp(`/(${Object.values(TASK_TABS).join('|')})(\\/|\\/.+|\\?.+|)$`),
    );

    return !isEmpty(matches) && matches[1] ? matches[1] : TASK_TABS.DASHBOARD;
  }

  handleOpenDiscussionBoard() {
    const {
      dispatch,
      history,
      match: { params: { orgAlias } },
      project: { id: projectId },
      task: { id: taskId },
    } = this.props;

    dispatch(modalCloseAC(TASK_OWNER_MODAL_ID));

    // TASK_OWNER_MODAL_ID
    history.push(
      projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION),
    );
  }

  handleDiscussionSelected(currentParticipant) {
    this.setState({ currentParticipant });
  }

  async handleTaskUpdated() {
    const {
      dispatch, match: { params: { orgAlias } }, project: { id: projectId }, task: { id: taskId },
      componentName,
    } = this.props;

    await Promise.all([
      dispatch(
        fetchProjectTaskDS({ orgAlias, projectId, taskId, componentName }),
      ),
      this.refreshTaskStateAndAssignments(),
    ]);
  }

  async refreshTaskStateAndAssignments() {
    const {
      componentName, dispatch, match: { params: { orgAlias, id: projectId, taskId } },
    } = this.props;

    await Promise.all([
      dispatch(fetchProjectTaskDS({ componentName, orgAlias, projectId, taskId })),
      dispatch(fetchProjectTaskAssignmentsDS({ componentName, orgAlias, projectId, taskId })),
    ]);
  }

  async refreshTaskInfo() {
    const {
      dispatch,
      componentName,
      match: { params: { orgAlias } }, project: { id: projectId }, task: { id: taskId },
    } = this.props;

    dispatch(
      fetchProjectTaskInfoDS({ orgAlias, projectId, taskId, componentName }),
    );
  }

  render() {
    const {
      match: { match, params: { orgAlias, id: projectId, taskId } },
      assignments,
      project,
      task,
      stateName,
      actions,
      managers,
      taskInfo,
      accessControl,
      participants,
      componentName,
      isAddMembersModalOpen,
    } = this.props;

    const taskTab = this.getActiveTabFromPathname();
    const { canSendPublicMessage, canMessageOwner } = accessControl;
    let discussionBoardUrl = projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION);

    // The user is not able to access the discussion board but they're allowed to message the
    // task owner: the discussion tab, should redirect to the discussion with the owner
    if (!canSendPublicMessage && canMessageOwner) {
      discussionBoardUrl = projectViewTaskUrl(
        orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, null, task.ownerId,
      );
    }

    const outstandingCount = getTaskPendingActions(actions).length;
    const tabSpec = [
      {
        href: projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DASHBOARD),
        key: TASK_TABS.DASHBOARD,
        label: 'Overview',
      },
      {
        href: projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.ASSIGNEES),
        key: TASK_TABS.ASSIGNEES,
        label: 'Assignees',
      },
    ];

    if (accessControl?.isManager) {
      // Add managers as the second tab
      tabSpec.push({
        key: TASK_TABS.MANAGERS,
        label: 'Managers',
        href: projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.MANAGERS),
      });
    }

    tabSpec.push(
      {
        href: discussionBoardUrl,
        key: TASK_TABS.DISCUSSION,
        label: 'Discussion',
        outstandingCount,
      },
      {
        href: projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DELIVERABLES),
        key: TASK_TABS.DELIVERABLES,
        label: 'Deliverables',
      },
    );

    // show worksheets tab only to managers
    if (accessControl?.isManager) {
      tabSpec.push({
        key: TASK_TABS.WORKSHEETS,
        label: 'Worksheets',
        href: projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.WORKSHEETS),
      });
    }

    const breadcrumbs = [
      {
        title: 'Projects',
        url: projectListUrl(orgAlias),
      },
      {
        title: project.title,
        url: projectViewUrl(orgAlias, project.id),
      },
      {
        title: 'Tasks',
        url: projectViewUrl(orgAlias, project.id, PROJECT_TABS.TASKS),
      },
      {
        title: task.title,
        url: null,
      },
    ];

    const { currentParticipant } = this.state;
    const ctaButton = (
      <ProjectCta
        currentTab={PROJECT_TABS.TASKS}
        participantUserId={!isEmpty(currentParticipant) ? currentParticipant.userId : null}
        project={project}
        rootComponentName={componentName}
        task={task}
        taskCounts={taskInfo}
        componentName={componentName}
        taskAllowedActions={accessControl}
        onTaskUpdated={this.handleTaskUpdated}
        taskActions={actions}
        taskAssignments={assignments}
      />
    );

    // Potential owners for the task, in case we need to transfer ownership
    const potentialOwners = managers.filter(pm => (
      pm.status === PROJECT_MEMBER_STATUS.ACCEPTED
    )).map(pm => pm.userCard.user);

    let ownershipWarning;
    if (!isEmpty(actions)) {
      const hasActionsThatTriggerWarning = actions.map(a => a.name).some(a => (
        OWNERSHIP_WORKING_ACTIONS.includes(a)
      ));

      if (hasActionsThatTriggerWarning) {
        ownershipWarning = (
          <React.Fragment>
            There are pending actions in the
            {' '}
            <a onClick={this.handleOpenDiscussionBoard} className="text-primary">
              discussion board
            </a>
            {' '}
            that can only be handled by the task owner. You can either handle them now,
            or wait for the new task owner to do so.
          </React.Fragment>
        );
      }
    }

    return (
      <TDApiConnected
        duck="view"
        component={this.constructor}
        storeKey={componentName}
        shouldRefetchOnQueryChange={false}
      >
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaComponent={ctaButton}
        >
          <div className="horizontal-scroll">
            <TabBar
              activeKey={taskTab}
              tabSpec={tabSpec}
            />
          </div>
        </ContentHeader>

        <div className={`page page--project page--task ${taskTab === TASK_TABS.DISCUSSION ? 'd-flex h-100' : ''}`}>
          <div
            className={`container ${taskTab === TASK_TABS.DISCUSSION ? 'd-flex flex-wrap h-100 align-self-start' : ''}`}
          >
            {task.deleted && (
              <div className="w-100 mb-5">
                <TDSystemMessage
                  type={BS_STYLE.WARNING}
                  title="This task is archived"
                >
                  The task has been archived and it is now in read-only mode
                </TDSystemMessage>
              </div>
            )}

            <ProjectTaskTabs
              componentProps={({
                assignments,
                activeTab: taskTab,
                task,
                project,
                accessControl,
                stateName,
                participants,
                taskActions: actions,
                parentComponentName: ProjectTaskView.GetComponentName(match),
                onTaskUpdated: this.handleTaskUpdated,
                onStateUpdated: this.refreshTaskStateAndAssignments,
                onInfoUpdated: this.refreshTaskInfo,
                onDiscussionSelected: this.handleDiscussionSelected,
              })}
            />

            <TaskOwnerModal
              managers={potentialOwners}
              parentComponentName={componentName}
              task={task}
              project={project}
              ownershipWarning={ownershipWarning}
              ownershipWarningTitle="Before you transfer ownership of this task"
            />

            { isAddMembersModalOpen && (
              <TaskAddManagersPanel open={isAddMembersModalOpen} />
            )}
          </div>
        </div>
      </TDApiConnected>
    );
  }
}

ProjectTaskView.propTypes = {
  assignments: PropTypes.arrayOf(taskAssignmentSpec),
  dispatch: PropTypes.func.isRequired,
  componentName: PropTypes.string.isRequired,
  isAddMembersModalOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  history: routerHistorySpec.isRequired,
  task: projectTaskSpec,
  project: projectSpec,
  accessControl: PropTypes.object,
  actions: PropTypes.array,
  stateName: PropTypes.string,
  taskInfo: taskInfoSpec,
  managers: PropTypes.arrayOf(userCardSpec),
  participants: PropTypes.arrayOf(taskParticipantSpec),
};

ProjectTaskView.defaultProps = {
  assignments: [],
  project: {},
  task: {},
  accessControl: {},
  actions: [],
  stateName: null,
  participants: [],
  managers: [],
  taskInfo: {},
};

const mapStateToProps = (state, props) => {
  const componentName = ProjectTaskView.GetComponentName(props.match);
  const viewState = getViewState(state, componentName);
  const taskState = getViewStateExtras(state, componentName, 'taskState');

  return {
    assignments: getListStateExtras(state, componentName, 'assignments'),
    componentName,
    task: viewState.item,
    project: getViewStateExtras(state, componentName, 'project'),
    accessControl: getViewStateExtras(state, componentName, 'accessControl'),
    taskInfo: getViewStateExtras(state, componentName, 'taskInfo'),
    actions: taskState.actions,
    isAddMembersModalOpen: getIsModalOpen(state, ADD_MANAGERS_TO_TASK_MODAL_ID),
    stateName: taskState.state,
    participants: taskState.participants,
    managers: state.projects.projectActive.managers,
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectTaskViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTaskView);

export default withPendingInvitationCheck(withRouter(ProjectTaskViewConnected));
