import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { pick } from 'lodash';

import GetStartedProgressNavBarItem from 'accounts/assets/js/components/GetStartedProgressNavBarItem.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { BS_STYLE, ICON, IMG_SIZE, LOGO } from 'core/assets/js/constants';
import { selectSummaries } from 'notifier/assets/js/ducks/notifications';
import GetPaidModal, { GET_PAID_MODAL_ID } from 'core/assets/js/components/Dashboard/GetPaidModal.jsx';
import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import NavBarItem from 'core/assets/js/components/NavBar/NavBarItem.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { selectPendingCounts } from 'core/assets/js/ducks/pendingCount';
import NavBarSectionWrapper from 'core/assets/js/components/NavBar/sections/NavBarSectionWrapper.jsx';
import NavBarNotificationsList from 'core/assets/js/components/NavBar/sections/NavBarNotificationsList.jsx';
import NavBarHelpList from 'core/assets/js/components/NavBar/sections/NavBarHelpList.jsx';
import NavBarSettingsList from 'core/assets/js/components/NavBar/sections/NavBarSettingsList.jsx';
import NavBarAccountMenu from 'core/assets/js/components/NavBar/sections/NavBarAccountMenu.jsx';
import NavBarOrgsList from 'core/assets/js/components/NavBar/sections/NavBarOrgsList.jsx';
import { selectActiveOrg, selectUserCards, selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { userCardSpec, orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { getHasOrgAccess, selectProfile } from 'accounts/assets/js/reducers/auth';
import { isMenuItemActive } from 'core/assets/js/lib/utils';
import { billingViewUrl } from 'finance/urls';
import { BILLING_TABS } from 'finance/assets/js/constants';


/**
 * The navigation bar
 *
 * @param props
 */
const MobileNavBar = ({
  activeOrg,
  activeUserCard,
  dispatch,
  handleClose,
  isBlockedByGetStarted,
  isGetPaidModalOpen,
  isMenuOpened,
  isMenuDisabled,
  items,
  location,
  match,
  newCount,
  profile,
  settingsSubItems,
  showAccountProgressBar,
  showBilling,
}) => {
  if (!profile) {
    return null;
  }

  const { name, jobTitle, avatar } = profile;
  const [activeSection, setActiveSection] = useState(null);
  const unreadClass = newCount ? 'td-dropdown__notifications--has-new' : '';
  const orgAlias = activeOrg.unique_alias || null;

  const SECTIONS = {
    ACCOUNT_MENU: 'account-menu',
    BILLING: 'billing',
    HELP: 'help',
    NOTIFICATIONS: 'notifications',
    ONBOARDING_STATUS: 'onboarding-status',
    ORGANIZATION_SELECT: 'org-select',
    SETTINGS: 'settings',
  };

  const isAccountDropDownActive = isMenuItemActive(
    match, location,
    [`${orgAlias}/settings/profile`,
      `${orgAlias}/settings/account`,
      `${orgAlias}/organizations`,
      `${orgAlias}/settings/time-off`,
    ],
  );

  const accountDropDownClass = ['td-drop-button__toggle mt-1 mb-5'];
  if (isAccountDropDownActive) {
    accountDropDownClass.push('account-drop-down__toggle--active');
  }

  const menuItems = items.map((item, index) => (
    <NavBarItem
      isSectionActive={isMenuItemActive(match, location, item.sections)}
      key={`${item.path}`}
      tabIndex={index}
      {...pick(item, ['count', 'icon', 'items', 'label', 'onClick', 'path', 'sections', 'suffix'])}
    />
  ));

  const onClose = (close = true) => {
    setActiveSection(null);

    if (close) {
      handleClose();
    }
  };

  const billingSections = useMemo(() => ([
    billingViewUrl(activeOrg.alias, BILLING_TABS.OVERVIEW),
    billingViewUrl(activeOrg.alias, BILLING_TABS.BILLING_HISTORY),
    billingViewUrl(activeOrg.alias, BILLING_TABS.SUBSCRIPTION),
  ]), [activeOrg.alias]);

  const onAddWidget = () => {
    dispatch(modalOpenAC(GET_PAID_MODAL_ID));
  };

  const isAnyManager = activeUserCard.userRole.isAnyManager;

  return (
    <>
      <a
        className={`off-canvas__close-button ${isMenuOpened ? 'off-canvas__close-button--open' : ''}`}
        onClick={() => onClose(true)}
      >
        <i className={ICON.CROSS} />
      </a>

      <div className="navbar-col mobile-navbar no-select">
        <div className={`navbar__backdrop ${activeSection ? 'navbar__backdrop--active' : ''}`} />
        <div className="navbar__top-section">
          <div
            onClick={() => setActiveSection(SECTIONS.ORGANIZATION_SELECT)}
            className="organization-switcher__selected-org"
          >
            <i className={`organization-switcher__switch-icon ${ICON.POPUP_WINDOW}`} />

            <div className="organization-switcher__org-item clearfix">
              <OrganizationLogo
                url={activeOrg.logo}
                orgName={activeOrg.name}
                size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                className="img-radius float-left organization-switcher__active-org-logo"
              />
              <div>
                <span className="organization-switcher__org-title truncate">
                  {activeOrg.name}
                </span>
                <span className="organization-switcher__user-role">
                  {activeUserCard.userRole.title}
                </span>
              </div>
            </div>
          </div>

          {!isAnyManager && !isMenuDisabled && !isBlockedByGetStarted && (
            <div className="get-paid-button-wrap col-auto mb-3 mt-3">
              <TDButton
                className="get-paid-button-wrap__button"
                onClick={onAddWidget}
                label="Get Paid"
                variant={BS_STYLE.PRIMARY}
              />
            </div>
          )}

          {isGetPaidModalOpen && (
            <GetPaidModal />
          )}
          {menuItems.length > 0 && (
            <div className="position-relative">
              {showAccountProgressBar && (
                <ul className={`navbar__menu mb-2 ${activeSection === SECTIONS.SETTINGS ? 'd-none' : ''}`}>
                  <GetStartedProgressNavBarItem />
                </ul>
              )}

              {!isMenuDisabled && !isBlockedByGetStarted && (
                <ul
                  className={`navbar__menu ${activeSection === SECTIONS.SETTINGS ? 'd-none' : ''}
                  ${isMenuDisabled ? 'navbar__menu--disabled' : ''}`}
                >
                  { menuItems }
                </ul>
              )}
            </div>
          )}
        </div>

        <div className="navbar__footer pt-2">
          { showBilling && (
            <div className="my-3">
              <NavBarItem
                isSectionActive={isMenuItemActive(match, location, billingSections)}
                key={billingViewUrl(activeOrg.alias)}
                path={billingViewUrl(activeOrg.alias)}
                label="Billing"
                icon={ICON.WALLET}
                sections={billingSections}
                tabIndex={-1}
              />
            </div>
          ) }
          <a
            className={`d-flex navbar-footer-toggle my-2 pr-3 ${unreadClass}`}
            onClick={() => setActiveSection(SECTIONS.SETTINGS)}
          >
            <div className="col px-0">
              <i className={`${ICON.COG}`} />
              Settings
            </div>
          </a>
          <a
            onClick={() => setActiveSection(SECTIONS.NOTIFICATIONS)}
            className={`d-flex navbar-footer-toggle my-2 pr-3 ${unreadClass}`}
          >
            <div className="col px-0">
              <i className={`${ICON.NOTIFICATIONS}`} />
              Notifications
            </div>

            <i className={`ms-auto mr-0 ${ICON.POPUP_WINDOW}`} />
          </a>
          <a
            onClick={() => setActiveSection(SECTIONS.HELP)}
            className={`d-flex navbar-footer-toggle my-2 pr-3 ${unreadClass}`}
          >
            <div className="col px-0">
              <i className={`${ICON.QUESTION_CIRCLE}`} />
              Help
            </div>

            <i className={`ms-auto mr-0 ${ICON.POPUP_WINDOW}`} />
          </a>
          <div
            className={accountDropDownClass.join(' ')}
            onClick={() => setActiveSection(SECTIONS.ACCOUNT_MENU)}
          >
            <a className="d-flex p-3 account-drop-down__toggle align-items-center">
              <ProfilePic
                url={avatar}
                alt={name}
                size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                className="mr-4 align-self-center"
              />

              <div className="flex-grow-1 flex-shrink-1 w-auto overflow-hidden pr-3">
                <div className="w-100">
                  <div title={name} className="truncate account-drop-down__name">{name}</div>
                </div>
                <div className="w-100">
                  <div title={jobTitle} className="truncate pr-2 account-drop-down__job-title">
                    {jobTitle}
                  </div>
                </div>
              </div>

              <i className={`${ICON.POPUP_WINDOW} navbar-item__slide-over-icon mr-0`} />
            </a>
          </div>

          <div className="text-center mt-2">
            Powered by
            <img src={LOGO.DEFAULT} className="navbar-col__logo ml-3" alt="TalentDesk.io" />
          </div>
        </div>

        <NavBarSectionWrapper
          isOpen={activeSection === SECTIONS.ORGANIZATION_SELECT}
          onClose={onClose}
          title="Organisations"
        >
          <NavBarOrgsList />
        </NavBarSectionWrapper>

        <NavBarSectionWrapper
          isOpen={activeSection === SECTIONS.ACCOUNT_MENU}
          onClose={onClose}
        >
          <NavBarAccountMenu orgAlias={activeOrg.alias} />
        </NavBarSectionWrapper>

        <NavBarSectionWrapper
          title="Notifications"
          isOpen={activeSection === SECTIONS.NOTIFICATIONS}
          onClose={onClose}
        >
          <NavBarNotificationsList />
        </NavBarSectionWrapper>

        <NavBarSectionWrapper
          title="Help"
          isOpen={activeSection === SECTIONS.HELP}
          onClose={onClose}
        >
          <NavBarHelpList />
        </NavBarSectionWrapper>

        <NavBarSectionWrapper
          isOpen={activeSection === SECTIONS.SETTINGS}
          onClose={onClose}
        >
          <NavBarSettingsList items={settingsSubItems} />
        </NavBarSectionWrapper>
      </div>
    </>
  );
};

MobileNavBar.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec,
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  isBlockedByGetStarted: PropTypes.bool.isRequired,
  isGetPaidModalOpen: PropTypes.bool,
  isMenuDisabled: PropTypes.bool.isRequired,
  isMenuOpened: PropTypes.bool,
  items: PropTypes.array,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  newCount: PropTypes.number.isRequired,
  onMenuClick: PropTypes.func,
  pendingCounts: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    homeUrl: PropTypes.string,
    jobTitle: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    userRole: PropTypes.string,
  }),
  settingsSubItems: PropTypes.array.isRequired,
  showAccountProgressBar: PropTypes.bool,
  showNotifications: PropTypes.bool,
  showBilling: PropTypes.bool,
  userCards: PropTypes.array,
  withLogo: PropTypes.bool,
};

MobileNavBar.defaultProps = {
  activeUserCard: {},
  isGetPaidModalOpen: false,
  isMenuOpened: false,
  items: [],
  onMenuClick: null,
  profile: null,
  showAccountProgressBar: false,
  showBilling: false,
  showNotifications: true,
  userCards: [],
  withLogo: false,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const mapStateToProps = (state, props) => {
  const summaryState = selectSummaries(state);
  return ({
    activeOrg: selectActiveOrg(state),
    activeUserCard: selectActiveUserCard(state),
    hasOrgAccess: getHasOrgAccess(state),
    location: props.location,
    match: props.match,
    newCount: summaryState.newCount,
    pendingCounts: selectPendingCounts(state),
    profile: selectProfile(state),
    userCards: selectUserCards(state),
  });
};

const MobileNavBarConnected = connect(mapStateToProps, mapDispatchToProps)(MobileNavBar);

export default withRouter(MobileNavBarConnected);
