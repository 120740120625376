import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProfileSettingsTabs from 'accounts/assets/js/components/ProfileSettingsTabs.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import { fetchProfileDS } from 'accounts/assets/js/data-services/profiles';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import ProfileSettingsTab from 'accounts/assets/js/components/ProfileSettingsTab.jsx';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { profileCreateExperienceUrl } from 'accounts/urls';
import { BS_STYLE } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { settingsPaymentMethodCreateSelectUrl } from 'settings/urls';

class ProfileSettingsView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return Promise.all([
      dispatch(fetchProfileDS({ url, params, authedAxios, componentName })),
    ]);
  }

  static GetComponentName(tab) {
    return `ProfileSettingsView-${tab}`;
  }

  render() {
    const { activeOrg, history, match, profile, viewerProfile } = this.props;
    const { tab } = match.params;

    let ctaButton = null;
    switch (tab) {
      case PROFILE_SETTINGS_TABS.BASIC_INFORMATION:
        ctaButton = (
          <TDButton
            floatRight
            label="View public profile"
            onClick={() => history.push(viewerProfile?.url)}
            variant={BS_STYLE.PRIMARY}
          />
        );
        break;
      case PROFILE_SETTINGS_TABS.EXPERIENCE:
        ctaButton = (
          <TDButton
            floatRight
            label="Add your experience"
            onClick={() => history.push(profileCreateExperienceUrl(activeOrg.alias))}
            variant={BS_STYLE.PRIMARY}
          />
        );
        break;
      case SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS:
        ctaButton = (
          <TDButton
            floatRight
            label="New payment method"
            onClick={() => history.push(settingsPaymentMethodCreateSelectUrl(activeOrg.alias))}
            variant={BS_STYLE.PRIMARY}
          />
        );
        break;
      default:
    }

    const breadcrumbs = [
      {
        title: 'Profile',
        url: null,
      },
    ];
    const fromGetStarted = history?.location?.state?.fromGetStarted;

    return (
      <React.Fragment>
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaComponent={!fromGetStarted && ctaButton}
        >
          { !fromGetStarted && (
            <ProfileSettingsTabs
              orgAlias={activeOrg.alias}
              tab={tab}
            />
          )}
        </ContentHeader>
        <div className="page page--settings">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={ProfileSettingsView}
              storeKey={ProfileSettingsView.GetComponentName(tab)}
              skeletonComponent={SettingsPageSkeleton}
            >
              <ProfileSettingsTab
                history={history}
                tab={tab}
                profile={profile}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfileSettingsView.propTypes = {
  activeOrg: orgSpec.isRequired,
  history: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  profile: PropTypes.object.isRequired,
  viewerProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const { tab } = props.match.params;
  const viewState = getViewState(state, ProfileSettingsView.GetComponentName(tab));
  const viewerProfile = selectProfile(state);
  return {
    activeOrg: selectActiveOrg(state),
    history: props.history,
    profile: viewState.item,
    viewerProfile,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ConnectedProfileSettingsView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileSettingsView);

export default withRouter(ConnectedProfileSettingsView);
