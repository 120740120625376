import React from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty } from 'lodash';
import { CURRENCY_SYMBOL } from 'td-finance-ts';

import { BS_SIZE } from 'core/assets/js/constants';
import { INVOICE_TYPE } from 'finance/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import WorksheetTypeIndicator from 'finance/assets/js/components/WorksheetTypeIndicator.jsx';
import InvoiceItemsModalTable from 'finance/assets/js/components/invoicing/InvoiceItemsModalTable.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';

class ContractorChargesTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalData: null,
    };
    this.openInvoiceItemsModal = this.openInvoiceItemsModal.bind(this);
  }

  openInvoiceItemsModal(rowData) {
    const { modalState: { open } } = this.props;
    this.setState({
      modalData: rowData,
    }, () => open());
  }

  render() {
    const {
      orgAlias,
      invoice,
      invoice: { invoiceItemGroups },
      modalState,
      withSubtotal,
    } = this.props;

    if (!invoice
      || !invoice.amounts
      || !invoice.invoiceItemGroups
      || isEmpty(invoice.invoiceItemGroups)
    ) {
      return null;
    }

    const {
      currency,
    } = invoice.amounts;
    const currencySymbol = CURRENCY_SYMBOL[currency];

    const isOutbound = invoice.type === INVOICE_TYPE.OUTBOUND;
    const hasFees = isOutbound;
    let tableColumns = [
      {
        key: 'provider',
        label: 'Provider',
      },
      {
        columnClassName: 'text-right',
        dataFormat: (_, row) => (
          <NumberTpl
            prefix={currencySymbol}
            value={row.amounts.amount}
          />
        ),
        key: 'amount',
        label: 'Amount',
        width: '165px',
      },
    ];

    const excludedColumns = !hasFees ? ['fee'] : [];
    tableColumns = filter(tableColumns, col => !excludedColumns.includes(col.key));

    const rows = invoiceItemGroups;

    /**
     * For Backwards compatibility, show type column only if at least one item includes
     * this information.
     */
    // Check if any of the items has the serviceOrderType property.
    const hasTypeInfo = rows.every((i => i.serviceOrderType));
    if (hasTypeInfo) {
      tableColumns.unshift({ key: 'type',
        defaultClassName: 'text-center',
        label: 'Type',
        width: '60px',
        dataFormat: (cell, row) => {
          return (
            <WorksheetTypeIndicator type={row.serviceOrderType} />
          );
        },
      });
    }

    const { modalData } = this.state;
    return (
      <React.Fragment>
        {!isEmpty(modalData) && orgAlias && (
          <ModalSimple
            open={modalState.isOpen}
            heading={modalData.description}
            bsSize={BS_SIZE.LARGE}
            size={BS_SIZE.LARGE}
            body={(
              <InvoiceItemsModalTable
                invoiceItems={invoice.invoiceItems}
                ids={modalData.children}
                orgAlias={orgAlias}
                emptyText="No Invoice Items found"
                hasFees={hasFees}
                {...modalData}
              />
            )}
            onClose={modalState.close}
          />
        )}
        <Table
          containerClass="invoice-table"
          cols={tableColumns}
          items={rows}
          onRowClick={this.openInvoiceItemsModal}
        />

        {withSubtotal && (
          <div className="finance-report__summary">
            <div>
              <label>Subtotal</label>
              <NumberTpl
                prefix={CURRENCY_SYMBOL[invoice.amounts.currency]}
                value={invoice.amounts.amount}
                decimals={2}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

ContractorChargesTable.propTypes = {
  invoice: PropTypes.object.isRequired,
  orgAlias: PropTypes.string,
  withSubtotal: PropTypes.bool,
  modalState: modalStateSpec.isRequired,
};


ContractorChargesTable.defaultProps = {
  orgAlias: null,
  withSubtotal: false,
};

export default withStateModal(ContractorChargesTable);
