import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { updateCustomFieldTemplateDS } from 'interviews/assets/js/data-services/interviews';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TemplateBuilderForm from 'settings/assets/js/components/TemplateBuilderForm.jsx';
import { orgTemplatesManageUrl } from 'settings/urls';
import { getEntityTypeByUrlParam } from 'interviews/assets/js/lib/utils';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { customFieldsUpdateTemplateApiUrl } from 'interviews/urls';


export const COMPONENT_NAME = 'TaskTemplateEditView';

class CustomFieldsTemplateEditView extends React.Component {
  static FetchData({ dispatch, params, authedAxios, componentName }) {
    const { orgAlias, templateId, entityType } = params;
    return dispatch(
      fetchViewDS({
        authedAxios,
        componentName,
        url: customFieldsUpdateTemplateApiUrl({ orgAlias, templateId, entityType }),
      }),
    );
  }

  static GetComponentName() {
    return COMPONENT_NAME;
  }

  constructor(props) {
    super(props);

    this.onsubmit = this.onsubmit.bind(this);
  }

  onsubmit(values) {
    const { match, dispatch, history } = this.props;
    const { orgAlias, templateId, entityType } = match.params;
    return new Promise((resolve, reject) => {
      dispatch(updateCustomFieldTemplateDS({
        orgAlias,
        values,
        entityType,
        templateId,
      }))
        .then(() => {
          history.push(orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.TEMPLATES));
          toastr.success('Well Done!', 'Template updated successfully.');
        })
        .catch((error) => {
          toastr.error('Oops!', 'We couldn’t update your Template');
          if (error.response.status === 400) {
            resolve(error.response.data);
          }

          reject(error);
        });
    });
  }

  render() {
    const { match, item } = this.props;
    const { orgAlias, entityType } = match.params;
    const orgManageInvitationTemplatesUrl = orgTemplatesManageUrl(orgAlias);
    const breadcrumbs = [
      {
        title: 'Templates',
        url: orgManageInvitationTemplatesUrl,
      },
      {
        title: 'Custom fields',
        url: orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.TEMPLATES),
      },
      {
        title: (
          <span>Edit template</span>
        ),
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--invitation-template-create">
          <div className="container-fluid">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={SettingsPageSkeleton}
            >
              <TemplateBuilderForm
                templateType={getEntityTypeByUrlParam(entityType)}
                initialValues={{ ...item }}
                onSubmit={this.onsubmit}
                template={item}
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
CustomFieldsTemplateEditView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});
const mapStateToProps = state => ({
  item: getViewState(state, COMPONENT_NAME).item,
});
const CustomFieldsTemplateEditViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFieldsTemplateEditView);

export default withRouter(CustomFieldsTemplateEditViewConnected);
