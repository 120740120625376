import { pick } from 'lodash';
import qs from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useLocation } from 'react-router-dom';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import InterviewListSkeleton from 'core/assets/js/components/Skeleton/InterviewListSkeleton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDCopyToClipboard from 'core/assets/js/components/TDCopyToClipboard.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { fetchListDS, listReplaceItemAC } from 'core/assets/js/ducks/list';
import {
  modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload,
} from 'core/assets/js/ducks/modalLauncher';
import DuplicateInterviewModal, { DUPLICATE_INTERVIEW_MODAL } from 'interviews/assets/js/components/DuplicateInterviewModal.jsx';
import InterviewCardItem from 'interviews/assets/js/components/InterviewCardItem.jsx';
import RemoveInterviewModal from 'interviews/assets/js/components/RemoveInterviewModal.jsx';
import { STATUS } from 'invitations/assets/js/constants';
import {
  generatePublicInvite, revokePublicInvite,
} from 'interviews/assets/js/data-services/interviews';
import {
  interviewEditUrl,
  interviewPreviewUrl,
  interviewListApiUrl,
  interviewInviteesUrl,
} from 'interviews/urls';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { PERMISSIONS } from 'roles/assets/js/constants';
import { postOrganizationDS } from 'settings/assets/js/data-services/organizations';

const DELETE_MODAL_ID = 'delete-interview';
const MANAGE_MODAL_ID = 'manage-interview-url';

const OnboardingFormsTab = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const activeOrg = useSelector(selectActiveOrg);
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const duplicatedInterviewModalPayload = useSelector(
    state => getModalPayload(state, DUPLICATE_INTERVIEW_MODAL),
  );
  const deleteModalPayload = useSelector(state => getModalPayload(state, DELETE_MODAL_ID));
  const isManageUrlModalOpen = useSelector(state => getIsModalOpen(state, MANAGE_MODAL_ID));
  const manageUrlModalPayload = useSelector(state => getModalPayload(state, MANAGE_MODAL_ID));

  const componentName = OnboardingFormsTab.GetComponentName();

  const listUrl = interviewListApiUrl(activeOrg.alias);
  const { hasLoaded, isLoading, items: interviews, pagination } = fetchDataHook({
    componentName, duck: 'list', url: listUrl,
  });

  const onSettingsChanged = changes => (
    dispatch(postOrganizationDS(activeOrg.alias, changes, componentName))
  );

  const reFetchInterviews = () => {
    dispatch(fetchListDS({ componentName, url: `${listUrl}?${qs.stringify(pagination)}` }));
  };

  const closeModal = () => dispatch(modalCloseAC());

  const managePublicInvite = async (interviewId, { generate = false } = {}) => {
    try {
      const response = await (generate ? generatePublicInvite : revokePublicInvite)({
        interviewId, orgAlias: activeOrg.alias,
      });
      if (!response || !response.data || !response.data.interview) {
        toastr.error(
          'Oh Snap!', `Something went wrong ${generate ? 'generat' : 'revok'}ing the URL`,
        );
        return;
      }
      dispatch(listReplaceItemAC(response.data.interview, componentName));
      toastr.success(`Url has been successfully ${generate ? 'generated' : 'revoked'}`);
      closeModal();
    } catch (err) {
      toastr.error('Oh Snap!', err.response.data._error);
    }
  };

  const managedInterview = manageUrlModalPayload
    ? interviews.find(i => i.id === manageUrlModalPayload.interviewId)
    : null;

  return (
    <>
      <h2 className="mt-0">Onboarding forms</h2>
      <p>
        When inviting your temporary workforce to use TalentDesk, you may require them to provide
        some custom information during their onboarding process. With Onboarding forms, you can
        create custom forms that can be added to the invitations that you send out. Once the form
        has been completed by the worker their responses can be reviewed and approved before access
        is granted.
      </p>
      <SearchFinalForm
        className="px-0"
        initialValues={pick(qs.parse(location.search), 'kw')}
        searchContainerClass="col-12 col-md-auto mt-3 mt-md-0 px-0 position-relative'"
        searchSpec={{ searchTerm: { placeholder: 'Search forms' } }}
      />
      {(isLoading || !hasLoaded) && <InterviewListSkeleton />}
      {!isLoading && hasLoaded && interviews.map(interview => (
        <InterviewCardItem
          key={interview.id}
          {...interview}
          editUrl={interviewEditUrl(activeOrg.alias, interview.id)}
          inviteesUrl={interviewInviteesUrl(activeOrg.alias, interview.id, STATUS.PENDING)}
          onDelete={() => {
            dispatch(modalOpenAC(
              DELETE_MODAL_ID, pick(interview, 'id', 'name', 'pendingInvitationCount'),
            ));
          }}
          onDuplicate={() => {
            dispatch(modalOpenAC(DUPLICATE_INTERVIEW_MODAL, { id: interview.id }));
          }}
          onManageUrl={() => {
            dispatch(modalOpenAC(MANAGE_MODAL_ID, { interviewId: interview.id }));
          }}
          url={interviewPreviewUrl(activeOrg.alias, interview.id)}
        />
      ))}
      <TDPagination {...pagination} />
      {hasPermission(PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS) && (
        <>
          <h2 className="mt-5">Onboarding settings</h2>
          <div className="rounded shadow-sm p-4 bg-white">
            <TDToggleWithConfirmationModal
              containerClassName="pb-3 px-3"
              disableHeader="Allow managers to manage onboarding forms is activated"
              DisableModalBody={() => (
                <>
                  <p>
                    Deactivating this setting means that managers will not be able to create
                    and edit user onboarding forms. They will not be able to add, remove or
                    update questions and fields in any of the onboarding forms.
                  </p>
                  <p className="mb-4">
                    Are you sure you want to deactivate &quot;Allow managers to manage
                    onboarding forms&quot;?
                  </p>
                </>
              )}
              enableHeader="Allow managers to manage onboarding forms is deactivated"
              EnableModalBody={() => (
                <>
                  <p>
                    Activating this setting means that managers will be able to create and
                    edit user onboarding forms. They will be able to add, remove or update
                    questions and fields in any of the onboarding forms.
                  </p>
                  <p className="mb-4">
                    Are you sure you want to activate &quot;Allow managers to manage
                    onboarding forms&quot;?
                  </p>
                </>
              )}
              label="Allow managers to manage onboarding forms"
              modalId="managers-can-manage-onboarding-forms"
              onToggled={selected => onSettingsChanged({
                managers_can_manage_onboarding_forms: selected,
              })}
              selected={!!activeOrg.managers_can_manage_onboarding_forms}
              sublabel={(
                <p>
                  By activating this option managers of your organization will be able to
                  create and edit onboarding forms
                </p>
              )}
            />
            {interviews.length > 0 && (
              <TDToggleWithConfirmationModal
                containerClassName="pb-3 px-3"
                enableHeader="Make onboarding forms mandatory in this organization"
                EnableModalBody={() => (
                  <>
                    <p>
                      By activating this setting, onboarding forms will be mandatory when
                      inviting contractors to join this organization.
                    </p>
                    <p className="mb-4">Are you sure you want to activate this setting?</p>
                  </>
                )}
                disableHeader="Make onboarding forms optional in this organization"
                DisableModalBody={() => (
                  <>
                    <p>
                      By deactivating this setting, onboarding forms will not be mandatory
                      when inviting contractors to join this organization.
                    </p>
                    <p className="mb-4">
                      Are you sure you want to deactivate this setting?
                    </p>
                  </>
                )}
                label="Mandatory onboarding forms"
                modalId="mandatory-onboarding-forms"
                onToggled={selected => onSettingsChanged({
                  onboarding_forms_are_mandatory: selected,
                })}
                selected={activeOrg.onboarding_forms_are_mandatory}
                sublabel={(
                  `By activating this option, contractors will have to fill in an onboarding
                  form before joining the organization`
                )}
              />
            )}
            <TDToggleWithConfirmationModal
              containerClassName="pb-3 px-3"
              enableHeader="User onboarding automatic approval is deactivated"
              EnableModalBody={() => (
                <>
                  <p>
                    Speed up the user onboarding process by allowing a hiring manager
                    to instruct the platform to automatically approve an onboarding
                    form upon its submission. This is particularly useful when a
                    hiring manager onboards on the platform a user they have worked
                    with in the past.
                  </p>
                  <p className="mb-4">
                    Are you sure you want to activate
                    the &#39;Automatic user approval&#39; setting?
                  </p>
                </>
              )}
              disableHeader="User onboarding automatic approval is activated"
              DisableModalBody={() => (
                <>
                  <p>
                    The automatic user approval during user onboarding allows the hiring
                    managers to speed up the onboarding process of selected users. This
                    is particularly useful when a hiring manager onboards on the platform
                    a user they have worked with in the past. Deactivating this setting
                    means that the user will have to submit their onboarding form and wait
                    for the hiring manager or the organisation owner to review it before
                    they get access to your organisation.
                  </p>
                  <p className="mb-4">
                    Are you sure you want to deactivate
                    the &#39;Automatic user approval&#39; setting?
                  </p>
                </>
              )}
              label="Automatic user approval"
              modalId="automatic-user-approval"
              onToggled={selected => onSettingsChanged({
                should_high_manager_manage_instant_approval: selected,
              })}
              selected={activeOrg.should_high_manager_manage_instant_approval}
              sublabel={(
                `Speed up the user onboarding process by allowing a hiring manager to
                instruct the platform to automatically approve an onboarding form
                upon its submission`
              )}
            />
            <div className="d-flex flex-column px-3">
              <p>
                Which additional onboarding steps do you want your providers to complete?
              </p>
              {['Skill', 'Language'].map((type, index) => {
                const typeLC = type.toLowerCase();
                const columnName = `onboarding_${typeLC}s_required`;
                const enabled = activeOrg[columnName];
                return (
                  <div
                    className={`form-group d-flex flex-row mb-${index === 0 ? 3 : 0}`}
                    key={type}
                  >
                    <input
                      checked={enabled}
                      className="mr-4"
                      onChange={() => {
                        // Do in a setTimeout, so API request doesn't block the checkbox changing
                        // in the UI
                        setTimeout(() => onSettingsChanged({ [columnName]: !enabled }), 1);
                      }}
                      type="checkbox"
                    />
                    <div>
                      <TDLabel
                        className="mb-0"
                        label={`${type}s`}
                        name={columnName}
                        sublabel={`Providers must add at least one ${typeLC}`}
                        subLabelClassName="mb-0"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {duplicatedInterviewModalPayload && (
        <DuplicateInterviewModal onDuplicating={reFetchInterviews} />
      )}

      {deleteModalPayload && (
        <RemoveInterviewModal onDelete={reFetchInterviews} orgAlias={activeOrg.alias} />
      )}

      {managedInterview && (
        <ModalSimple
          footer={(
            <div className="text-right">
              {!managedInterview.publicUrl && (
                <TDButton
                  data-testid="interview-list-generate-link-button"
                  label="Generate invite link"
                  onClick={() =>  managePublicInvite(managedInterview.id, { generate: true })}
                  variant={BS_STYLE.PRIMARY}
                />
              )}
              {managedInterview.publicUrl && (
                <TDButton
                  btnIcon={ICON.CROSS}
                  label="Revoke"
                  onClick={() =>  managePublicInvite(managedInterview.id, { revoke: true })}
                  variant={BS_STYLE.DANGER}
                />
              )}
            </div>
          )}
          heading="Invite Link"
          onClose={closeModal}
          open={isManageUrlModalOpen}
        >
          <p>
            Share the invite link to allow candidates to apply directly to your
            organization. The candidate will be prompted to submit the Onboarding Form
            of your choice, so you can approve or reject them, or request amends.
          </p>
          <p>
            You can deactivate the link at any point by clicking on Revoke.
          </p>
          {managedInterview.publicUrl && (
            <TDCopyToClipboard className="mb-4 mt-5" text={managedInterview.publicUrl} />
          )}
        </ModalSimple>
      )}
    </>
  );
};

OnboardingFormsTab.GetComponentName = () => 'OnboardingFormsTab';

export default OnboardingFormsTab;
