import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { get, pick, set } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { selectSetupSteps } from 'accounts/assets/js/ducks/account';
import { orgGetStartedUrl } from 'accounts/urls';
import { calculateAccountCompletePercentage } from 'accounts/assets/js/lib/helpers';
import { BS_STYLE, USER_CARD_STATUS } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { settingsBankAccountOrgSelectUrl, settingsPaymentsSubPageUrl } from 'settings/urls';
import { bankAccountSpec, twCurrencyReqsSpec } from 'settings/assets/js/lib/objectSpecs';
import {
  createBankAccountDS,
  updateBankAccountDS,
  validateBankAccountCountryCurrencyDS,
} from 'settings/assets/js/data-services/settings';
import {
  BANK_ACCOUNT_TYPE, PAYMENT_METHODS, PAYMENT_METHOD_DETAILS,
  TW_BANK_ACCOUNT_STEP,
} from 'settings/assets/js/constants';
import { getViewState } from 'core/assets/js/ducks/view';
import { selectActiveOrg, selectUserCards, selectOrgCurrencies } from 'organizations/assets/js/reducers/organizations';
import TransferwiseValidator from 'services/assets/js/lib/TransferwiseValidator';
import ErrorSerializer from 'core/assets/js/lib/ErrorSerializer';
import { resolveCardTokenForPayload } from 'settings/assets/js/lib/utils';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import TWWizardBankDetails from 'settings/assets/js/components/tw-form-components/TWWizardBankDetails.jsx';
import TWWizardStartStep from 'settings/assets/js/components/tw-form-components/TWWizardStartStep.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';

const REQUIREMENTS_STORE_KEY = 'trwCurrencyReqs';

class TWBankAccountForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.isFirstStepIncomplete = this.isFirstStepIncomplete.bind(this);
    this.handleFirstStepSubmit = this.handleFirstStepSubmit.bind(this);
    this.state = {
      showErrorSubmissionOption: false,
      bankAccountFormInformation: {},
    };
  }

  async handleFirstStepSubmit(formValues) {
    const { dispatch } = this.props;
    const { currency, address: { country } } = formValues;

    toastr.info(
      'Validating',
      'We are validating your selection. This may take a few seconds.',
    );

    try {
      await validateBankAccountCountryCurrencyDS({
        values: { currency, countryCode: country },
        dispatch,
      });

      toastr.removeByType('info');
    } catch (err) {
      toastr.removeByType('info');
      const res = { [FORM_ERROR]: err?.errors?._error };
      Object.entries(err?.errors ?? {}).forEach(([key, value]) => {
        set(res, key, value);
      });
      return res;
    }
    return null;
  }

  isFirstStepIncomplete(formValues) {
    const { alias, bank_name: bankName, currency, address } = formValues;
    const allRequiredFieldsCompleted = !!(alias && bankName && currency && address?.country);
    return !allRequiredFieldsCompleted;
  }

  async submit(values) {
    const {
      activeOrg,
      bankAccount,
      dispatch,
      history,
      requirements,
      steps,
      userCards,
    } = this.props;
    const hasBankAccount = !!get(bankAccount, 'id');
    const hasActiveUserCards = !!(userCards && userCards.find(
      uc => uc.status === USER_CARD_STATUS.APPROVED,
    ));
    const paymentsUrl = settingsPaymentsSubPageUrl(activeOrg.alias);
    const accountCompletePercentage = calculateAccountCompletePercentage(steps, activeOrg.alias);

    toastr.info(
      'Validating',
      'We are validating your bank details with our payment provider. This may take a few seconds.',
      {
        timeOut: 0,
      },
    );

    // Based on the new designs applied on bank accounts, we use a combination
    // of dyanmic Wise values and custom bank account definitions to create the second
    // step dropdown - Account type (type field).
    //
    // Case: user has filled/saved information on one account type and tries saving another one
    // The form has set as 'transferwise_type_selected', the bank account recipient saved value.
    // The form type reflects that active dropdown value.
    // While saving we need to replace 'type_selected' with 'type' so that we can validate
    // the correct form information.
    // eslint-disable-next-line no-param-reassign
    values.transferwise_type_selected = values.type;

    let recipient;
    try {
      recipient = await TransferwiseValidator.validateRecipient({
        alias: values.alias,
        type: values.transferwise_type_selected,
        bankName: values.bank_name,
        accountHolderName: values.accountHolderName,
        currency: values.currency.toUpperCase(),
        details: values,
      }, Object.values(requirements), {
        validateAlias: true, validateBankName: true,
      });
    } catch (error) {
      toastr.removeByType('info');

      const serializedError = ErrorSerializer.serialize(error);
      return serializedError;
    }

    let toPost;
    try {
      const hasRequirements = !!requirements[values.transferwise_type_selected];
      const twType = requirements[values.transferwise_type_selected]?.bankAccountType
        // in case we have override the bank account type using no Wise validation
        || bankAccount?.bank_account_type
        // if there are requirements but no explicit bankAccountType, fall back to Wise
        || BANK_ACCOUNT_TYPE.TRANSFERWISE;
      // if there are no requirements fall back to existing account type
      const bankAccountType = hasRequirements ? twType : bankAccount?.bank_account_type;
      toPost = await resolveCardTokenForPayload({
        id: bankAccount.id,
        paymentMethodType: PAYMENT_METHODS.BANK_TRANSFER,
        bank_account_type: bankAccountType,
        beneficiary: values.accountHolderName,
        ...pick(values, ['alias', 'bank_name', 'currency', 'custom_reference', 'transferwise_type_selected']),
        details: recipient.details,
      });
    } catch (error) {
      toastr.removeByType('info');

      const serializedError = ErrorSerializer.serialize(error);
      return { cardNumber: serializedError._error };
    }

    try {
      let selectionUrl;
      if (hasBankAccount) {
        await updateBankAccountDS(toPost, dispatch);
        selectionUrl = settingsBankAccountOrgSelectUrl(
          activeOrg.alias, PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.BANK_TRANSFER].alias,
          bankAccount.id,
        );
      } else {
        const newBankAccount = await createBankAccountDS(activeOrg.alias, toPost, dispatch);
        selectionUrl = settingsBankAccountOrgSelectUrl(
          activeOrg.alias, PAYMENT_METHOD_DETAILS[PAYMENT_METHODS.BANK_TRANSFER].alias,
          newBankAccount.id,
        );
      }

      if (accountCompletePercentage < 100) {
        history.push({
          pathname: orgGetStartedUrl(activeOrg.alias),
          state: { moveToNextStep: true },
        });
        return null;
      }

      history.push(hasActiveUserCards ? selectionUrl : paymentsUrl);

      toastr.removeByType('info');
    } catch (err) {
      toastr.removeByType('info');

      const res = { [FORM_ERROR]: err?.errors?._error };
      if (/50?/.test(err?.response?.status)) {
        this.setState({
          showErrorSubmissionOption: true,
          bankAccountFormInformation: values,
        });
        return null;
      }
      Object.entries(err.errors).forEach(([key, value]) => {
        set(res, key, value);
      });
      return res;
    }
    return null;
  }

  render() {
    const {
      bankAccount, dispatch, orgCurrencies, history, fromGetStarted,
      match: { params: { orgAlias } },
    } = this.props;
    const { showErrorSubmissionOption, bankAccountFormInformation } = this.state;
    let initialValues = {
      legalType: 'PRIVATE',
    };
    const hasBankAccount = !!get(bankAccount, 'id');
    if (hasBankAccount) {
      const { beneficiary, recipient: { details }, alias } = bankAccount;
      initialValues = {
        ...initialValues,
        accountHolderName: beneficiary,
        alias,
        type: bankAccount?.recipient.type,
        currency: bankAccount.currency,
        bank_name: bankAccount.bank_name,
        custom_reference: bankAccount.custom_reference,
        transferwise_type_selected: bankAccount.transferwise_type_selected,
        ...details,
      };
    }
    const WIZARD_STEPS = [
      {
        title: 'Payment information',
        component: TWWizardStartStep,
        step: TW_BANK_ACCOUNT_STEP.START,
        getSubmissionStatus: this.isFirstStepIncomplete,
        onStepSubmission: this.handleFirstStepSubmit,
      },
      { title: 'Bank details',
        component: TWWizardBankDetails,
        step: TW_BANK_ACCOUNT_STEP.BANK_DETAILS,
      },
    ];
    return (
      <div>
        <Wizard
          alwaysShowBackCta
          submitBtnTitle={fromGetStarted ? 'Save & continue' : 'Save'}
          submittingBtnTitle="Validating payment details..."
          onSubmit={values => this.submit(values)}
          initialValues={initialValues}
          onCancel={() => history.push(settingsPaymentsSubPageUrl(orgAlias))}
        >
          {WIZARD_STEPS.map(({
            title, validate, component: Component, step, onStepSubmission,
            getSubmissionStatus,
          }) => (
            <Wizard.Page
              dispatch={dispatch}
              // We need to pass the storeKey to the following child TDApis
              // we can't pass it as storeKey because we may mess with passThrough props
              // of TDApi and its HOCs
              descriptor={REQUIREMENTS_STORE_KEY}
              showErrorSubmissionOption={showErrorSubmissionOption}
              bankAccountFormInformation={bankAccountFormInformation}
              bankAccount={bankAccount}
              orgCurrencies={orgCurrencies}
              component={Component}
              key={step}
              validate={validate}
              title={title}
              nextBtnSubmittingTitle="Validating..."
              getSubmissionStatus={getSubmissionStatus}
              onStepSubmission={onStepSubmission}
              contentPrefix={step === TW_BANK_ACCOUNT_STEP.BANK_DETAILS ? (
                <TDSystemMessage
                  type={BS_STYLE.WARNING}
                  title="Warning!"
                  className="mb-4 mx-3"
                >
                  <>
                    Please ensure that your Bank Account Name matches that which is on your
                    Bank Account and your TalentDesk Profile.
                    You must not use another person’s Bank Account.
                    Discrepancies may result in payment delays.
                  </>
                </TDSystemMessage>
              ) : null}
            />
          ))}
        </Wizard>
      </div>
    );
  }
}

TWBankAccountForm.propTypes = {
  activeOrg: orgSpec.isRequired,
  bankAccount: bankAccountSpec,
  dispatch: PropTypes.func.isRequired,
  fromGetStarted: PropTypes.bool,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  orgCurrencies: PropTypes.array.isRequired,
  requirements: twCurrencyReqsSpec,
  steps: PropTypes.object.isRequired,
  userCards: PropTypes.arrayOf(userCardSpec).isRequired,
};

TWBankAccountForm.defaultProps = {
  bankAccount: {},
  fromGetStarted: false,
  requirements: null,
};

const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, REQUIREMENTS_STORE_KEY);
  return {
    activeOrg: selectActiveOrg(state),
    userCards: selectUserCards(state),
    orgCurrencies: selectOrgCurrencies(state),
    requirements: get(viewState, 'item.requirements') || get(props, 'bankAccount.requirements'),
    steps: selectSetupSteps(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TWBankAccountFormConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(TWBankAccountForm);

export default withRouter(TWBankAccountFormConnected);
