import { FORM_ERROR } from 'final-form';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import InterviewForm from 'interviews/assets/js/components/InterviewForm.jsx';
import { postInterviewDS } from 'interviews/assets/js/data-services/interviews';
import { parseInterviewBody } from 'interviews/assets/js/lib/utils';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import { orgTemplatesManageUrl } from 'settings/urls';

const InterviewCreateView = ({
  history, location: { search }, match: { params: { orgAlias } },
}) => {
  const dispatch = useDispatch();

  const {
    managers_can_manage_onboarding_forms: managersCanManageOnboardingForms,
  } = useSelector(selectActiveOrg);
  const hasOrgAccess = useSelector(getHasOrgAccess);
  const isAnyManager = hasOrgAccess({ requireManager: true });
  const isHigherManager = hasOrgAccess({ requireFinController: true });
  const canCreate = isHigherManager || (isAnyManager && managersCanManageOnboardingForms);

  const settingsPageUrl = orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.ONBOARDING);

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Onboarding forms', url: settingsPageUrl },
          { title: 'Create', url: null },
        ]}
      />
      <div className="page page--interview-create">
        <div className="container">
          {canCreate && (
            <InterviewForm
              onSubmit={async values => {
                try {
                  await dispatch(postInterviewDS(
                    { orgAlias },
                    parseInterviewBody(values),
                    InterviewCreateView.GetComponentName()),
                  );
                  const queries = queryString.parse(search);
                  history.push(queries.backUrl || settingsPageUrl);
                  toastr.success(
                    'Onboarding form saved!',
                    'Your Onboarding form has been saved. You can revisit this page and extend it',
                  );
                  return null;
                } catch (err) {
                  const errors = omit(err.response?.data || {}, '_error', '_meta');
                  if (Object.keys(errors).length > 0) {
                    return errors;
                  }
                  return { [FORM_ERROR]: err.response?.data._error || err.message };
                }
              }}
            />
          )}
          {!canCreate && 'You cannot create onboarding forms'}
        </div>
      </div>
    </>
  );
};

InterviewCreateView.GetComponentName = () => 'InterviewCreateView';

InterviewCreateView.propTypes = {
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(InterviewCreateView);
