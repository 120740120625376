import React from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import { isEmpty } from 'lodash';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import CustomFieldsUpdater from 'interviews/assets/js/components/CustomFieldsUpdater.jsx';
import { BS_STYLE, USER_TYPE } from 'core/assets/js/constants';
import {
  completeStepDS,
  fetchSetupStepsDS,
} from 'accounts/assets/js/ducks/account';
import { routerMatchContentsSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import {
  getCustomFieldTemplates,
  fetchCustomFieldTemplatesDS,
} from 'interviews/assets/js/ducks/customFields';
import { extractCustomFieldSetup } from 'interviews/assets/js/lib/utils';
import { postUserCustomFieldsDS, refreshAccountDS } from 'accounts/assets/js/data-services/account';
import { processAdditionalInformationValues } from 'people/assets/js/lib/utils';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { getViewState } from 'core/assets/js/ducks/view';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

export const COMPONENT_NAME = 'CustomFieldsTab';

const CustomFieldsTab = (props) => {
  const {
    activeUserCard: { user: { id: userId }, userRole },
    answers: storedAnswers,
    customFieldTemplateIds,
    customFieldTemplates,
    dispatch,
    history,
    match: {
      params: { orgAlias },
    },
    profile,
  } = props;
  const { ofType: userType } = userRole;
  const { initialValues, selectedCustomFields } = extractCustomFieldSetup({
    additionalSelectedTemplateIds: customFieldTemplateIds,
    answers: storedAnswers,
    templates: customFieldTemplates,
    useCurrentTemplates: true,
  });
  // Check if coming from get started page
  const fromGetStarted = !!history?.location?.state?.fromGetStarted;
  const handleProfileUpdate = async (values) => {
    try {
      const stepName = 'CustomFieldsFillStep';

      const finalValues = processAdditionalInformationValues(
        values,
        customFieldTemplates,
        userType,
      );

      if (
        values.custom_field_ids.length > 0
        && finalValues.custom_field_ids.length === 0
      ) {
        // The user has not selected any templates, with questions they can answer
        const error = 'You have not selected any templates with questions you can answer';
        toastr.error('Oops!', error);
        return { custom_field_templates: error };
      }

      await dispatch(
        postUserCustomFieldsDS(orgAlias, userId, finalValues, COMPONENT_NAME),
      );
      // This is required to update the 'Onboarding progress' profile section
      const profileIsForAProvider = userType === USER_TYPE.PROVIDER;
      const fetchUserDS = profileIsForAProvider
        ? fetchProviderDS
        : fetchManagerDS;
      await dispatch(
        fetchUserDS({
          componentName: COMPONENT_NAME,
          params: { orgAlias, userId },
        }),
      );

      await completeStepDS(dispatch, orgAlias, 'CustomFieldsFillStep', { history });
      const { steps: updatedSteps } = await dispatch(fetchSetupStepsDS());
      const updatedOrgSteps = updatedSteps.userCardSetupSteps[orgAlias] || [];
      const updatedOrgStep = updatedOrgSteps.find(
        step => step.name === stepName,
      );

      if (updatedOrgStep.is_complete) {
        await dispatch(refreshAccountDS()).then(({ auth }) => {
          history.push({
            pathname: auth.userProfile.homeUrl,
            state: { moveToNextStep: true },
          });
        });
      }

      return null;
    } catch (err) {
      const error = err._error || err.message;
      toastr.error('Oops!', error);
      return err.errors || { [FORM_ERROR]: error };
    }
  };

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <div className="page--settings__content clearfix">
        {!profile.is_complete && (
          <TDSystemMessage
            type={BS_STYLE.WARNING}
            className="mb-5"
            title="You're almost there!"
          >
            <p> Please fill in your profile </p>
          </TDSystemMessage>
        )}
        <div className="settings-row">
          <h3 className="h3 mb-5">Additional Information</h3>

          <Form
            name="profile-custom-fields-form"
            onSubmit={handleProfileUpdate}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            // important: mandatory templates will not work properly otherwise
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, form }) => (
              <form
                onSubmit={handleSubmit}
                className={fromGetStarted ? 'px-0' : ''}
              >
                <CustomFieldsUpdater
                  entityId={userId}
                  entityType={FIELD_ENTITY_TYPE.USER}
                  form={form}
                  fromGetStarted={fromGetStarted}
                  initialCustomFields={selectedCustomFields}
                  onTemplateAdded={(template) => {
                    if (!isEmpty(storedAnswers)) {
                      return;
                    }
                    // Update any fields, based on existing answers
                    const paths = template.questions.map(q => q.path);
                    storedAnswers.forEach((storedAnswer) => {
                      if (paths.includes(storedAnswer.path)) {
                        form.change(storedAnswer.path, storedAnswer.body);
                      }
                    });
                  }}
                  showTemplateNames
                  showTemplatesWithoutQuestionsTheUserCanAnswer={false}
                  templateFieldLabel="Additional information template"
                  templates={customFieldTemplates}
                  useCurrentTemplates
                />

                <div className="modal-footer mx-0 mt-5 border-top-0 mb-0 pb-0">
                  <TDButton
                    disabled={submitting}
                    type="submit"
                    variant={BS_STYLE.PRIMARY}
                    label="Save & continue"
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const CustomFieldsTabApiConnected = withTDApiConnected({
  fetchData: ({ dispatch, authedAxios, params, url, props }) => {
    const profileIsForAProvider = props?.activeUserCard?.userRole?.ofType === USER_TYPE.PROVIDER;

    const fetchUserDS = profileIsForAProvider
      ? fetchProviderDS
      : fetchManagerDS;

    return Promise.all([
      dispatch(
        fetchCustomFieldTemplatesDS({
          authedAxios,
          entityType: FIELD_ENTITY_TYPE.USER,
          fetchAll: true,
          orgAlias: params.orgAlias,
          url,
        }),
      ),
      dispatch(
        fetchUserDS({
          componentName: COMPONENT_NAME,
          params: {
            orgAlias: params.orgAlias,
            userId: props?.activeUserCard?.user?.id,
          },
        }),
      ),
    ]);
  },
  duck: 'list',
  storeKey: { COMPONENT_NAME },
  skeletonComponent: SettingsPageSkeleton,
})(CustomFieldsTab);

CustomFieldsTab.propTypes = {
  activeUserCard: userCardSpec.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  customFieldTemplateIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec).isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchContentsSpec.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  const userCard = getViewState(state, COMPONENT_NAME)?.item;
  return {
    activeUserCard: selectActiveUserCard(state),
    answers: userCard.user?.answers || [],
    customFieldTemplateIds: userCard?.invitation?.customFieldTemplateIds || [],
    customFieldTemplates: getCustomFieldTemplates(state),
    history: props.history,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const CustomFieldsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomFieldsTabApiConnected);

export default withRouter(CustomFieldsTabConnected);
