import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import CheckableList from 'core/assets/js/components/CheckableList.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { orgTemplatesManageUrl, customFieldTemplateCreateUrl } from 'settings/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { FIELD_ENTITY_TYPE, ENTITY_TYPE_LABEL, FIELD_ENTITY_TYPE_TO_URL_PARAM } from 'interviews/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';


const availableEntityTypes = Object.values(FIELD_ENTITY_TYPE)
  .filter(entityType => entityType !== FIELD_ENTITY_TYPE.ONBOARDING_FORM)
  .map(entityType => ({
    id: entityType,
    entityType,
  }))
  .reverse();

function CustomFieldsTemplateSelectTypeToCreateView({ match, history }) {
  const [selectedEntityType, setSelectedEntityType] = useState();
  const { orgAlias } = match.params;
  const orgManageInvitationTemplatesUrl = orgTemplatesManageUrl(orgAlias);
  const breadcrumbs = [
    {
      title: 'Templates',
      url: orgManageInvitationTemplatesUrl,
    },
    {
      title: 'Custom fields',
      url: orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.TEMPLATES),
    },
    {
      title: (
        <span className="discreet">
          Select template type...
        </span>
      ),
      url: null,
    },
  ];

  const getTypeIcon = entityType => {
    let templateIcon;

    switch (entityType) {
      case FIELD_ENTITY_TYPE.TASK:
        templateIcon = ICON.TASKS;
        break;
      case FIELD_ENTITY_TYPE.PROJECT:
        templateIcon = ICON.BROWSER;
        break;
      case FIELD_ENTITY_TYPE.USER:
      default:
        templateIcon = ICON.USER;
        break;
    }

    return templateIcon;
  };

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--invitation-template-create">
        <div className="container">
          <Card className="mb-4">
            <Card.Body>
              <CheckableList
                defaultValue={availableEntityTypes[0]}
                items={availableEntityTypes}
                itemComponent={({ item: { entityType } }) => {
                  return (
                    <React.Fragment>
                      <i className={`${getTypeIcon(entityType)} discreet mr-2 pr-1`} />
                      <span>
                        {`${ENTITY_TYPE_LABEL[entityType]} template`}
                      </span>
                    </React.Fragment>
                  );
                }}
                onItemChecked={item => setSelectedEntityType(item)}
              />
            </Card.Body>
          </Card>

          <div className="clearfix">
            <div className="float-right">
              <TDButton
                variant={BS_STYLE.PRIMARY}
                onClick={() => {
                  history.push(
                    customFieldTemplateCreateUrl(
                      orgAlias,
                      FIELD_ENTITY_TYPE_TO_URL_PARAM[selectedEntityType.entityType],
                    ),
                  );
                }}
                disabled={!selectedEntityType}
                label="Create template"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
CustomFieldsTemplateSelectTypeToCreateView.propTypes = {
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(CustomFieldsTemplateSelectTypeToCreateView);
