import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { orgTemplatesManageUrl } from 'settings/urls';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import TabBar from 'core/assets/js/components/TabBar.jsx';

/**
 * This is the tabs component we use on Org Settings -> Templates
 * @returns {*}
 */
const ManageTemplatesTabs = ({ tab, match: { params: { orgAlias } } }) => {
  const tabSpec = [
    {
      key: SETTINGS_TEMPLATE_TABS.ONBOARDING,
      label: 'Onboarding forms',
      href: orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.ONBOARDING),
    },
    {
      key: SETTINGS_TEMPLATE_TABS.ORG_INVITATIONS,
      label: 'Org invitations',
      href: orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.ORG_INVITATIONS),
    },
    {
      key: SETTINGS_TEMPLATE_TABS.TEMPLATES,
      label: 'Templates',
      href: orgTemplatesManageUrl(orgAlias, SETTINGS_TEMPLATE_TABS.TEMPLATES),
    },
  ];

  return (
    <div className="horizontal-scroll">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

ManageTemplatesTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(ManageTemplatesTabs);
