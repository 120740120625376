import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';

import HorizontalFreeScroll from 'core/assets/js/components/HorizontalFreeScroll.jsx';
import OnboardingProgressBar from 'core/assets/js/components/OnboardingProgressBar.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import {
  BS_STYLE, ICON, PROFILE_TABS, TABS_STYLING_VARIATION, USER_TYPE,
} from 'core/assets/js/constants';
import { getViewState } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { userSpec } from 'accounts/assets/js/lib/objectSpecs';
import { financeProFormaInvoiceCreateUrl } from 'finance/urls';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { profileSpec } from 'people/assets/js/lib/objectSpecs';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { orgPeopleProvidersUrl, orgPeopleManagersUrl, orgUserProfileUrl } from 'people/urls';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import PeopleProfileTabs from 'people/assets/js/components/PeopleProfileTabs.jsx';
import UserDetails from 'people/assets/js/components/UserDetails.jsx';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { PROFILE_SETTINGS_TABS } from 'accounts/assets/js/constants';
import { profileSettingsUrl } from 'accounts/urls';
import { getHasOrgAccess, selectProfile } from 'accounts/assets/js/reducers/auth';
import PeopleProfileTabsSkeleton from 'core/assets/js/components/Skeleton/PeopleProfileTabsSkeleton.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { PROFILE_VIEW_COMPONENT_NAME } from 'people/assets/js/constants';
import { PERMISSIONS } from 'roles/assets/js/constants';

const REVIEW_MODAL_ID = 'profile-reviews-modal';
const NOTE_MODAL_ID = 'profile-notes-modal';


class ProfileView extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { userType } = params;
    if (userType === 'providers') {
      return dispatch(fetchProviderDS({ url, params, authedAxios, componentName }));
    }

    return dispatch(fetchManagerDS({ url, params, authedAxios, componentName }));
  }

  static GetComponentName() {
    return PROFILE_VIEW_COMPONENT_NAME;
  }

  constructor(props) {
    super(props);
    this.handleOpenNoteModal = this.handleOpenNoteModal.bind(this);
    this.handleCloseNoteModal = this.handleCloseNoteModal.bind(this);
    this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this);
    this.handleOpenReviewModal = this.handleOpenReviewModal.bind(this);
  }

  handleOpenNoteModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(NOTE_MODAL_ID));
  }

  handleCloseNoteModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
    dispatch(reset('notes-add'));
  }

  handleCloseReviewModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleOpenReviewModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(REVIEW_MODAL_ID));
  }

  render() {
    const {
      activeOrg,
      hasOrgAccess,
      hasPermission,
      history,
      isNotesModalOpen,
      isReviewModalOpen,
      params,
      params: { userType },
      profile,
      projects,
      viewerProfile,
      user: { email: userEmail },
      userCard,
      viewerId,
    } = this.props;
    const tabSpec = [];
    const orgAlias = activeOrg.unique_alias;
    const userId = userCard && userCard.user && userCard.user.id;
    const projectOptions = projects.map(p => ({ value: p.id, text: p.title }));
    const isThemself = userCard.user && (viewerId === userId);
    const userTypeId = userType === 'providers'
      ? USER_TYPE.PROVIDER
      : USER_TYPE.MANAGER;
    const isProviderProfile = userTypeId !== USER_TYPE.MANAGER;
    const defaultTab = PROFILE_TABS.EXPERIENCE;
    const email = userCard.email || userEmail;
    const isOwnProfile = profile?.userId === viewerProfile?.userId;

    const breadcrumbs = [
      {
        title: upperFirst(userType),
        url: isProviderProfile ? orgPeopleProvidersUrl(orgAlias) : orgPeopleManagersUrl(orgAlias),
      },
      {
        title: profile ? `${profile.name}` : null,
        url: null,
      },
    ];

    const isAnyManager = hasOrgAccess({
      requireManager: true,
      requireFinController: true,
      requireOrgCreator: true,
    });

    const onboardingTabUrl = userId && orgUserProfileUrl(
      orgAlias, userTypeId, userId, PROFILE_TABS.ONBOARDING,
    );

    if (userId) {
      const canViewReviews = isProviderProfile && (
        isAnyManager || activeOrg.should_provider_view_other_providers_review
      );
      const showDocumentsTab = isProviderProfile && (isAnyManager || isThemself);

      // Show About tab for all users
      tabSpec.push({
        label: 'About',
        key: PROFILE_TABS.EXPERIENCE,
        href: orgUserProfileUrl(orgAlias, userTypeId, userId, PROFILE_TABS.EXPERIENCE),
      });

      if (userCard.onboardingSteps) {
        tabSpec.push({
          href: onboardingTabUrl, key: PROFILE_TABS.ONBOARDING, label: 'Onboarding',
        });
      }

      if (hasPermission(PERMISSIONS.CAN_VIEW_PROVIDER_TAX_INFORMATION)) {
        tabSpec.push({
          label: 'Tax',
          key: PROFILE_TABS.TAX_INFORMATION,
          href: orgUserProfileUrl(orgAlias, userTypeId, userId, PROFILE_TABS.TAX_INFORMATION),
        });
      }

      if (userCard.paymentMethod !== undefined) {
        tabSpec.push({
          label: 'Payments',
          key: PROFILE_TABS.PAYMENT_DETAILS,
          href: orgUserProfileUrl(orgAlias, userTypeId, userId, PROFILE_TABS.PAYMENT_DETAILS),
        });
      }

      // Show Reviews tab only for provider profiles and if it's manager
      // or it's themself profile or view review option is enabled
      if (canViewReviews) {
        tabSpec.push({
          label: 'Reviews',
          key: PROFILE_TABS.REVIEWS,
          href: orgUserProfileUrl(orgAlias, userTypeId, userId, PROFILE_TABS.REVIEWS),
        });
      }

      // Show rates only when viewer is a manager and not viewing his own profile
      if (isAnyManager && !isThemself) {
        tabSpec.push({
          label: 'Rates',
          key: PROFILE_TABS.RATES,
          href: orgUserProfileUrl(orgAlias, userTypeId, userId, PROFILE_TABS.RATES),
        });
      }

      // Show projects only when viewer is a manager or viewing his own profile
      if (isAnyManager || isThemself) {
        tabSpec.push({
          label: 'Work',
          key: PROFILE_TABS.PROJECTS,
          href: orgUserProfileUrl(orgAlias, userTypeId, userId, PROFILE_TABS.PROJECTS),
        });
      }

      // Show assigned documents for provider profiles if current user
      // is a manager or their profile
      if (showDocumentsTab) {
        tabSpec.push({
          label: 'Documents',
          key: PROFILE_TABS.DOCUMENTS,
          href: orgUserProfileUrl(orgAlias, userTypeId, userId, PROFILE_TABS.DOCUMENTS),
        });
      }

      // Show Notes tab only when viewer is a manager
      if (isAnyManager) {
        tabSpec.push({
          label: 'Notes',
          key: PROFILE_TABS.NOTES,
          href: orgUserProfileUrl(orgAlias, userTypeId, userId, PROFILE_TABS.NOTES),
        });
      }
    }

    const hasTabs = tabSpec.length > 0;

    const onboardingSubmission = userCard?.submissions?.[0];

    const tab = params.tab || defaultTab;
    const isOnboardingTab = tab === PROFILE_TABS.ONBOARDING;

    const ctaButtonItems = [];
    if (!isOnboardingTab && userId && onboardingSubmission?.allowedActions?.canReview) {
      ctaButtonItems.push({
        label: 'Review onboarding form',
        onClick: () => history.push(`${onboardingTabUrl}?reviewOnboardingForm=true`),
        variant: BS_STYLE.PRIMARY,
      });
    }

    if (isOwnProfile && viewerProfile?.url) {
      ctaButtonItems.push({
        label: 'Edit profile',
        onClick: () => history.push(profileSettingsUrl(
          orgAlias,
          PROFILE_SETTINGS_TABS.BASIC_INFORMATION),
        ),
        variant: BS_STYLE.PRIMARY,
      });
    }

    if (userCard?.allowedActions?.canCreateProFormaInvoice) {
      ctaButtonItems.push({
        label: 'Pay a bonus',
        onClick: () => history.push(
          `${financeProFormaInvoiceCreateUrl(orgAlias)}?provider_id=${userId}`,
        ),
        variant: BS_STYLE.PRIMARY,
      });
    }

    const componentName = this.constructor.GetComponentName();

    return (
      <React.Fragment>
        <TDApiConnected
          component={this.constructor}
          duck="view"
          shouldRefetchOnQueryChange={false}
        />

        <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />

        <div className="page page--profile">
          <div className="container">
            {hasTabs && (
              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <HorizontalFreeScroll className="mb-5">
                  <TabBar
                    activeKey={tab}
                    className="nav-tabs mx-2"
                    tabSpec={tabSpec}
                    variation={TABS_STYLING_VARIATION.DEFAULT}
                  />
                </HorizontalFreeScroll>
              </div>
            )}
            <div className="user-profile row">
              <div className="col-12 col-lg-4">
                <UserDetails
                  activeKey={tab}
                  email={email}
                  profile={profile}
                  userCard={userCard}
                />
                {isOnboardingTab && (
                  <Card className="user-profile__tabs mt-5">
                    <div className="user-profile__onboarding-progress">
                      {userCard.onboardingProgress !== undefined && (
                        <Card.Header className="d-flex align-items-center justify-content-between">
                          Onboarding progress
                          <OnboardingProgressBar
                            alignRight
                            percentage={userCard.onboardingProgress}
                          />
                        </Card.Header>
                      )}
                      {userCard?.onboardingSteps?.map(({ completed, description }, stepIndex) => (
                        <div
                          className="step py-3 mx-4 d-flex align-items-center justify-content-between"
                          key={`${description}-${stepIndex}`}
                        >
                          <div className={`description${completed ? ' completed' : ''}`}>
                            {description}
                          </div>
                          {completed && <span className={ICON.SUCCESS} />}
                        </div>
                      ))}
                    </div>
                  </Card>
                )}
              </div>

              <div className="col-12 col-lg-8 mt-4 mt-lg-0">
                { !userId && isProviderProfile && (
                  <PeopleProfileTabsSkeleton />
                )}

                {hasTabs && (
                  <PeopleProfileTabs
                    handleCloseNoteModal={this.handleCloseNoteModal}
                    handleCloseReviewModal={this.handleCloseReviewModal}
                    handleOpenNoteModal={this.handleOpenNoteModal}
                    handleOpenReviewModal={this.handleOpenReviewModal}
                    isNotesModalOpen={isNotesModalOpen}
                    isReviewModalOpen={isReviewModalOpen}
                    parentComponentName={componentName}
                    profile={profile}
                    projectOptions={projectOptions}
                    tab={tab}
                    userCard={userCard}
                  />
                )}

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfileView.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  hasPermission: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isNotesModalOpen: PropTypes.bool,
  isReviewModalOpen: PropTypes.bool,
  params: PropTypes.object.isRequired,
  profile: profileSpec,
  viewerProfile: profileSpec.isRequired,
  projects: PropTypes.arrayOf(projectSpec),
  user: userSpec,
  userCard: userCardSpec.isRequired,
  viewerId: PropTypes.number.isRequired,
};

ProfileView.defaultProps = {
  isNotesModalOpen: false,
  isReviewModalOpen: false,
  profile: {},
  projects: [],
  user: {},
};

const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, ProfileView.GetComponentName());
  const hasOrgAccess = getHasOrgAccess(state);
  const { item: userCard, item: { user, projects } } = viewState;
  const viewerProfile = selectProfile(state);

  return {
    activeOrg: selectActiveOrg(state),
    hasOrgAccess,
    hasPermission: getActiveUserCardPermissionChecker(state),
    isNotesModalOpen: getIsModalOpen(state, NOTE_MODAL_ID),
    isReviewModalOpen: getIsModalOpen(state, REVIEW_MODAL_ID),
    params: props.match.params,
    profile: user ? user.profile : null,
    projects,
    userCard,
    user,
    viewerProfile,
    viewerId: viewerProfile ? viewerProfile.userId : null,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProviderProfileViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileView);

export default withRouter(ProviderProfileViewConnect);
