import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { postOrganizationDS } from 'settings/assets/js/data-services/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import TDSelect from 'core/assets/js/components/TDSelect.jsx';
import OrgSettingContainer from 'settings/assets/js/components/OrgSettingContainer.jsx';
import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import {
  SERVICE_ORDER_MAX_AGE_DAYS, SERVICE_ORDER_MAX_AGE_DAYS_LABEL, SERVICE_ORDER_MAX_AGE_DAYS_VALUES,
} from 'projects/assets/js/constants';

const COMPONENT_NAME = 'OrgWorksheetSettingsTab';

const WorksheetApprovalEnableModalTitle = 'Auto-approve service orders';

const WorksheetApprovalDisableModalBody = () => (
  <>
    <p>
      The auto-approval option ensures that all of your users are paid always
      on time even if you forget to approve their service orders.
      Deactivating this option means that users get paid only when the project owners manually
      approve them.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the auto-approval option?
    </p>
  </>
);

const WorksheetRequirementModaltitle = ({ userTypeLabel }) => (
  `All ${userTypeLabel} can submit service orders`
);

WorksheetRequirementModaltitle.propTypes = {
  userTypeLabel: PropTypes.string.isRequired,
};

const WorksheetRequirementModalBody = ({ userTypeLabel }) => (
  <p>
    {`By enabling this option you allow all ${userTypeLabel} , whether they are on your `}
    company&rsquo;s payroll or freelancers to submit service orders.
  </p>
);

WorksheetRequirementModalBody.propTypes = {
  userTypeLabel: PropTypes.string.isRequired,
};

const SecondLevelEnableModalTitle = 'Second level of financial approval';

const SecondLevelEnableModalBody = () => (
  <>
    <p>
      Want to double check before you approve any service orders?
      Switch on multiple levels of financial approval.
    </p>

    <p>
      <strong>How does it work?</strong>
    </p>

    <p>
      Every time a user submits a new service order, the project owner
      gets notified. Any project manager has the option to `confirm` the payment request or
      reject it. Upon confirmation, a higher manager - the financial controller, if set, or the
      organisation owner - gets notified in order to approve or reject the payment request.
    </p>

    <p>
      Please notice that if the project manager is a higher manager the
      confirmation step is skipped.
    </p>
  </>
);

const SecondLevelEnableModalWarn = () => (
  <>
    <SecondLevelEnableModalBody />
    <p className="mb-4">
      Are you sure you want to introduce a second level of financial approval?
    </p>
  </>
);

const SecondLevelDisableModalBody = () => (
  <>
    <p>
      The second level of financial approval ensures that all payments are double checked
      before any invoices are issued by your users. Deactivating this setting means that
      a project manager can instantly approve a service order which will automatically
      be included in your next invoice.
    </p>

    <p className="mb-4">
      Are you sure you want to deactivate the second level of financial approval?
    </p>
  </>
);

const CreateProviderWorksheetsEnableModaltitle = 'Create provider worksheets';

const CreateProviderWorksheetsEnableModalBody = () => (
  <>
    <p>
      Do you want the task assignees to only focus to the execution and delivery of their task and
      not spend time on admin? Now you can allow the managers to create a worksheet on behalf of the
      task assignees and pay them effortlessly.
    </p>

    <p>
      <strong>How does it work?</strong>
    </p>

    <p>
      By activating this setting the task owners gain more control of the task management and
      payment process. When a task owner marks a task or a task assignment as completed, they get
      prompted to create worksheet on behalf of the task assignees for this particular task. The
      worksheet automatically becomes “Approved” or “Confirmed”, in case the second level of
      approvals is activated in your organisation.
    </p>
  </>
);

const CreateProviderWorksheetsEnableModalWarn = () => (
  <>
    <CreateProviderWorksheetsEnableModalBody />
    <p className="mb-4">Are you sure you want to activate this setting?</p>
  </>
);

const CreateProviderWorksheetsDisableModalBody = () => (
  <>
    <p>
      This setting gives more control to the task owners over the task management and payment
      process, so that the task assignees can focus only on the execution and delivery of their task
      and not spend any time on admin.
    </p>

    <p>
      By deactivating this setting only the task assignees will be able to submit a worksheet for
      their task.
    </p>

    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
  </>
);

const AllowProjectWorkInWorksheetsTitle = 'Allow project work in worksheets';
const AllowWorksheetsAfterProjectCompletionTitle = 'Allow worksheets after project completion';

const NUM_DAYS = 30;
const worksheetsAutoApprovalDaysOptions = [];
for (let i = 1; i <= NUM_DAYS; i += 1) {
  worksheetsAutoApprovalDaysOptions.push({
    text: i,
    value: i,
  });
}

const OrgWorksheetSettingsTab = ({
  dispatch,
  activeOrg: {
    alias,
    allow_draft_invoice: allowDraftInvoice,
    allow_draft_invoices_in_future: allowDraftInvoicesInFuture,
    allow_project_work_in_worksheets: allowProjectWorkInWorksheets,
    auto_approve_service_orders: autoApproveWorksheets,
    bonus_payments_enabled: bonusPaymentsEnabled,
    create_provider_worksheets: createProviderWorksheets,
    enable_worksheets_for_completed_projects: enableWorksheetsForCompletedProjects,
    notify_owner_worksheet_confirmed: notifyOwnerWorksheetConfirmed,
    payment_status_widget_show_worksheet_cutoff: showWorksheetCutoff,
    service_order_max_age_days: serviceOrderMaxAgeDays,
    should_employee_provider_create_service_order: allProvidersRaiseWorksheets,
    should_employee_manager_create_service_order: allManagersRaiseWorksheets,
    second_level_of_service_order_approval: secondLevelOfWorksheetApproval,
    worksheets_auto_approval_days: worksheetsAutoApprovalDays,
  },
}) => {
  const handleSettingsChanged = (changes) => {
    dispatch(postOrganizationDS(alias, changes, COMPONENT_NAME));
  };

  const WorksheetApprovalEnableModalBody = () => (
    <>
      <p>
        Have you established a trusting relationship with your users? Now you can save time and
        make sure they always get paid on time by activating the service orders auto-approval
        option.
      </p>
      <p>
        <strong>How does it work?</strong>
      </p>
      <p>
        Every time a user submits a new Worksheet or claims a new Expense, the project owner gets
        notified. Every time a user submits a new Pro-forma Invoice, financial controllers get
        notified.
        They have the option to manually approve or reject, but if no action is taken after
        {` ${worksheetsAutoApprovalDays} business days, TalentDesk.io automatically approves it `}
        and schedules its payment.
      </p>
    </>
  );

  return (
    <div className="rounded shadow-sm p-4 bg-white">
      <h3 className="heading-block">Service order settings</h3>

      <div className="settings-row">
        <TDSystemMessage className="mb-4" title="Service orders are how users get paid">
          <p>
            Service orders are how the users in your organization get paid. There are three types:
          </p>
          <ul>
            <li>
              <b>Worksheet</b>
              {' - This is how a user claims for work they have completed in a project'}
            </li>
            <li>
              <b>Expense</b>
              {' - This is how a user claims for expenses they have incurred in a project'}
            </li>
            <li>
              <b>Pro-forma Invoice</b>
              {' - This is how a user claims for work they have completed outwith a project'}
            </li>
          </ul>
          <p className="mt-3">These are then consolidated into the invoices that get paid.</p>
        </TDSystemMessage>

        {/* auto approve worksheets */}
        <TDToggleWithConfirmationModal
          modalId="auto-approve-worksheets"
          selected={autoApproveWorksheets}
          label={WorksheetApprovalEnableModalTitle}
          sublabel={([
            'Make sure your users get paid on time by allowing us to auto-approve service ',
            `orders after ${worksheetsAutoApprovalDays} working days.`,
          ].join(''))}
          onToggled={selected => handleSettingsChanged({ auto_approve_service_orders: selected })}
          enableHeader="Auto-approval is deactivated"
          disableHeader="Auto-approval is activated"
          EnableModalBody={() => (
            <>
              <WorksheetApprovalEnableModalBody />
              <p className="mb-4">
                Are you sure you want to activate the service orders auto-approval option?
              </p>
            </>
          )}
          DisableModalBody={WorksheetApprovalDisableModalBody}
          popOverContent={(
            <WorksheetApprovalEnableModalBody />
          )}
          popOverTitle={WorksheetApprovalEnableModalTitle}
        />

        {autoApproveWorksheets && (
          <OrgSettingContainer
            label="Automatic approval trigger days"
            modalId="worksheets-auto-approval-days"
            subLabel={([
              'How many days after a user has raised a service order should it be ',
              'auto-approved?',
            ].join(''))}
          >
            <TDSelect
              onChange={newValue => handleSettingsChanged({
                worksheets_auto_approval_days: newValue,
              })}
              options={worksheetsAutoApprovalDaysOptions}
              value={worksheetsAutoApprovalDays}
            />
          </OrgSettingContainer>
        )}

        {/* permanent employed providers should raise worksheets */}
        <TDToggleWithConfirmationModal
          modalId="providers-confirm-employee-worksheet"
          selected={allProvidersRaiseWorksheets}
          label={(
            <WorksheetRequirementModaltitle userTypeLabel="Providers" />
          )}
          sublabel="Allow permanently employed Providers to submit service orders"
          enableHeader="All Providers can submit service orders"
          onToggled={selected => handleSettingsChanged({
            should_employee_provider_create_service_order: selected,
          })}
          EnableModalBody={() => WorksheetRequirementModalBody('Providers')}
          popOverContent={(
            <WorksheetRequirementModalBody userTypeLabel="Providers" />
          )}
          popOverTitle={(
            <WorksheetRequirementModaltitle userTypeLabel="Providers" />
          )}
        />

        {/* permanent employed managers should raise worksheets */}
        <TDToggleWithConfirmationModal
          modalId="managers-confirm-employee-worksheet"
          selected={allManagersRaiseWorksheets}
          label={(
            <WorksheetRequirementModaltitle userTypeLabel="Managers" />
          )}
          sublabel="Allow permanently employed Managers to submit service orders"
          enableHeader="All Managers can submit service orders"
          onToggled={selected => handleSettingsChanged({
            should_employee_manager_create_service_order: selected,
          })}
          EnableModalBody={() => WorksheetRequirementModalBody('Managers')}
          popOverContent={(
            <WorksheetRequirementModalBody userTypeLabel="Managers"  />
          )}
          popOverTitle={(
            <WorksheetRequirementModaltitle userTypeLabel="Managers" />
          )}
        />

        <TDToggleWithConfirmationModal
          modalId="second-level-of-approval"
          selected={secondLevelOfWorksheetApproval}
          label={SecondLevelEnableModalTitle}
          sublabel={(
            'Allow multiple levels of approval on any payment requested via a service order'
          )}
          enableHeader="Second level of financial approval is deactivated"
          disableHeader="Second level of financial approval is activated"
          onToggled={selected => handleSettingsChanged({
            second_level_of_service_order_approval: selected,
          })}
          EnableModalBody={SecondLevelEnableModalWarn}
          DisableModalBody={SecondLevelDisableModalBody}
          popOverContent={(
            <SecondLevelEnableModalBody />
          )}
          popOverTitle={SecondLevelEnableModalTitle}
        />

        {secondLevelOfWorksheetApproval && (
          <TDToggleWithConfirmationModal
            disableHeader={(
              'The organization owner is always notified when service orders are confirmed'
            )}
            DisableModalBody={() => (
              <>
                <p>
                  By deactivating this setting, the organization owner will not be notified when
                  worksheets are confirmed, unless there are no financial controllers in the
                  organization.
                </p>
                <p className="mb-4">Are you sure you want to deactivate this setting?</p>
              </>
            )}
            enableHeader={(
              'The organization owner is not always notified when service orders are confirmed'
            )}
            EnableModalBody={() => (
              <>
                <p>
                  By activating this setting, the organization owner will be notified when
                  service orders are confirmed, even if there are financial controllers in the
                  organization.
                </p>
                <p className="mb-4">Are you sure you want to activate this setting?</p>
              </>
            )}
            label="Always notify the organization owner, when service orders are confirmed"
            modalId="notify-owner-worksheet-confirmed"
            onToggled={selected => handleSettingsChanged({
              notify_owner_worksheet_confirmed: selected,
            })}
            selected={notifyOwnerWorksheetConfirmed}
            sublabel={([
              'Notify the organization owner when service orders are confirmed, even if there are ',
              'financial controllers in the organization',
            ].join(''))}
          />
        )}

        <TDToggleWithConfirmationModal
          disableHeader="Create provider worksheets is activated"
          DisableModalBody={CreateProviderWorksheetsDisableModalBody}
          enableHeader="Create provider worksheets is deactivated"
          EnableModalBody={CreateProviderWorksheetsEnableModalWarn}
          label={CreateProviderWorksheetsEnableModaltitle}
          modalId="create-provider-worksheets"
          onToggled={selected => handleSettingsChanged({
            create_provider_worksheets: selected,
          })}
          selected={createProviderWorksheets}
          sublabel={
            `Allow managers to create worksheets on behalf of
            their providers when they mark a task as completed.`
          }
          popOverContent={(
            <CreateProviderWorksheetsEnableModalBody />
          )}
          popOverTitle={CreateProviderWorksheetsEnableModaltitle}
        />

        <TDToggleWithConfirmationModal
          disableHeader="Project work is currently allowed in worksheets"
          DisableModalBody={() => (
            <>
              <p>
                By deactivating this setting, users will not be able to add project work to
                their worksheets.
              </p>
              <p className="mb-4">Are you sure you want to deactivate this setting?</p>
            </>
          )}
          enableHeader="Project work is currently not allowed in worksheets"
          EnableModalBody={() => (
            <>
              <p>
                By activating this setting, users will be able to add project work to their
                worksheets.
              </p>
              <p className="mb-4">Are you sure you want to activate this setting?</p>
            </>
          )}
          label={AllowProjectWorkInWorksheetsTitle}
          modalId="allow-project-work-in-worksheets"
          onToggled={selected => handleSettingsChanged({
            allow_project_work_in_worksheets: selected,
          })}
          selected={allowProjectWorkInWorksheets}
          sublabel="Allow users to add project work to their worksheets"
          popOverContent="This setting gives users the ability to add project work to
          their worksheets."
          popOverTitle={AllowProjectWorkInWorksheetsTitle}
        />

        <TDToggleWithConfirmationModal
          disableHeader="Worksheets can currently be created after project completion"
          DisableModalBody={() => (
            <>
              <p>
                By deactivating this setting, contractors will not be able to create their
                worksheets after project completion.
              </p>
              <p className="mb-4">Are you sure you want to deactivate this setting?</p>
            </>
          )}
          enableHeader="Worksheets can not currently be created after project completion"
          EnableModalBody={() => (
            <>
              <p>
                By activating this setting, contractors will be able to create their
                worksheets after project completion.
              </p>
              <p className="mb-4">Are you sure you want to activate this setting?</p>
            </>
          )}
          label={AllowWorksheetsAfterProjectCompletionTitle}
          modalId="enable-worksheets-for-completed-projects"
          onToggled={selected => handleSettingsChanged({
            enable_worksheets_for_completed_projects: selected,
          })}
          selected={enableWorksheetsForCompletedProjects}
          sublabel="Allow contractors to submit worksheets after project completion"
          popOverContent="This setting gives contractors the ability to create
          worksheets after project completion."
          popOverTitle={AllowWorksheetsAfterProjectCompletionTitle}
        />

        <TDToggleWithConfirmationModal
          modalId="allow_draft_invoice"
          selected={allowDraftInvoice}
          label="Enable Proforma Invoices functionality"
          sublabel={(
            <>
              <p>
                Proforma Invoices allows Providers and Managers with Contractor status (excluding
                Org Owners and Financial Controllers) to submit a request for payment for work
                that is not linked to any project or budget. Once approved, Proforma Invoices will
                be included in your organization’s next billing cycle.
              </p>
            </>
          )}
          onToggled={selected => handleSettingsChanged({ allow_draft_invoice: selected })}
          enableHeader="Activate Proforma Invoices"
          disableHeader="Deactivate Proforma Invoices"
          EnableModalBody={() => (
            <>
              <p>
                By activating this setting, Providers and Contractor Managers will be able to submit
                requests for payment for work that is not linked to projects or budgets.
              </p>
              <p>Are you sure you want to activate it?</p>
            </>
          )}
          DisableModalBody={() => (
            <>
              <p>
                By disabling this functionality, Managers (excluding Organization owners and
                Financial Controllers) and Providers can only use Projects and Tasks functionalities
                to submit Worksheets. Only approved Worksheets will be included in your consolidated
                invoice in your next billing cycle.
              </p>
              <p className="mb-4">
                Are you sure you want to deactivate Proforma Invoices?
              </p>
            </>

          )}
        />

        {/* enable raising Proforma Invoices in future */}
        {allowDraftInvoice && (
          <TDToggleWithConfirmationModal
            modalId="providers-can-create-proforma-invoices-in-future"
            label="All Providers and Contractor Managers can create Proforma Invoices for future date ranges"
            sublabel={(
              <p>
                Allow Providers and Managers with Contractor status (excluding Org Owners and
                Financial Controllers) to submit a request for payment for work that may include
                a future date. Once approved, these Proforma Invoices will be included in your
                organization’s next billing cycle.
              </p>
            )}
            enableHeader="Allow Providers and Contractor Managers to create Proforma Invoices for future date ranges."
            disableHeader="Prevent Providers and Contractor Managers from creating Proforma Invoices for future date ranges"
            onToggled={selected => handleSettingsChanged({
              allow_draft_invoices_in_future: selected,
            })}
            selected={allowDraftInvoicesInFuture}
            EnableModalBody={() => (
              <>
                <p>
                  By activating this setting, Providers and Contractor Managers will be able to
                  submit requests for payments for future dates.
                </p>
                <p className="mb-4">Are you sure you want to activate it?</p>
              </>
            )}
            DisableModalBody={() => (
              <>
                <p>
                  By deactivating this setting, Providers and Contractor Managers will not be able
                  to submit requests for payments for future dates.
                </p>
                <p className="mb-4">Are you sure you want to deactivate it?</p>
              </>
            )}
          />
        )}
        <OrgSettingContainer
          label={(
            'Maximum number of days back that providers can use when submitting worksheets, '
            + 'expenses and proforma invoices'
          )}
        >
          <TDSelect
            onChange={newValue => handleSettingsChanged({
              service_order_max_age_days: newValue,
            })}
            options={SERVICE_ORDER_MAX_AGE_DAYS_VALUES.map(value => ({
              text: SERVICE_ORDER_MAX_AGE_DAYS_LABEL[value], value,
            }))}
            value={(
              serviceOrderMaxAgeDays === null
                ? SERVICE_ORDER_MAX_AGE_DAYS.NONE
                : serviceOrderMaxAgeDays
            )}
          />
        </OrgSettingContainer>
        <TDToggleWithConfirmationModal
          disableHeader="Prevent managers from creating bonus payments for Providers"
          DisableModalBody={() => (
            <>
              <p>
                By deactivating this setting, managers will not be able to create bonus payments for
                Providers.
              </p>
              <p className="mb-4">Are you sure you want to deactivate it?</p>
            </>
          )}
          enableHeader="Allow managers to create bonus payments for Providers"
          EnableModalBody={() => (
            <>
              <p>
                By activating this setting, managers will be able to create bonus payments for
                Providers.
              </p>
              <p className="mb-4">Are you sure you want to activate it?</p>
            </>
          )}
          label="Allow managers to create bonus payments for Providers"
          modalId="bonus-payments"
          onToggled={selected => handleSettingsChanged({
            bonus_payments_enabled: selected,
          })}
          selected={bonusPaymentsEnabled}
          disabled={!allowDraftInvoice}
          disabledMessage={!allowDraftInvoice && (
            'To enable this, please first enable "proforma invoices functionality"'
          )}
          sublabel={(
            <p>
              Enable this feature if you would like to allow your managers to create bonus payments
              for providers. This utilised the Proforma Invoices feature. This means that you will
              find the list of bonus payments within the list of Proforma Invoices. A filter exists
              to show only Proforma Invoices raised by managers. Bonus payments will be
              auto-approved and included in the next invoice at the end of the billing cycle.
            </p>
          )}
        />
      </div>

      <h3 className="heading-block">Dashboard settings</h3>
      <div className="settings-row">
        <TDToggleWithConfirmationModal
          disableHeader="Contractors can see the service order cutoff date"
          DisableModalBody={() => (
            <>
              <p>
                By deactivating this setting, contractors will not see the service order cutoff date
                in the Payment Status dashboard widget.
              </p>
              <p className="mb-4">Are you sure you want to deactivate this setting?</p>
            </>
          )}
          enableHeader="Contractors cannot currently see the service order cutoff date"
          EnableModalBody={() => (
            <>
              <p>
                By activating this setting, contractors will see the service order cutoff date in
                the Payment status dashboard widget.
              </p>
              <p className="mb-4">Are you sure you want to activate this setting?</p>
            </>
          )}
          label="Display service order cutoff date"
          modalId="enable-worksheet-cutoff"
          onToggled={selected => handleSettingsChanged({
            payment_status_widget_show_worksheet_cutoff: selected,
          })}
          selected={showWorksheetCutoff}
          sublabel={([
            'Display the service order cutoff date to Contractors on the Payment Status dashboard ',
            'widget',
          ].join(''))}
          popOverContent="On the dashboard that your Contractors see, a widget exists to help them
          know the status of their service orders. This widget contains a cutoff date for which they
          must submit their service orders in order for them to be approved and invoiced. Disabling
          this setting will remove the cutoff date from this widget."
          popOverTitle="Display service order cutoff date"
        />
      </div>
    </div>
  );
};

OrgWorksheetSettingsTab.propTypes = {
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgWorksheetSettingsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgWorksheetSettingsTab);

export default OrgWorksheetSettingsTabConnected;
