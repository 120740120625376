import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { isSubmitting, submit } from 'redux-form';

import { getViewState } from 'core/assets/js/ducks/view';
import { fetchOrganizationDS } from 'settings/assets/js/data-services/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { updateOrgBankAccount } from 'settings/assets/js/data-services/settings';
import { PAYMENT_METHOD_DETAILS, BANK_ACCOUNT_TYPE, SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import {
  settingsPaymentsSubPageUrl,
  settingsOrgBankAccountOptionsApiUrl,
  settingsPaymentMethodCreateSelectUrl,
} from 'settings/urls';
import { fetchListDS } from 'core/assets/js/ducks/list';
import InlineEditItem from 'core/assets/js/components/InlineEditItem.jsx';
import BankAccountSelector, { FORM_ID as BANK_FORM_ID } from 'settings/assets/js/components/BankAccountSelector.jsx';
import { getBankAccountLegacyBankCode } from 'settings/assets/js/lib/utils';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { completeStepDS } from 'accounts/assets/js/ducks/account';

const OrgSettingsPaymentsBankForm = ({
  dispatch,
  history,
  items,
  item: activeOrg,
  match: { params: { orgAlias } },
  submitting,
}) => {
  const selectedBankAccount = items.filter(i => i.is_selected)[0];
  const selectedPaymentMethod = selectedBankAccount
    ? Object.values(PAYMENT_METHOD_DETAILS).find(pm => pm.bankAccountTypeId
      .includes(selectedBankAccount.bank_account_type)) : {};
  const orgPaymentMethods = activeOrg.payment_methods;
  const isPaymentMethodDisabledInOrg = paymentMethodId => (
    orgPaymentMethods && !activeOrg.payment_methods.includes(paymentMethodId)
  );
  const bankAccountOptions = items.map((item) => {
    // check if current method is activated for this organization
    const paymentMethod = Object
      .values(PAYMENT_METHOD_DETAILS)
      .find(pm => pm.bankAccountTypeId.includes(item.bank_account_type)) || {};
    const disabled = isPaymentMethodDisabledInOrg(paymentMethod.methodId);

    let text = (item.alias || item.bank_name);
    if (disabled) {
      text += ` - ${paymentMethod.name} is not activated`;
    }

    return (
      {
        value: item.id,
        text,
        disabled,
      }
    );
  });

  const selectorInitialValues = {};
  let isTwAccountType = false;
  let isPaypalAccountType = false;
  let isPayoneerAccountType = false;

  if (selectedBankAccount) {
    isTwAccountType = selectedBankAccount.bank_account_type === BANK_ACCOUNT_TYPE.TRANSFERWISE
      || selectedBankAccount.bank_account_type === BANK_ACCOUNT_TYPE.TRANSFERWISE_NO_VALIDATION;
    isPaypalAccountType = selectedBankAccount.bank_account_type === BANK_ACCOUNT_TYPE.PAYPAL;
    isPayoneerAccountType = selectedBankAccount.bank_account_type === BANK_ACCOUNT_TYPE.PAYONEER;
    selectorInitialValues.bank_account_id = selectedBankAccount.id;
  }

  const bankAccountsExist = items.length > 0;

  return (
    <InlineEditItem
      ctaCreateAction={() => history.push(settingsPaymentMethodCreateSelectUrl(orgAlias))}
      ctaCreateClassName={bankAccountsExist ? '' : 'active'}
      ctaCreateRounded={!bankAccountsExist}
      ctaCreateShow
      ctaCreateVariant={bankAccountsExist ? BS_STYLE.LINK : BS_STYLE.PRIMARY}
      ctaEditClassName={selectedBankAccount ? '' : 'active'}
      ctaEditLabel={selectedBankAccount ? 'Change' : 'Select a payment method'}
      ctaEditRounded={!selectedBankAccount}
      ctaEditShow={bankAccountsExist}
      ctaEditVariant={selectedBankAccount ? BS_STYLE.LINK : BS_STYLE.PRIMARY}
      disclaimer={(
        <React.Fragment>
          <Link data-testid="bank-settings-manage-payment-methods-link" to={settingsPaymentsSubPageUrl(orgAlias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS)}>
            Click here
          </Link>
          {' '}
          to manage your payment methods
        </React.Fragment>
      )}
      editForm={(
        <BankAccountSelector
          initialValues={selectorInitialValues}
          options={bankAccountOptions}
          onSubmit={async values => {
            const changingSelectedBankAccount = !!selectedBankAccount;
            await updateOrgBankAccount(values, dispatch, 'OrgSettingsPaymentsBank', orgAlias);
            dispatch(requestRefreshAC('OrgSettingsPaymentsBank'));
            if (changingSelectedBankAccount) {
              toastr.success('Well Done!', 'Payment settings updated successfully.');
              return;
            }
            await completeStepDS(dispatch, orgAlias, 'PaymentMethodStep', { history });
          }}
        />
      )}
      label={(
        <>
          Selected payment method for
          {' '}
          <b>{activeOrg.name}</b>
        </>
      )}
      preview={selectedBankAccount && (
        (isTwAccountType || isPaypalAccountType || isPayoneerAccountType)
          ? (
            <div>
              <div className="font-weight-bold">
                { selectedBankAccount.alias }
              </div>
              { isPaymentMethodDisabledInOrg(selectedPaymentMethod.methodId) && (
                <div className="discreet">
                  {`The selected method is not available for ${activeOrg.name}`}
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              <span className="font-weight-bold">
                { selectedBankAccount.bank_name }
              </span>
              <br />
              { selectedBankAccount.account_number || selectedBankAccount.iban ? (
                <span>
                  {getBankAccountLegacyBankCode(selectedBankAccount)}
                  {' '}
                  {selectedBankAccount.account_number || selectedBankAccount.iban}
                </span>
              ) : null}
            </React.Fragment>
          )
      )}
      onSave={() => dispatch(submit(BANK_FORM_ID))}
      submitting={submitting}
      wrapperExtraClasses="shadow-sm bg-white pt-3 px-0 pb-3"
    />
  );
};

OrgSettingsPaymentsBankForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  items: PropTypes.array,
  submitting: PropTypes.bool,
  match: routerMatchSpec.isRequired,
  item: orgSpec,
};

OrgSettingsPaymentsBankForm.defaultProps = {
  items: [],
  item: {},
  submitting: false,
};

const OrgSettingsPaymentsBankFormConnected = withTDApiConnected({
  fetchData: ({ dispatch, params, url = '', authedAxios, componentName }) => {
    const { orgAlias } = params;

    return Promise.all([
      dispatch(fetchListDS({
        url: settingsOrgBankAccountOptionsApiUrl(orgAlias),
        params,
        authedAxios,
        componentName,
      })),
      dispatch(fetchOrganizationDS(params, url, authedAxios, componentName)),
    ]);
  },
  duck: 'list',
  storeKey: 'OrgSettingsPaymentsBank',
  skeletonComponent: SettingsPageSkeleton,
})(OrgSettingsPaymentsBankForm);

const mapStateToProps = (state) => {
  const viewState = getViewState(state, 'OrgSettingsPaymentsBank');
  return {
    submitting: isSubmitting(BANK_FORM_ID)(state),
    item: viewState.item,
  };
};

const OrgSettingsPaymentsBankFormReduxConnect = connect(
  mapStateToProps,
)(OrgSettingsPaymentsBankFormConnected);

export default withRouter(OrgSettingsPaymentsBankFormReduxConnect);
