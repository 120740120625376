import React from 'react';
import PropTypes from 'prop-types';
import { BANK_CURRENCY } from 'td-finance-ts';

import TDFinalFormOnChange from 'core/assets/js/components/TDFinalFormOnChange.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import PaymentReferencePreview from 'settings/assets/js/components/PaymentReferencePreview.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import CurrencySelectField from 'core/assets/js/components/FinalFormFields/CurrencySelectField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import { extendedCountryOptions } from 'core/assets/js/lib/isoCountries';
import { requestRefreshAC } from 'core/assets/js/ducks/requests';

const TWWizardStartStep = ({
  formValues, dispatch, descriptor,
}) => {
  const advancedFields = ['custom_reference'];
  const { currency, custom_reference: customReference } = formValues;
  return (
    <>
      <TDFinalFormOnChange name="currency">
        {(value, previous) => {
          // We need this check functionality so that redux store key which contains
          // the currency requirements changes based on the new currency selected
          if (value === previous) {
            return;
          }
          dispatch(requestRefreshAC(descriptor));
        }}
      </TDFinalFormOnChange>
      <div className="row">
        <div className="col-12">
          <h2>Payment information</h2>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12 col-sm-6">
          <TextInputField
            name="alias"
            required
            label="Friendly name for your account"
            sublabel="Enter a name that describes this payment method"
            validate={val => (!val ? 'Field is required' : null)}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInputField
            required
            name="bank_name"
            label="Bank name"
            sublabel="Enter the name of your bank"
            validate={val => (!val ? 'Field is required' : null)}
          />
        </div>
        <div className="col-12 col-sm-6">
          <CurrencySelectField
            validate={val => (!val ? 'Field is required' : null)}
            label="Currency"
            required
            sublabel="This is your account's currency, not the currency you would like to receive payments in."
            currencyOptions={BANK_CURRENCY}
            name="currency"
            isClearable={false}
          />
        </div>
        <div className="col-12 col-sm-6">
          <SelectField
            label="Country"
            // we need address.country naming to follow the way TW is using the country field
            name="address.country"
            defaultOptionText="Select country..."
            component={SelectField}
            optionsMapping={extendedCountryOptions}
            sublabel="This is the country of the bank where you opened your bank account."
            required
            validate={val => (!val ? 'Field is required' : null)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <AreaCollapsible
            containerElement="div"
            className="mb-5 w-100"
            fieldSet
            // expand advanced fields if any of the advanced fields is filled
            initiallyExpanded={advancedFields.some(field => formValues[field])}
            contentChildren={(
              <>
                <div className="row">
                  <div className="col-6">
                    <TextInputField
                      name="custom_reference"
                      placeholder="e.g acustomreference"
                      extraInfo="You can add a custom reference message. We'll ensure we pass it to the bank every time we make a transfer."
                      label="Payee reference message"
                    />
                  </div>
                </div>
                <PaymentReferencePreview
                  currency={currency}
                  paymentReference={customReference}
                />
              </>
            )}
            headingChildren={<span>More options</span>}
          />
        </div>
      </div>
    </>
  );
};

TWWizardStartStep.propTypes = {
  descriptor: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default TWWizardStartStep;
