import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ModalConfirmFinalForm from 'core/assets/js/components/ModalConfirmFinalForm.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { listRemoveItemAC, listUpdateItemAC } from 'core/assets/js/ducks/list';
import { modalOpenAC, getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import {
  RATE_ADJUSTMENT_MODAL_ID, RATE_ADJUSTMENT_STATUS, RATE_CANNOT_DELETE_REASON_LABEL,
} from 'rates/assets/js/constants';
import {
  deleteRateDS, fetchRatesDS, renameRateDS, setRateAsDefaultDS,
} from 'rates/assets/js/data-services/rates';
import { orgSettingsRatesEditUrl } from 'rates/urls';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { PROFILE_VIEW_COMPONENT_NAME } from 'people/assets/js/constants';
import { MODAL_ID as UPDATE_TASK_ASSIGNMENTS_RATES_MODAL_ID } from 'rates/assets/js/components/UpdateTaskAssignmentsRatesModal.jsx';

const getDefaultInfoModalId = rateId => `default-rate-info-modal-${rateId}`;
const getRateRenameModalId = rateId => `rename-modal-${rateId}`;
const getDeleteModalId = rateId => `delete-modal-${rateId}`;

const RateCard = ({
  activeInTasks, editInModal, item, isProvider, listComponentName, userCard,
}) => {
  const {
    alias,
    allowedActions,
    amount,
    currencySymbol,
    hasPendingRateAdjustment,
    id,
    isDefault,
    isRateActive,
    isRateDraft,
    latestRateAdjustment,
    pendingStatusLabel,
    rateAdjustmentAllowedActions,
    unit,
    user_id: userId,
  } = item;

  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { orgAlias } = params;
  const history = useHistory();
  const activeOrg = useSelector(selectActiveOrg);
  const activeUserCard = useSelector(selectActiveUserCard);
  const defaultInfoModalId = getDefaultInfoModalId(id);
  const defaultInfoModalIsOpen = useSelector(state => getIsModalOpen(state, defaultInfoModalId));
  const renameModalId = getRateRenameModalId(id);
  const renameModalIsOpen = useSelector(state => getIsModalOpen(state, renameModalId));
  const deleteModalId = getDeleteModalId(id);
  const deleteModalIsOpen = useSelector(state => getIsModalOpen(state, deleteModalId));

  const openEditRateModal = () => dispatch(modalOpenAC(
    RATE_ADJUSTMENT_MODAL_ID, { hasActiveTasks: userCard?.hasActiveTasks, rateId: id },
  ));

  useEffect(() => {
    const qs = queryString.parse(location.search);
    const targetRateId = qs.targetRateId && parseInt(qs.targetRateId, 10);
    if (targetRateId && item && id === targetRateId) {
      openEditRateModal();
    }
  }, []);

  // If the rate is active show as a rate amount the last agreed amount,
  // otherwise the last proposed amount
  const rateAmount = isRateActive ? amount : latestRateAdjustment.new_amt;

  // Show the `Pending approval` label only when there is a pending rate adjustment
  const statusLabel = pendingStatusLabel && (
    <StatusTag displayAsTag label={pendingStatusLabel} statusClass="pending" />
  );

  // Draft rates or ACTIVE rates with a pending rate adjustment are highlighted with an orange
  // background colour
  const cardClassName = ['shadow-none rate-card'];
  if (isRateDraft || hasPendingRateAdjustment) {
    cardClassName.push('card--pending');
  } else {
    cardClassName.push('card--light-gray');
  }

  const canSetAsDefault = allowedActions.canSetAsDefault && !isDefault;

  const isOwner = activeUserCard?.user?.id === userId;
  const isThisUsersDefault = isDefault && isOwner;

  const canReviewRateAdjustment = (
    latestRateAdjustment.status === RATE_ADJUSTMENT_STATUS.PENDING
    && rateAdjustmentAllowedActions?.canRespond
  );

  const editUrl = orgSettingsRatesEditUrl(orgAlias, id);

  const splitButtonActions = [{
    label: canReviewRateAdjustment ? 'Review' : 'Edit',
    onClick: e => {
      e.stopPropagation();
      if (editInModal) {
        openEditRateModal();
      } else {
        history.push(editUrl);
      }
    },
  }];

  if (isThisUsersDefault) {
    splitButtonActions.push({
      label: 'Default rate', onClick: () => dispatch(modalOpenAC(defaultInfoModalId)),
    });
  } else if (canSetAsDefault) {
    splitButtonActions.push({
      label: 'Set as default rate',
      onClick: async () => {
        try {
          const updatedRate = await dispatch(setRateAsDefaultDS(orgAlias, id));
          toastr.success(
            'Well Done!', `You have set "${alias}" as ${isOwner ? 'your' : 'the'} new default rate`,
          );
          dispatch(fetchRatesDS({
            componentName: listComponentName, orgAlias, userId: isOwner ? null : userId,
          }));
          const fetchDS = isProvider ? fetchProviderDS : fetchManagerDS;
          dispatch(fetchDS({
            componentName: PROFILE_VIEW_COMPONENT_NAME, params: { ...params, userId },
          }));
          if (
            userCard?.hasActiveTasks
            && activeOrg.can_apply_default_rate_change_to_tasks
            && activeUserCard.userRole.isAnyManager
          ) {
            dispatch(modalOpenAC(
              UPDATE_TASK_ASSIGNMENTS_RATES_MODAL_ID,
              {
                newRate: updatedRate,
                oldRate: {
                  amount: userCard?.rate,
                  currencySymbol: userCard?.currencySymbol,
                  user_id: userCard?.user?.id,
                  unit: userCard?.rateUnit,
                },
              },
            ));
          }
        } catch (e) {
          toastr.error('Oh Snap!', e._error || e.message);
        }
      },
    });
  }

  splitButtonActions.push(
    { label: 'Rename', onClick: () => dispatch(modalOpenAC(renameModalId)) },
    { label: 'Delete', onClick: () => dispatch(modalOpenAC(deleteModalId)) },
  );

  const closeModal = () => dispatch(modalCloseAC());

  return (
    <Card className={cardClassName.join(' ')} data-testid="rate-card">
      <Card.Body>
        <div className="row d-flex flex-nowrap align-items-center">
          <div className="col-auto pr-0">
            <div className="col-auto px-0 rate-card__infos">
              <span className="rate-card__title">
                <Link className="text-dark" to={editUrl}>
                  {alias}
                  {isDefault && (
                    <StatusTag
                      className="ml-3"
                      displayAsTag
                      statusClass="posted"
                      label="Default Rate"
                    />
                  )}
                </Link>
              </span>
              <span className="ml-n3 ml-sm-4 d-block d-sm-inline w-100 status-pending__container">
                {statusLabel}
              </span>
            </div>
            {!!activeInTasks && (
              <div className="rate-card__meta col-auto px-0">
                {`Currently used in ${activeInTasks} tasks`}
              </div>
            )}
          </div>
          <div className="rate-card__amount ml-auto px-0 col-auto">
            <RateAmount
              amount={rateAmount}
              showRateUnitDiscreetly
              symbol={currencySymbol}
              unit={unit}
            />
          </div>
          <div className="card__actions ml-1 col-auto justify-content-end">
            <ModalConfirmFinalForm
              confirmLabel="Save"
              data-testid="rate-rename-modal"
              FormContentComponent={() => (
                <>
                  {allowedActions?.canRename && (
                    <TextInputField label="Alias" name="alias" required />
                  )}
                  {!allowedActions?.canRename && (
                    <p>You do not have permission to rename this rate</p>
                  )}
                </>
              )}
              formProps={{
                initialValues: { alias },
                onSubmit: async values => {
                  try {
                    const updatedRate = await dispatch(renameRateDS(
                      orgAlias, id, { alias: values.alias },
                    ));
                    toastr.success('Well Done!', `The new rate alias is "${updatedRate.alias}"`);
                    closeModal();
                    dispatch(listUpdateItemAC(
                      id, { alias: updatedRate.alias }, listComponentName,
                    ));
                  } catch (e) {
                    toastr.error('Oh Snap!', e._error || e.message);
                  }
                },
              }}
              heading={`Rename rate "${alias}"`}
              onClose={closeModal}
              open={renameModalIsOpen}
              showConfirmButton={allowedActions?.canRename}
            />
            <ModalConfirm
              body={(
                <>
                  {allowedActions?.canDelete?.value && (
                    <>
                      <p>Deleting this rate does not affect your active projects or tasks.</p>
                      <p>Are you sure you want to delete this rate?</p>
                    </>
                  )}
                  {!allowedActions?.canDelete?.value && (
                    <>
                      <p>You cannot delete this rate</p>
                      {allowedActions?.canDelete?.reason && (
                        <p>{RATE_CANNOT_DELETE_REASON_LABEL[allowedActions.canDelete.reason]}</p>
                      )}
                    </>
                  )}
                </>
              )}
              confirmLabel="Delete rate"
              confirmStyle={BS_STYLE.DANGER}
              heading={`Delete rate "${alias}"`}
              onClose={closeModal}
              onConfirm={async () => {
                try {
                  await dispatch(deleteRateDS(orgAlias, id));
                  toastr.success('Well Done!', `Your rate "${alias}" deleted`);
                  // Update the list in redux store
                  dispatch(listRemoveItemAC(id, listComponentName));
                } catch (err) {
                  toastr.error('Oh Snap!', err._error || err.message);
                }
              }}
              open={deleteModalIsOpen}
              showConfirmButton={allowedActions?.canDelete?.value}
            />
            {isThisUsersDefault && (
              <ModalSimple
                body={(
                  <>
                    <p>
                      Your default rate is displayed on your profile and in the providers list. You
                      can create and use multiple rates in different units (for e.g. per hour, per
                      word, per day etc.), however, your default rate unit will always match the
                      rate unit set by the organisation to make search easier for managers.
                    </p>
                    <p>
                      This rate also cannot be deleted, unless you set another default rate unit
                      first.
                    </p>
                  </>
                )}
                footer={<TDButton className="float-right" label="Close" onClick={closeModal} />}
                heading={`"${alias}" is your default rate`}
                onClose={closeModal}
                open={defaultInfoModalIsOpen}
              />
            )}
            <SplitButton actions={splitButtonActions} data-testid="rate-card-dropdown-button" />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

RateCard.propTypes = {
  activeInTasks: PropTypes.number,
  editInModal: PropTypes.bool,
  item: PropTypes.shape(rateSpec).isRequired,
  isProvider: PropTypes.bool,
  listComponentName: PropTypes.string.isRequired,
  userCard: userCardSpec,
};

RateCard.defaultProps = {
  activeInTasks: 0,
  editInModal: false,
  isProvider: true,
  userCard: null,
};

export default RateCard;
