import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import ReviewForm from 'interviews/assets/js/components/ReviewForm.jsx';
import SubmissionForm from 'interviews/assets/js/components/SubmissionForm.jsx';
import {
  SUBMISSION_STATUS, SUBMISSION_STATUS_CLASS, SUBMISSION_STATUS_LABEL,
} from 'interviews/assets/js/constants';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

const OnboardingForms = ({ parentComponentName, userCard }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const activeUserCard = useSelector(selectActiveUserCard);

  const reviewOnboardingForm = queryString.parse(location.search).reviewOnboardingForm;

  let defaultOpenSubmissionId = null;
  if (
    reviewOnboardingForm && Array.isArray(userCard.submissions) && userCard.submissions.length > 0
  ) {
    let submissionRequiringReview = userCard.submissions.find(s => (
      (s.allowedActions.canReview || s.allowedActions.canSubmit)
      && (
        (
          /^\d+$/.test(reviewOnboardingForm.toString())
          && parseInt(reviewOnboardingForm, 10) === s.id
        )
        || reviewOnboardingForm === 'true'
      )
    ));
    if (!submissionRequiringReview) {
      submissionRequiringReview = userCard.submissions.find(
        s => s.allowedActions.canReview || s.allowedActions.canSubmit,
      );
    }
    if (submissionRequiringReview) {
      defaultOpenSubmissionId = submissionRequiringReview.id;
    }
  }
  const [
    openOnboardingFormSubmission, setOpenOnboardingSubmission,
  ] = useState(defaultOpenSubmissionId);

  const { isAnyManager } = activeUserCard.userRole;

  const reloadProfile = () => {
    const fetchDS = params.userType === PEOPLE_TYPE.PROVIDERS ? fetchProviderDS : fetchManagerDS;
    dispatch(fetchDS({ componentName: parentComponentName, params }));
  };

  const hasPendingOnboardingFormSubmission = (userCard.submissions || []).some(
    s => [SUBMISSION_STATUS.PENDING, SUBMISSION_STATUS.SUBMITTED].includes(s.status),
  );

  const canSeeAtLeastOneOnboardingForm = (userCard.submissions || []).some(
    s => s.allowedActions.canReview || s.allowedActions.canView || s.allowedActions.canSubmit,
  );

  if (!isAnyManager || !canSeeAtLeastOneOnboardingForm) {
    return null;
  }

  return (
    <Card className="mb-5">
      <AreaCollapsible
        baseHeadingClassName="w-100"
        className="profile-onboarding-collapsible"
        contentChildren={(
          <div className="d-flex flex-column">
            {hasPendingOnboardingFormSubmission && (
              <TDSystemMessage
                className="mb-5 mx-3"
                title="Pending onboarding form!"
                type={BS_STYLE.WARNING}
              >
                The provider has not submitted their onboarding form yet.
              </TDSystemMessage>
            )}
            {(userCard.submissions || []).map(submission => {
              if (!submission.allowedActions.canReview && !submission.allowedActions.canView) {
                return null;
              }
              const isOpen = openOnboardingFormSubmission === submission.id;
              return (
                <div
                  className={(
                    `profile-onboarding-submission mx-3 mb-3 p-3${isOpen ? ' open' : ''}`
                  )}
                  key={submission.id}
                >
                  <div
                    className="header-title d-flex align-items-start align-items-sm-center"
                    onClick={() => setOpenOnboardingSubmission(isOpen ? null : submission.id)}
                  >
                    <i className={`${ICON.CHEVRON_UP} py-3 px-4`} />
                    <div
                      className={(
                        'name-and-status w-100 d-flex flex-column flex-sm-row align-items-start'
                          + ' align-items-sm-center justify-content-between'
                      )}
                    >
                      <div className="font-weight-bold py-3">{submission.name}</div>
                      <StatusTag
                        label={SUBMISSION_STATUS_LABEL[submission.status]}
                        statusClass={SUBMISSION_STATUS_CLASS[submission.status]}
                      />
                    </div>
                  </div>
                  {isOpen && (
                    <>
                      {submission.allowedActions.canSubmit && (
                        <SubmissionForm
                          containerClassName="px-3"
                          displayName={false}
                          onSubmit={reloadProfile}
                          submission={submission}
                        />
                      )}
                      {!submission.allowedActions.canSubmit && (
                        <ReviewForm
                          displayOnboardingFormName={false}
                          onChangeManagerAnswers={reloadProfile}
                          onReOpen={reloadProfile}
                          onSubmit={reloadProfile}
                          providerFirstName={userCard.user.profile.firstName}
                          submission={submission}
                        />
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
        headingChildren={(
          <div
            className={(
              'people-onboarding-collapsible-header-content d-flex align-items-center '
              + 'justify-content-between'
            )}
          >
            <div className="d-flex align-items-center justify-content-start float-left">
              <div>Onboarding forms</div>
              {hasPendingOnboardingFormSubmission && (
                <StatusTag className="ml-5" label="Pending" statusClass="pending" />
              )}
            </div>
          </div>
        )}
        headingClassName="px-5 d-flex align-items-center justify-content-between"
        initiallyExpanded={reviewOnboardingForm}
        toggleElementClassName={null}
      />
    </Card>
  );
};

OnboardingForms.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
  userCard: userCardSpec.isRequired,
};

export default OnboardingForms;
