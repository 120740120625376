import PropTypes from 'prop-types';
import React from 'react';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDDropdown from 'core/assets/js/components/TDDropdown.jsx';
import { ICON, POPPER_PLACEMENT } from 'core/assets/js/constants';

const TDDropChevronButton = ({
  children, className, 'data-testid': dataTestId, menuPlacement, useDropButton,
}) => {
  const toggleEl = <TDButton className="p-3"><i className={ICON.CHEVRON_DOWN} /></TDButton>;
  const finalClassName = `drop-chevron${className ? ` ${className}` : ''}`;
  if (useDropButton) {
    return (
      <TDDropButton
        className={finalClassName}
        data-testid={dataTestId}
        placement={menuPlacement}
        stopPropagation
        toggleEl={toggleEl}
      >
        {children}
      </TDDropButton>
    );
  }
  return (
    <TDDropdown
      alignRight
      className={finalClassName}
      data-testid={dataTestId}
      stopPropagation
      toggle={toggleEl}
    >
      {children}
    </TDDropdown>
  );
};

TDDropChevronButton.propTypes = {
  children: (
    PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
  ),
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  menuPlacement: PropTypes.oneOf(Object.values(POPPER_PLACEMENT)),
  useDropButton: PropTypes.bool,
};

TDDropChevronButton.defaultProps = {
  className: null,
  'data-testid': null,
  menuPlacement: POPPER_PLACEMENT.BOTTOM_END,
  useDropButton: false,
};

export default TDDropChevronButton;
