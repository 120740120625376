import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import Wizard from 'core/assets/js/components/FinalFormFields/Wizard.jsx';
import { USER_TYPE } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { TYPE } from 'interviews/assets/js/constants';
import InterviewFormConfigure from 'interviews/assets/js/components/InterviewFormConfigure.jsx';
import InterviewFormPreview from 'interviews/assets/js/components/InterviewFormPreview.jsx';
import { SETTINGS_TEMPLATE_TABS } from 'settings/assets/js/constants';
import { orgTemplatesManageUrl } from 'settings/urls';

const INTERVIEW_STEPS = {
  CONFIGURE: 'configure',
  PREVIEW: 'preview',
};

const InterviewForm = ({ history, initialValues, match: { params: { orgAlias } }, onSubmit }) => {
  return (
    <Wizard
      className="create-interview-form"
      includeArrayMutators
      initialValues={initialValues}
      onCancel={() => history.push(orgTemplatesManageUrl(
        orgAlias, SETTINGS_TEMPLATE_TABS.ONBOARDING,
      ))}
      onSubmit={onSubmit}
      showFormErrors={false}
      submitBtnTitle="Save onboarding form"
    >
      <Wizard.Page
        beforePageChange={({ name, questions }) => {
          const errors = {};
          if (!name) {
            errors.name = 'This field is required';
          }
          if (!Array.isArray(questions) || questions.length === 0) {
            errors[FORM_ERROR] = 'Add at least one question to your onboarding form';
          } else {
            let hasRequired = false;
            let hasProviderQuestion = false;
            const moneyAsRateIndexes = [];
            questions.forEach((q, index) => {
              const qErrors = { payload: {} };
              if (q.required) {
                hasRequired = true;
              }
              if (q.answeredByUserType === USER_TYPE.PROVIDER) {
                hasProviderQuestion = true;
              }
              if (!q.label) {
                qErrors.label = 'Field is required';
              }
              if (q.required === undefined) {
                qErrors.required = 'Field is required';
              }
              if (q.type === TYPE.MONEY) {
                if (q.payload.isRate) {
                  moneyAsRateIndexes.push(index);
                } else if (q.payload.isRate === undefined) {
                  qErrors.payload.isRate = 'Field is required';
                }
              }
              if (q.type === TYPE.TEXT && q.payload.isTextArea === undefined) {
                qErrors.payload.isTextArea = 'Field is required';
              }
              if (q.type === TYPE.SELECT) {
                if (q.payload.multiple === undefined) {
                  qErrors.payload.multiple = 'Field is required';
                }
                if (!Array.isArray(q.payload.choices) || q.payload.choices.length === 0) {
                  qErrors.payload.choices = 'Field is required';
                }
                if (q.payload.has_other === undefined) {
                  qErrors.payload.has_other = 'Field is required';
                }
              }
              if (q.type === TYPE.FILE) {
                if (
                  !Array.isArray(q.payload.attachment_types)
                  || q.payload.attachment_types.length === 0
                ) {
                  qErrors.payload.attachment_types = 'Choose at least one option';
                }
                if (q.payload.multiple === undefined) {
                  qErrors.payload.multiple = 'Field is required';
                }
                if (q.payload.setExpiryDate === undefined) {
                  qErrors.payload.setExpiryDate = 'Field is required';
                }
              }
              if (q.answeredByUserType === undefined) {
                qErrors.answeredByUserType = 'Field is required';
              }
              if (
                !Array.isArray(q.payload.visibleTo)
                || q.payload.visibleTo.length === 0
              ) {
                qErrors.payload.visibleTo = 'Choose at least one option';
              }
              if (qErrors.label || Object.keys(qErrors.payload).length > 0) {
                if (errors.questions === undefined) {
                  errors.questions = {};
                }
                errors.questions[index] = qErrors;
              }
            });
            if (moneyAsRateIndexes.length > 1) {
              moneyAsRateIndexes.forEach(index => {
                if (!errors.questions) {
                  errors.questions = {};
                }
                if (!errors.questions[index]) {
                  errors.questions[index] = { payload: {} };
                }
                errors.questions[index].payload.isRate = [
                  ' You have already added a rate question. Please select "No" or remove this',
                  ' question',
                ].join('');
              });
            }
            if (!hasRequired) {
              errors[FORM_ERROR] = [
                'Onboarding Forms require that at least one of the questions be set as Required. ',
                'Please enable the Required option on one of the questions',
              ].join('');
            } else if (!hasProviderQuestion) {
              errors[FORM_ERROR] = `You must have at least one question to be answered by the
                provider`;
            }
          }
          return Object.keys(errors).length > 0 ? errors : null;
        }}
        component={InterviewFormConfigure}
        nextBtnTitle="Preview form"
        step={INTERVIEW_STEPS.CONFIGURE}
        title="Configure"
      />
      <Wizard.Page
        component={InterviewFormPreview}
        step={INTERVIEW_STEPS.PREVIEW}
        title="Preview"
      />
    </Wizard>
  );
};

InterviewForm.propTypes = {
  history: routerHistorySpec.isRequired,
  initialValues: PropTypes.object,
  match: routerMatchSpec.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

InterviewForm.defaultProps = {
  initialValues: {},
};

export default withRouter(InterviewForm);
