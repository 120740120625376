import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import { isEqual } from 'lodash';
import { withRouter } from 'react-router-dom';

import { TASK_TABS } from 'core/assets/js/constants';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { projectViewTaskUrl } from 'projects/urls';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import TaskAssigneesTab from 'projects/assets/js/components/taskTabs/TaskAssigneesTab.jsx';
import TaskDashboardTab from 'projects/assets/js/components/taskTabs/TaskDashboardTab.jsx';
import TaskDiscussionTab from 'projects/assets/js/components/taskTabs/TaskDiscussionTab.jsx';
import TaskDeliverablesTab from 'projects/assets/js/components/taskTabs/TaskDeliverablesTab.jsx';
import TaskWorksheetsTab from 'projects/assets/js/components/taskTabs/TaskWorksheetsTab.jsx';
import TaskManagersTab from 'projects/assets/js/components/taskTabs/TaskManagersTab.jsx';

/**
   * Returns the url for a specific tab
   *
   * @param {String} tabName the tab name to render the url for
   * @returns {String}
   */
const getProjectTabUrl = (tabName, withParticipant = false) => (
  projectViewTaskUrl(
    ':orgAlias',
    ':id',
    ':taskId',
    `:tabName(${tabName})`,
    null,
    withParticipant ? ':userId(\\d+)' : null,
  )
);

class ProjectTaskTabs extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      location: { pathname: previousPath }, match: { params: previousParams },
    } = prevProps;

    const {
      componentProps: { onStateUpdated }, location: { pathname }, match: { params },
    } = this.props;

    // We need to update the task's state when switching between discussion board tabs
    if (isEqual(previousParams, params) && previousPath !== pathname
      && typeof onStateUpdated === 'function') {
      onStateUpdated();
    }
  }

  render() {
    const {
      componentProps: { accessControl },
      componentProps,
      match: { url: currentUrl, params: { orgAlias, id: projectId, taskId } },
    } = this.props;

    return (
      <Switch>
        <Route exact path={getProjectTabUrl(TASK_TABS.DASHBOARD)}>
          <TaskDashboardTab {...componentProps} />
        </Route>

        <Route exact path={getProjectTabUrl(TASK_TABS.ASSIGNEES)}>
          <TaskAssigneesTab {...componentProps} />
        </Route>

        <Route exact path={`${getProjectTabUrl(TASK_TABS.MANAGERS)}`}>
          <TaskManagersTab {...componentProps} />
        </Route>

        <Route exact path={`${getProjectTabUrl(TASK_TABS.DISCUSSION)}`}>
          <TaskDiscussionTab {...componentProps} />
        </Route>

        <Route exact path={`${getProjectTabUrl(TASK_TABS.DISCUSSION, true)}`}>
          <TaskDiscussionTab {...componentProps} />
        </Route>

        {accessControl?.isManager && (
          <Route exact path={`${getProjectTabUrl(TASK_TABS.WORKSHEETS)}`}>
            <TaskWorksheetsTab {...componentProps} />
          </Route>
        )}

        <Route exact path={`${getProjectTabUrl(TASK_TABS.DELIVERABLES)}`}>
          <TaskDeliverablesTab {...componentProps} />
        </Route>

        <Route>
          <RedirectRoute
            status={302}
            from={currentUrl}
            to={projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DASHBOARD)}
          />
        </Route>
      </Switch>
    );
  }
}

ProjectTaskTabs.propTypes = {
  componentProps: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
};

ProjectTaskTabs.defaultProps = {
  componentProps: {},
};

export default withRouter(ProjectTaskTabs);
