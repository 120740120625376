import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import CustomSelectField from 'core/assets/js/components/FinalFormFields/CustomSelectField.jsx';
import axios from 'core/assets/js/lib/tdAxios';
import SelectedDocumentsLinks from 'documents/assets/js/components/SelectedDocumentsLinks.jsx';
import { documentOptionsApiUrl } from 'documents/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const DocumentSelectField = ({
  allowCountersigners,
  className,
  isMulti,
  label,
  name,
  required,
  showSelected,
  sublabel,
}) => {
  const [hasLoadedOptions, setHasLoadedOptions] = useState(false);
  const [options, setOptions] = useState([]);
  const activeOrg = useSelector(selectActiveOrg);
  const { values } = useFormState();

  useEffect(() => {
    axios.get(documentOptionsApiUrl(activeOrg.alias, allowCountersigners))
      .then(({ data }) => {
        setOptions(data);
      })
      .catch(err => {
        toastr.error('Oh Snap!', err.response?.data?._error || err.message);
      })
      .finally(() => {
        setHasLoadedOptions(true);
      });
  }, []);

  if (!hasLoadedOptions) {
    return null;
  }

  const selectedIds = values[name] || [];

  return (
    <>
      <CustomSelectField
        className={className}
        label={label}
        sublabel={sublabel}
        name={name}
        options={options}
        defaultOptionText="Select documents..."
        isMulti={isMulti}
        required={required}
      />
      {showSelected && (
        <SelectedDocumentsLinks documentOptions={options} selectedDocumentIds={selectedIds} />
      )}
    </>
  );
};

DocumentSelectField.propTypes = {
  allowCountersigners: PropTypes.bool,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  showSelected: PropTypes.bool,
  sublabel: PropTypes.string,
};

DocumentSelectField.defaultProps = {
  allowCountersigners: false,
  className: null,
  isMulti: true,
  label: 'Required legal documents',
  name: 'documentIds',
  required: false,
  showSelected: false,
  sublabel: null,
};

export default DocumentSelectField;
