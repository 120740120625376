import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  PERMISSIONS,
  PERMISSIONS_GROUPS,
  PERMISSIONS_LABEL,
  PERMISSION_SUBGROUPS,
  PERMISSION_SUBGROUPS_LABEL,
  PERMISSIONS_DESCRIPTION,
  PERMISSION_GROUPS_CONFIGURATION,
} from 'roles/assets/js/constants';
import PermissionMembersList from 'roles/assets/js/components/PermissionMembersList.jsx';
import { ICON } from 'core/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';

export const PERMISSION_MODAL_ID = 'permission-role-modal';

/**
 * Determines if a group is available for an organization
 *
 * @param {Object} activeOrg
 * @param {String} group
 * @returns {Boolean}
 */
const groupIsAvailable = (activeOrg, group) => {
  if (group === PERMISSIONS_GROUPS.INVOICING && !activeOrg.invoice_caps_enabled) {
    return false;
  }
  return true;
};

/**
 * Determines if a subgroup is available for an organization
 * @param {Object} activeOrg
 * @param {String} subgroup
 * @returns {Boolean}
 */
const subgroupIsAvailable = (activeOrg, subgroup) => {
  if (
    subgroup === PERMISSION_SUBGROUPS.CLASSIFICATION_QUESTIONNAIRE
    && !activeOrg.aor_classification_questionnaire_enabled
  ) {
    return false;
  }
  return true;
};

/**
 * Determines if a permission is available for an organization
 *
 * @param {Object} activeOrg
 * @param {String} permission
 * @returns {Boolean}
 */
const permissionIsAvailable = (activeOrg, permission) => {
  if (
    [PERMISSIONS.CAN_VIEW_1099_CONTRACTORS, PERMISSIONS.CAN_FILE_1099_REPORTS].includes(permission)
    && !activeOrg['1099_filings_enabled']
  ) {
    return false;
  }
  if (permission === PERMISSIONS.CAN_MANAGE_INVOICE_CAPS && !activeOrg.invoice_caps_enabled) {
    return false;
  }
  if (
    permission === PERMISSIONS.CAN_VIEW_CLASSIFICATION_QUESTIONNAIRE_SUBMISSIONS
    && !activeOrg.aor_classification_questionnaire_enabled
  ) {
    return false;
  }
  if (
    permission === PERMISSIONS.CAN_VIEW_PROVIDER_PAYMENT_DETAILS
    && !activeOrg.higher_managers_can_see_providers_payment_details
  ) {
    return false;
  }
  return true;
};

const PermissionsGroupList = () => {
  const dispatch = useDispatch();
  const activeOrg = useSelector(selectActiveOrg);
  const [selectedPermission, setSelectedPermission] = useState('');

  useEffect(() => {
    dispatch(modalOpenAC(PERMISSION_MODAL_ID));
  }, [selectedPermission]);

  const handleSelectPermission = (permission) => {
    setSelectedPermission(permission);
  };

  return (
    <div className="permissions-wrapper d-flex">
      <div className="permissions-list w-100">
        {PERMISSION_GROUPS_CONFIGURATION.map(group => {
          if (!groupIsAvailable(activeOrg, group.id)) {
            return null;
          }

          return group.subgroups.map((subgroup) => {
            if (!subgroupIsAvailable(activeOrg, subgroup.id)) {
              return null;
            }

            return (
              <div
                className="rounded shadow-sm p-4 bg-white mb-5"
                key={subgroup.id}
              >
                <h3 className="mt-2 mb-0">
                  {PERMISSION_SUBGROUPS_LABEL[subgroup.id]}
                </h3>

                <hr className="mx-n4 my-5" />

                <ul>
                  {subgroup.permissions.map(permission => {
                    if (!permissionIsAvailable(activeOrg, permission)) {
                      return null;
                    }

                    return (
                      <li
                        className={`
                          permissions-field__list-item
                          align-items-center
                          d-flex
                          justify-content-start
                          px-4
                          py-5
                          ${selectedPermission === permission ? 'selected' : ''}
                        `}
                        key={permission}
                        onClick={() => handleSelectPermission(permission)}
                      >
                        <i className={`${ICON.KEY_DUOTONE} mr-4`} />
                        <div className="permissions-field__info">
                          <p className="permissions-field__label">{PERMISSIONS_LABEL[permission]}</p>
                          <p className="permissions-field__description">{PERMISSIONS_DESCRIPTION[permission]}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          });
        })}
      </div>
      {selectedPermission && <PermissionMembersList permission={selectedPermission} />}
    </div>
  );
};

PermissionsGroupList.GetComponentName = () => 'PermissionsGroupList';

export default PermissionsGroupList;
